import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {withSnackbar} from 'notistack';
import {green, grey, red} from '@material-ui/core/colors';
import RenderMetadataField from "../../common/RenderMetadataField";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {asyncForEach, getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: green[500],
    },
    greyAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: grey[500],
    }
});

function SelectLocalDocumentStep(props) {
    return (
        <span>
            n/a for folders
        </span>
    )
}


SelectLocalDocumentStep.propTypes = {
    classes: PropTypes.object.isRequired,
    updateFileList: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    handleOnChangeDocumentMetadata: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired
};

const INITIAL_STATE = {
    //dialog
    open: false,
    metadata: {},
    //service
    response: {
        success: true,
        message: ""
    },
    inProgress: false
};

class AddFolderDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        this.handleOnChangeMetadata = this.handleOnChangeMetadata.bind(this);
        this.createFolder = this.createFolder.bind(this);

    }

    componentDidMount(){
        this.setState({
            open: true,
            metadata: {} //need to set here to prevent caching of previous values
        });
    }


    handleClose = (newFolder) => {

        const END_STATE = {
            //dialog/stepper
            open: false,

            metadata: {},

            //service
            response: {
                success: true,
                message: ""
            },

        };
        this.setState(END_STATE);

        if (newFolder && newFolder.boxId) {
            //construct folderDetails in same structure as would be passed in from search results
            let folderDetails = {
                id: newFolder.boxId,
                name: newFolder.name,
                newFolder: true
            };
            if (newFolder.metadata) {
                for (let i=0; i < newFolder.metadata.length; i++ ) {
                    folderDetails[newFolder.metadata[i].templateKey + "~" + newFolder.metadata[i].metadataKey] = newFolder.metadata[i].value
                    folderDetails[newFolder.metadata[i].metadataKey] = newFolder.metadata[i].value
                }
            }
            this.props.closeDialog(folderDetails);
        } else {
            this.props.closeDialog();
        }

    };

    handleOnChangeMetadata = (id, newValue) => {

        const metadata = this.state.metadata;

        metadata[id] = newValue;

        this.setState({metadata: metadata});

    };

    getMetadata = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug")

        debug && console.log('AddFolderDialog getMetadata for parent folder');

        const pathVar = "/" + this.props.parentFolderId + "/metadata";
        //const params = "?userId=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar;

        const request = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
        };

        debug && console.log ('getMetadata url:' , url, 'request:' , request);

        await this.props.triggerRefreshAuthToken();
        let response = await (fetch(url,request));

        debug  && console.log ('getMetadata response = ', response);

        let responseCheck = await response.ok;

        if (responseCheck) {
            let data = await response.json();
            debug  &&  console.log('response.json =', data);
            return data;
        } else {
            Promise.resolve(getErrorMessageFromResponse(response, 'getting metadata'))
                .then(message => {
                    this.props.enqueueSnackbar(message, {variant: 'error'});
                })
            debug  && console.log("getMetadata error. response.statusText:", response.statusText, {variant: 'error'});
            return null
        }
    };

    validate = () => {

        let fields = this.props.addFolderConfig.fields;
        let missingValues = [];

        if (fields && fields.length > 0) {
            fields.forEach(field => {
                if (field.required) {
                    if (!this.state.metadata[field.templateKey + "~" + field.metadataKey]  || this.state.metadata[field.templateKey + "~" + field.metadataKey].toString() === "" ) {
                        missingValues.push(field)
                    }
                }
            })
        }

        if (missingValues.length > 0) {
            return false;
        } else if (missingValues.length === 0 ){
            return true
        }
    }



    //createFolder = async () => {
    async createFolder(){

        const debug = window.location.pathname.toLowerCase().includes("debug");

        if (!this.validate()) {

            this.props.enqueueSnackbar('Please input required values', {variant: 'info'});

        } else {

            this.setState({inProgress: true});

            debug && console.log('createFolder start,  props=', this.props);

            //append generic metadata

            let metadata = this.state.metadata;
            let folderName = "";

            //extract templateKey & metadataKey and populate array
            let metadataArray = [];

            Object.entries(metadata).forEach(entry => {
                if (entry[0] === this.props.addFolderConfig.folderNameField.templateKey + "~" + this.props.addFolderConfig.folderNameField.metadataKey) {
                    folderName = entry[1]
                }
                metadataArray.push({
                    templateKey: entry[0].split("~")[0],
                    metadataKey: entry[0].split("~")[1],
                    value: entry[1]
                })
            });

            //add any fixed metadata from config
            const fixedMetadata = this.props.addFolderConfig.fixedMetadata;
            if (fixedMetadata) {
                for (let i = 0; i < fixedMetadata.length; ++i) {
                    metadataArray.push({
                        templateKey: fixedMetadata[i].templateKey,
                        metadataKey: fixedMetadata[i].metadataKey,
                        value: fixedMetadata[i].value,
                    })
                }
            }

            debug && console.log('fixedMetadata = ', fixedMetadata);

            //add metadata specified to be applied from parent
            const setMetadataFromParentFolder = this.props.addFolderConfig.setMetadataFromParentFolder;
            if (setMetadataFromParentFolder && setMetadataFromParentFolder.length > 0) {

                //get parent folder metadata
                let getMetadataResponse = await this.getMetadata();
                let response = await getMetadataResponse;

                debug && console.log('getMetadata allMetadata = ' + JSON.stringify(response));

                //extract the metadata we need from the response
                //for each metadata template specified in config add item to array with templateKey, metadataKey and value
                for (let i = 0; i < setMetadataFromParentFolder.length; i++) {
                    for (let r = 0; r < response.length; r++) {
                        if (response[r]["$template"] === setMetadataFromParentFolder[i].templateKey) {
                            //get metadata for each metadataKey listed
                            setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                                if (response[r][metadataKey]) {
                                    metadataArray.push({
                                        templateKey: setMetadataFromParentFolder[i].templateKey,
                                        metadataKey: metadataKey,
                                        value: response[r][metadataKey]
                                    })
                                }
                            })
                        }
                    }
                }
            }


            debug && console.log('createFolder - get applyGroupToFolderConfig');

            //If a folder group being created, add the folder group prefix to the metadata so that it can be used when adding users to case
            const applyGroupToFolderConfig = this.props.addFolderConfig.applyGroupToFolder;
            applyGroupToFolderConfig && applyGroupToFolderConfig.length > 0 && applyGroupToFolderConfig.forEach(groupConfig =>{
                groupConfig.groupName && groupConfig.groupName.forEach( groupNameConfig => {
                    if (groupNameConfig.setFolderGroupPrefix && groupNameConfig.setFolderGroupPrefix.templateKey && groupNameConfig.setFolderGroupPrefix.metadataKey && groupNameConfig.setFolderGroupPrefix.value)  {
                        metadata[groupNameConfig.setFolderGroupName.templateKey +'~'+ groupNameConfig.setFolderGroupName.metadataKey] = groupNameConfig.setFolderGroupPrefix.value
                    }
                })
            })


            let queryStr = "?userId=" + this.props.userDetails.boxId + '&autoGenTasks=false&username=' + this.props.userDetails.userName;

            //pass in metadata templates to be cascaded to children
            if (this.props.addFolderConfig.createCascadeFor) {
                if (this.props.addFolderConfig.createCascadeFor.length > 0) {
                    queryStr = queryStr + "&createCascadeFor=" + this.props.addFolderConfig.createCascadeFor.toString()
                }
            }
            let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + "/" + this.props.parentFolderId + queryStr;

            let prefix = ""
            if (this.props.addFolderConfig.prefix) {
                prefix = this.props.addFolderConfig.prefix;
            }

            let body = {
                name: folderName !== "" ? (prefix + folderName.trim()) : "new folder",
                metadata: metadataArray
            };

            debug && console.log('createFolder body: ', body);

            let request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

            let subfolders = [];
            if (this.props.addFolderConfig.autoGenerateSubFolders) {
                subfolders = this.props.addFolderConfig.autoGenerateSubFolders;
            }

            debug && console.log("createFolder url:", url, 'request: ', request);

            await this.props.triggerRefreshAuthToken();
            fetch(url, request)
                .then(response => {
                    if (response.ok) {
                        debug && console.log('createFolder RESPONSE ok: ', response);
                        if (subfolders.length > 0) {
                            this.props.enqueueSnackbar(this.props.addFolderConfig.label + " created successfully.  Generating subfolders...", {variant: 'success'});
                        } else {
                            this.props.enqueueSnackbar(this.props.addFolderConfig.label + " created successfully.", {variant: 'success'});
                        }
                        return (response.json()).then(result => {
                            debug && console.log('createFolder response.json = ', result);
                            if (result && result.boxId) {

                                // Apply security to folder if required
                                if (applyGroupToFolderConfig && Array.isArray(applyGroupToFolderConfig) && applyGroupToFolderConfig.length > 0 ) {
                                    this.applyGroupToFolder(applyGroupToFolderConfig, result.boxId, metadata)
                                }

                                //Now generate subfolders asynchronously
                                if (subfolders.length > 0) {
                                    this.createSubfoldersAsync(result, subfolders, metadataArray);
                                } else {
                                    this.setState({inProgress: false});
                                    this.handleClose(result)
                                }
                            }
                        })
                    } else {
                        debug && console.log('createFolder RESPONSE not ok: ', response);

                        //response not ok
                        Promise.resolve(getErrorMessageFromResponse(response, 'creating folder'))
                            .then(message => {
                                //Check for 409 Conflict error and give custom message if available
                                if (response.status === 409 && this.props.addFolderConfig.conflictMessage) {
                                    this.props.enqueueSnackbar(message + ".  " + this.props.addFolderConfig.conflictMessage, {variant: 'error'});
                                } else {
                                    this.props.enqueueSnackbar(message, {variant: 'error'});
                                }
                            })
                        this.setState({inProgress: false})

                    }
                })
                .catch(e => {
                    debug && console.log("createFolder Exception:", e, "url:", url, "request: ", request);
                });
        }

    };

    async processSubfolder(subfolder, parentFolderId, parentFolderMetadataArray) {
        try {

            window.location.pathname.toLowerCase().includes("debug") && console.log ('processSubfolder: subFolder=' , subfolder, 'parentFolderId=', parentFolderId, 'parentFolderMetadataArray=', parentFolderMetadataArray);

            let metadataArray = [];
            //Add fixedMetadata;
            for (let i=0; i < subfolder.fixedMetadata.length; i++){
                metadataArray.push(subfolder.fixedMetadata[i])
            }

            if (subfolder.setCaseId && subfolder.setCaseId.templateKey && subfolder.setCaseId.metadataKey) {
                metadataArray.push({
                    templateKey: subfolder.setCaseId.templateKey,
                    metadataKey: subfolder.setCaseId.metadataKey,
                    value: parentFolderId
                })
            }

            console.log ('*** subfolder - setMetadataFromParentFolder')

            if (subfolder.setMetadataFromParentFolder && subfolder.setMetadataFromParentFolder.length > 0) {
                const setMetadataFromParentFolder = subfolder.setMetadataFromParentFolder;

                for (let i=0; i<setMetadataFromParentFolder.length; i++) {
                    setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {

                        console.log ('*** metadataKey = ', metadataKey);
                        console.log ('*** templateKey = ', setMetadataFromParentFolder[i].metadataKeys)
                        console.log ('*** parentFolderMetadataArray = ', parentFolderMetadataArray)

                        ///check if value available in parentFolderMetadata
                        for (let p=0; p < parentFolderMetadataArray.length; p++) {
                            if (parentFolderMetadataArray[p].metadataKey === metadataKey && parentFolderMetadataArray[p].templateKey === setMetadataFromParentFolder[i].templateKey) {
                                metadataArray.push({
                                    templateKey: setMetadataFromParentFolder[i].templateKey,
                                    metadataKey: metadataKey,
                                    value: parentFolderMetadataArray[p].value
                                })
                            }
                        }
                    })
                }
            }

            let body = {
                name: subfolder.name,
                metadata: metadataArray
                //metadata: subfolder.fixedMetadata
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ("body: " , body);

            //TODO Wallbrook: autoGenFolderTasks - Wallbrook Specific, set to true for parent folder, false for subfolders
            const createSharedLink = subfolder.createSharedLink ? "&createSharedLink=true" : ""
            const queryStr = "?userId=" + this.props.userDetails.boxId + '&autoGenTasks=false' + createSharedLink;
            let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + "/" + parentFolderId  + queryStr;

            window.location.pathname.toLowerCase().includes("debug") && console.log ('url: ', url);
            const request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

            //call service to create folder
            await this.props.triggerRefreshAuthToken();
            let response = await (fetch(url,request));

            window.location.pathname.toLowerCase().includes("debug") && console.log ('processSubfolder response =', response);

            let data = await response.json();

            return data;

        } catch(err) {
            window.location.pathname.toLowerCase().includes("debug") && console.log("processSubfolder error: " + err);
            return err;
        }
    }

    createSubfoldersAsync = (parentFolder, subfolders, parentFolderMetadataArray) => {

        const parentFolderId = parentFolder.boxId;

        //Generate subfolders asynchronously

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            await asyncForEach(
                subfolders,
                async (subfolder) => {

                    await this.processSubfolder(subfolder, parentFolderId, parentFolderMetadataArray)
                        .then(result => {
                            window.location.pathname.toLowerCase().includes("debug") && console.log ('processSubfolder result = ', result);
                            if (result !== null) {
                                if (result.boxId) {
                                    return result.boxId
                                } else {
                                    return "0";
                                }
                            } else {
                                window.location.pathname.toLowerCase().includes("debug") && console.log ("response is null for subfolder", subfolder);
                                return "0";
                            }
                        })
                        .then(id => {
                            window.location.pathname.toLowerCase().includes("debug") && console.log ('Folder Id for ' , subfolder , ": " , id)
                        });

                });

            this.props.enqueueSnackbar("Subfolder generation complete", {variant: 'success'});
            this.setState({inProgress: false});
            //Close Dialog now
            this.handleClose(parentFolder)
        };

        //start upload
        start();

    };

    async applyGroupToFolder(applyGroupToFolderConfig, folderId, metadata) {

        const debug = window.location.pathname.toLowerCase().includes("debug")
        debug && console.log ('applyGroupToFolder ', folderId,  'metadata=', metadata, 'applyGroupToFolderConfig=', applyGroupToFolderConfig);

        //Colculate group names from config, group names can comprise of metadata, fixed values and folderId
        let body = [];

        applyGroupToFolderConfig && applyGroupToFolderConfig.forEach(groupConfig =>{
            //Calculate the groupName for each groupConfig
            let groupNameElements = [];
            let groupName = "";
            groupConfig.groupName && groupConfig.groupName.forEach( groupNameConfig => {
                groupNameConfig.value ?
                    groupNameElements.push(groupNameConfig.value):
                    groupNameConfig.templateKey && groupNameConfig.metadataKey &&
                    groupNameConfig.templateKey === "n/a" && groupNameConfig.metadataKey === "id" ?
                        groupNameElements.push(folderId) :
                        metadata[groupNameConfig.templateKey + '~' + groupNameConfig.metadataKey ] ?
                            groupNameElements.push(metadata[groupNameConfig.templateKey + '~' + groupNameConfig.metadataKey ]) :
                            this.props.enqueueSnackbar('Unable to get metadata value for ' + groupNameConfig.templateKey + '~' + groupNameConfig.metadataKey, {variant: 'error'});
                groupName = groupNameElements.join('.');
            })
            body.push ({
                groupName: groupName,
                boxId: folderId,
                accessLevel: groupConfig.accessLevel
            })

        })

        const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group/applyToFolder";

        const request = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('applyGroupToFolder url=', url, 'body=', body, 'request=', request);

        await this.props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('applyGroupToFolder response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'applying security to folder'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("applyGroup error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(data => {
                debug && console.log('applyGroup response.json = ', data)

                if (data) {
                    this.props.enqueueSnackbar("Security successfully applied to folder.", {variant: 'success'});
                }

            })
            .catch(e => {
                this.props.enqueueSnackbar("Error applying group (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("applyGroupToFoler  exception:" , e);
            })
    }

    render() {

        //const { classes } = this.props;

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"sm"}
                keepMounted={false}
                disableBackdropClick disableEscapeKeyDown>

                <DialogTitle id="form-dialog-title">{'Add ' + this.props.addFolderConfig.label}</DialogTitle>

                <DialogContent>
                    {
                    this.props.addFolderConfig.fields.map(field => {return(

                            <FormControl fullWidth  style={{paddingBottom: '10px'}} key={"fc" + field.templateKey + "~" + field.metadataKey}>

                            <RenderMetadataField
                                fieldValue={(this.state.metadata[field.templateKey + "~" + field.metadataKey])? this.state.metadata[field.templateKey + "~" + field.metadataKey] : ""}
                                handleOnChange={this.handleOnChangeMetadata}
                                metadataConfig={this.props.metadataConfig}
                                optionsConfig={this.props.optionsConfig}
                                metadataKey={field.metadataKey}
                                templateKey={field.templateKey}
                                usage={"upload"}
                                formValues={this.state.metadata}
                                required={field.required}
                                helperText={field.helperText}
                                forceDisable={this.state.inProgress}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            />
                        </FormControl>
                    )})
                    }
                </DialogContent>

                <DialogActions>

                    <div>
                        {
                            !this.state.inProgress && <Button onClick={this.handleClose}>Cancel</Button>
                        }
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled = {this.state.inProgress}
                            onClick={this.createFolder}
                        > {this.state.inProgress ? "Creating..." : "Submit"}
                        </Button>

                    </div>

                </DialogActions>

                {
                    (this.state.inProgress ) && <LinearProgress color = "primary"/>
                }

            </Dialog>
        );
    }
}

AddFolderDialog.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    parentFolderId: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(AddFolderDialog));

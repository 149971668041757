import React from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import { OktaAuth,toRelativeUrl } from '@okta/okta-auth-js';
import Home from './Home';
import CustomLoginComponent from '../auth/LoginPage';

const HasAccessToRouter = () => {
    const history = useHistory(); // example from react-router

    const customAuthHandler = () => {
        // Redirect to the /login page that has a CustomLoginComponent
        history.push('/login');
    };


    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    //OpenID Connect configuration
    const oidcConfig = {
        clientId: window.REACT_APP_OKTA_CLIENT_ID,
        issuer: window.REACT_APP_OKTA_BASE_URL + "/oauth2/default",
        redirectUri: window.location.origin + "/implicit/callback",
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        postLogoutRedirectUri: window.location.origin
    }

    const oktaAuth = new OktaAuth(oidcConfig);

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Route exact path="/" render={props => <Home {...props} launchDashboard={false} />} />
            <Route path="/implicit/callback" render={(props) => <LoginCallback {...props}  />} />
            <Route path="/login" component={CustomLoginComponent} />

            <SecureRoute exact path="/debug" render={props => <Home {...props} launchDashboard={false} />} />
            <SecureRoute exact path="/dashboard" render={props => <Home {...props} launchDashboard={true} />} />
            <SecureRoute path="/file/:id" render={props => <Home {...props} launchDashboard={false} />} />
            <SecureRoute path="/upload/:workspace" render={props => <Home {...props} launchDashboard={false} />} />
            <SecureRoute path="/folder/:folderId" render={props => <Home {...props} launchDashboard={false} />} />
            <SecureRoute path="/search" render={props => <Home {...props} launchDashboard={false} />} />
        </Security>
    );
};

const App = () => (
    <div>
        <Router>
            <HasAccessToRouter />
        </Router>
    </div>
);

export default App;
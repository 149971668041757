import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import RenderMetadataField from "../../common/RenderMetadataField";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {getErrorMessageFromResponse, hasAccess} from "../../common/helper"
import {IntlProvider} from "react-intl";
import Preview from "./Preview";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        // height: '90vh',
        // overflow: 'hidden',
        // flexGrow: 1,
    },

    container: {
        display: 'flex',
        overflow: 'hidden',
    },

    left: {
        float: 'left',
        width: '50%'
    },

    right: {
        float: 'right',
        width: '50%'
    },

    paper: {
        height: 140,
        width: 100,
    },

    sideMenu: {
        width: theme.spacing(8),
        height: '100%',
        visibility: 'visible',
        display: 'block',
        float: 'right',
        backgroundColor: theme.palette.secondary.main
    },

    sideMenuButton: {
        color: 'white',
        marginTop: theme.spacing(1)
    },

    sidePanelHeader: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing (.5)
    }

});


function DisplayMetadata(props) {

    //Render fields for each metadata item on the document

    window.location.pathname.toLowerCase().includes("debug") && console.log ('DisplayMetadata props: ', props);

    if (props.metadataFields) {

        let metadataFields = props.metadataFields;
        let formValues = {};
        Object.entries(metadataFields.fixedMetadata).forEach(entry => {
            formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
        });
        Object.entries(metadataFields.genericFields).forEach(entry => {
            formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
        });
        Object.entries(metadataFields.documentSpecificFields).forEach(entry => {
            formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
        });

        let editableFields = [];

        //Render fields
        return (

            <List>

                {
                    metadataFields.genericFields &&
                        metadataFields.genericFields.map( field => {
                            editableFields.push(field.templateKey + "~" + field.metadataKey)
                            return(
                                <ListItem key={field.templateKey + field.metadataKey}>
                                    <FormControl fullWidth  style={{paddingBottom: '10px'}} key={"fc" + field.templateKey + "~" + field.metadataKey}>
                                        <RenderMetadataField
                                            fieldValue={field.value ? field.value : null}
                                            handleOnChange={props.handleOnChangeGenericMetadata}
                                            metadataConfig={props.metadataConfig}
                                            optionsConfig={props.optionsConfig}
                                            metadataKey={field.metadataKey}
                                            templateKey={field.templateKey}
                                            forceDisable={!props.hasEditAccess}
                                            usage={"edit"}
                                            formValues={formValues}
                                        />

                                    </FormControl>
                                </ListItem>)
                })}

                {metadataFields.documentSpecificFields.map( field => {
                    editableFields.push(field.templateKey + "~" + field.metadataKey)
                    return(
                        <ListItem key={field.templateKey + field.metadataKey}>
                            <FormControl fullWidth  style={{paddingBottom: '10px'}} key={"fc" + field.templateKey + "~" + field.metadataKey}>
                                <RenderMetadataField
                                    fieldValue={field.value ? field.value : null}
                                    handleOnChange={props.handleOnChangeDocumentSpecificMetadata}
                                    metadataConfig={props.metadataConfig}
                                    optionsConfig={props.optionsConfig}
                                    metadataKey={field.metadataKey}
                                    templateKey={field.templateKey}
                                    forceDisable={!props.hasEditAccess}
                                    usage={"edit"}
                                    formValues={formValues}
                                />
                            </FormControl>
                        </ListItem>)
                })}

                {/*{metadataFields.fixedMetadata.length > 0 && <ListSubheader disableSticky>Reference</ListSubheader>}*/}

                {/*{metadataFields.fixedMetadata.map( field => {*/}
                {/*    // only add read only metadata field if field isn't already added above*/}
                {/*    if (editableFields.indexOf(field.templateKey + "~" + field.metadataKey) === -1) {*/}
                {/*        return (*/}
                {/*            <ListItem key={field.templateKey + field.metadataKey}>*/}
                {/*                <FormControl fullWidth style={{paddingBottom: '10px'}}*/}
                {/*                             key={"fc" + field.templateKey + "~" + field.metadataKey}>*/}
                {/*                    <RenderMetadataField*/}
                {/*                        fieldValue={field.value ? field.value : null}*/}
                {/*                        metadataConfig={props.metadataConfig}*/}
                {/*                        optionsConfig={props.optionsConfig}*/}
                {/*                        metadataKey={field.metadataKey}*/}
                {/*                        templateKey={field.templateKey}*/}
                {/*                        forceDisable={true}*/}
                {/*                        usage={"edit"}*/}
                {/*                        formValues={formValues}*/}
                {/*                    />*/}
                {/*                </FormControl>*/}
                {/*            </ListItem>)*/}
                {/*    } else {*/}
                {/*        return null*/}
                {/*    }*/}
                {/*})}*/}


            </List>

        );


    } else {
        //in case of error retrieving meta data
        //should we render blank fields as specified in upload config????

        window.location.pathname.toLowerCase().includes("debug") && console.log("metadataFields NOT SET... ");
        return(
            <span> No metadata available </span>)
    }
}

DisplayMetadata.propTypes = {
    metadataFields: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    handleOnChangeGenericMetadata: PropTypes.func.isRequired,
    handleOnChangeDocumentSpecificMetadata: PropTypes.func.isRequired,
    hasEditAccess: PropTypes.bool.isRequired
};


const INITIAL_STATE = {
    open: false,
    doc: null,
    metadataFields: {},
    metadataHasChanged: false,
    metadtaUpdated: false,
    isFetching: false,
    showSidepanel: false,
    showSidepanelOption: "" //todo pass in default?
};

class PreviewWithPropertiesDialog extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;
        this.handleOnChangeGenericMetadata = this.handleOnChangeGenericMetadata.bind(this);
        this.handleOnChangeDocumentSpecificMetadata = this.handleOnChangeDocumentSpecificMetadata.bind(this);
        //this.handleSidepanelClose = this.handleSidepanelClose.bind(this);
    }

    componentDidMount(){
        this.setState({ open: true});
        if(this.props.showMetadataOnOpen) {
            this.showSidepanel("metadata");
        }
        window.location.pathname.toLowerCase().includes("debug") && console.log('boxDocId:', this.props.boxDocID);
    }

    handleCloseDialog = (metadataUpdated) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('PreviewWithPropertiesDialog handleCloseDialog metadataUpdated = ', metadataUpdated);

        //clear state
        const END_STATE = {
            open: false,
            doc: null,
            metadataFields: {},
            isFetching: false,
            metadataHasChanged: false
        };

        this.setState(END_STATE);
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog(metadataUpdated)
        }
    };

    showSidepanel = (option) => {

        this.setState({
            showSidepanel: true,
            showSidepanelOption: option
        });
        switch (option) {
            case "metadata": {
                if (! this.state.doc ) {
                    //get metadata if not already retrieved
                    this.getMetadata();
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    hideSidepanel = () => {
        this.setState({showSidepanel: false})
    }


    getMetadata = async () => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('====getMetadata=====');

        this.setState({isFetching: true});

        let getContent = true;

        //get list of templates from metadataConfig
        //&fields=metadata.scope.templateName
        let templateNames = [];
        Object.entries(this.props.metadataConfig).forEach(template => {
            if (templateNames.indexOf(template[0]) === -1) {
                templateNames.push(template[0])
            }
        })

        let fieldsStr = "name,created_at,modified_at,version,size";
        if (templateNames.length > 0) {
            const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
            fieldsStr = fieldsStr + "," + templateNamesStr
        }

        const pathVar = "/" + this.props.boxDocID;
        //metadata=false still required but fields param used to get metadata
        const params =  "?userId=" + this.props.userDetails.boxId + "&content=" + getContent + "&metadata=false&fields=" + fieldsStr;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("getMetadata url :", url);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving metadata'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getMetadata error.  url:", url, "request: ", request, "response.text:", response);
                    return null

                }
            })
            .then(result => {

                window.location.pathname.toLowerCase().includes("debug") && console.log('getMetadata, result=', result);

                this.setState({doc: result});
                this.setState({isFetching: false});

                //extract metadata
                //Use uploadConfig of selected Workspace to determine what fields should be displayed

                let metadataFields = {};
                let listOfFields = [];   //list used to check what fields have been added

                //get values for generic fields;
                metadataFields.genericFields = this.props.selectedWorkspaceConfig.uploadConfig.genericFields;
                //loop through genericFields and add property to genericFieldsObject for each

                if (metadataFields.genericFields) {
                    for (let i = 0; i < metadataFields.genericFields.length; i++) {
                        let templateKey = metadataFields.genericFields[i].templateKey;
                        let metadataKey = metadataFields.genericFields[i].metadataKey;
                        listOfFields.push(templateKey + "~" + metadataKey);
                        if (result.metadata) {
                            if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                                if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey]) {
                                    if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey]) {
                                        //metadataFields.genericFields[i].value = result.metadata[templateKey]["enterprise_" + window.REACT_APP_ENTERPRISE_ID].metadata[metadataKey];
                                        //genericField.templateKey + "~" + genericField.metadataKey
                                        metadataFields.genericFields[i].value = result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey];
                                    } else {
                                        metadataFields.genericFields[i].value = null;
                                    }
                                } else {
                                    metadataFields.genericFields[i].value = null;
                                }
                            } else {
                                metadataFields.genericFields[i].value = null;
                            }
                        } else {
                            metadataFields.genericFields[i].value = null
                        }
                    }
                } else {
                    metadataFields.genericFields = [];
                }

                //get values for document specific fields
                metadataFields.documentSpecificFields = this.props.selectedWorkspaceConfig.uploadConfig.documentSpecificFields;
                if (metadataFields.documentSpecificFields) {
                    for (let i = 0; i < metadataFields.documentSpecificFields.length; i++) {
                        let templateKey = metadataFields.documentSpecificFields[i].templateKey;
                        let metadataKey = metadataFields.documentSpecificFields[i].metadataKey;
                        listOfFields.push(templateKey + "~" + metadataKey);
                        if (result.metadata) {
                                if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                                    if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey]) {
                                    if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey]) {
                                        metadataFields.documentSpecificFields[i].value = result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey];
                                    } else {
                                        metadataFields.documentSpecificFields[i].value = null;
                                    }

                                } else {
                                    metadataFields.documentSpecificFields[i].value = null;
                                }
                            } else {
                                metadataFields.documentSpecificFields[i].value = null;
                            }
                        } else {
                            metadataFields.documentSpecificFields[i].value = null;
                        }
                    }
                } else {
                    metadataFields.documentSpecificFields = []
                }

                //Add any additional properties under fixedMetaData
                let fixedMetaDataArray = [];

                //loop through metadata returned and add all values
                if (result.metadata) {
                    if (result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                        const templates = Object.entries(result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID])
                        if (Array.isArray(templates)) {
                            templates.forEach(metadataTemplate => {
                                let templateName = metadataTemplate[0];
                                Object.entries(metadataTemplate[1]).forEach(property => {
                                    if (property[0].substring(0, 1) !== "$") {
                                        fixedMetaDataArray.push({
                                            metadataKey: property[0],
                                            templateKey: templateName,
                                            value: property[1]
                                        })
                                    }
                                })
                            })
                        }
                    }
                }

                metadataFields.fixedMetadata = fixedMetaDataArray;

                this.setState({metadataFields: metadataFields});

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("getMetadata Exception:", e, " url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error retrieving metadata - " + e, {variant: 'error'});
                this.setState({isFetching: false})

            })
    };


    setMetadata = async () => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('PreviewWithPropertiesDialog submitRequest');

        //Merge all editable metadata into one
        let metadataArray = this.state.metadataFields.genericFields.concat(this.state.metadataFields.documentSpecificFields)

        window.location.pathname.toLowerCase().includes("debug") && console.log ('submitRequest metadataArray: ' , metadataArray);

        let body = {
            //content: content,
            fileName: this.state.doc.name,
            metadata: metadataArray,
            //subFolders: subFolders //TODO needed ??
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("body: " , body);

        const pathVar = "/" + this.props.boxDocID;
        const params = "?folderId=" + this.props.selectedWorkspaceConfig.uploadConfig.folderId + "&userId=" + this.props.userDetails.boxId + '&audit=' + this.props.actionsConfig.edit.audit;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;

        const request = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('submitRequest url:' , url, 'BODY:', body, 'request:' , request);

        this.setState({isFetching: true});

        await this.props.triggerRefreshAuthToken();
        fetch(url, request )
            .then(response => {
                if (response.ok) {
                    this.props.enqueueSnackbar("Metadata on " + body.fileName + " successfully updated.", {variant: 'success'});
                    this.setState({isFetching: false});
                    this.setState({metadataHasChanged: false});
                    this.handleCloseDialog(true);
                } else {

                    Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("submitRequest error.  url:", url, "request: ", request, "response.text:", response.statusText, {variant: 'error'});
                    this.setState({isFetching: false});
                }
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("submitRequest Exception:", e, "url:", url, "request: ", request);
                this.setState({isFetching: false});
                this.props.enqueueSnackbar("Error " + e, {variant: 'error'});
            })
    };


    handleOnChangeGenericMetadata = (id, newValue) => {

        let templateKey = id.split("~")[0];
        let metadataKey = id.split("~")[1];

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newMetadataFields = this.state.metadataFields;        //need this temporary object to ensure all values are maintained
        for (let i = 0; i < newMetadataFields.genericFields.length; i++) {
            if (newMetadataFields.genericFields[i].metadataKey === metadataKey && newMetadataFields.genericFields[i].templateKey === templateKey) {
                newMetadataFields.genericFields[i].value = val;

                //TODO - Zurich Temp Lookup - set contract ID based on claim ID selection
                if (id === "zurichEcmLite~claimId") {
                    let claimList = {
                        "00000621": "YI20008449",
                        "00000622": "YT0002755F",
                        "00000623":	"YT11647510",
                        "00000624": "YI20025330",
                        "00000625": "YT11385235",
                        "00000626": "YT0003014A",
                        "00000627": "YG11329760",
                        "00000628": "YT0002733F",
                        "00000629": "YT11681622",
                        "00000630": "YT0003487M",
                        "00000631": "YT11630084",
                        "00000632": "YT11681241",
                        "00000633": "YG11386903",
                        "00000634": "YG11386932",
                        "00000635": "YG0002723U",
                        "00000636": "YG11331218"
                    };
                    let contractId = claimList[newValue];
                    if (contractId ) {
                        this.props.enqueueSnackbar("Auto-set contract ID to " +  contractId, {variant: 'info'});
                        //find contractID in newMetadataFields and set it
                        for (let g = 0; g < newMetadataFields.genericFields.length; g++) {
                            if (newMetadataFields.genericFields[g].metadataKey === "contractId" && newMetadataFields.genericFields[g].templateKey === "zurichEcmLite") {
                                newMetadataFields.genericFields[g].value = contractId;
                                break;
                            }
                        }
                    }
                }
                //TODO - end Zurich Temp Lookup

                break;
            }
        }

        this.setState({metadataFields : newMetadataFields});

        if (!this.state.metadataHasChanged) {
            this.setState({metadataHasChanged: true});
        }

    };

    handleOnChangeDocumentSpecificMetadata = (id, newValue) => {

        let templateKey = id.split("~")[0];
        let metadataKey = id.split("~")[1];

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newMetadataFields = this.state.metadataFields;        //need this temporary object to ensure all values are maintained
        for (let i = 0; i < newMetadataFields.documentSpecificFields.length; i++) {
            if (newMetadataFields.documentSpecificFields[i].metadataKey === metadataKey && newMetadataFields.documentSpecificFields[i].templateKey === templateKey) {
                newMetadataFields.documentSpecificFields[i].value = val;
                break;
            }
        }
        this.setState({"metadataFields" : newMetadataFields});
        if (!this.state.metadataHasChanged) {
            this.setState({metadataHasChanged: true});
        }
    };

    
    render() {

        const classes = this.props.classes;

        let fileOptions = {};
        fileOptions[this.props.boxDocID] = {
            startAt: {
                unit: 'pages',
                value: this.props.pageNumber
            }
        };

        const hasEditAccess = hasAccess(this.props.actionsConfig.edit, this.props.userDetails.boxPortalRole);

        let enableCustomMetadata = false;
        if (this.props.actionsConfig.previewDocument.customMetadata) {
            if (this.props.actionsConfig.previewDocument.customMetadata.enabled) {
                if (hasAccess(this.props.actionsConfig.previewDocument.customMetadata, this.props.userDetails.boxPortalRole)) {
                    enableCustomMetadata = true
                }
            }
        }

        let enableComments = false;
        const commentsAction = this.props.actionsConfig.comments;
        if (commentsAction && commentsAction.enabled && hasAccess(commentsAction, this.props.userDetails.boxPortalRole)) {
            enableComments = true
        }

        //
        // let enableSidebarDetails = false;
        // let detailsSidebarProps = {};
        // if (this.props.actionsConfig.previewDocument.sidebarDetails) {
        //     if (this.props.actionsConfig.previewDocument.sidebarDetails.enabled) {
        //         if (hasAccess(this.props.actionsConfig.previewDocument.sidebarDetails, this.props.userDetails.boxPortalRole)) {
        //             enableSidebarDetails = true
        //             detailsSidebarProps = this.props.actionsConfig.previewDocument.sidebarDetails.options;
        //         }
        //     }
        // }
        //
        // let enableSidebarMetadata = false;
        // if (this.props.actionsConfig.previewDocument.sidebarMetadata) {
        //     if (this.props.actionsConfig.previewDocument.sidebarMetadata.enabled) {
        //         if (hasAccess(this.props.actionsConfig.previewDocument.sidebarMetadata, this.props.userDetails.boxPortalRole)) {
        //             enableSidebarMetadata = true
        //         }
        //     }
        // }
        //
        // //Note Activity feed requires token scopes adjusted to work
        // let enableSidebarActivityFeed = false;
        // if (this.props.actionsConfig.previewDocument.sidebarActivityFeed) {
        //     if (this.props.actionsConfig.previewDocument.sidebarActivityFeed.enabled) {
        //         if (hasAccess(this.props.actionsConfig.previewDocument.sidebarActivityFeed, this.props.userDetails.boxPortalRole)) {
        //             enableSidebarActivityFeed = true
        //         }
        //     }
        // }
        //
        // //Note sidebar details needs to be enabled for versions to appear (with at least one option enabled)
        // let enableSidebarVersions = false;
        // if (this.props.actionsConfig.previewDocument.sidebarVersions) {
        //     if (this.props.actionsConfig.previewDocument.sidebarVersions.enabled) {
        //         if (hasAccess(this.props.actionsConfig.previewDocument.sidebarVersions, this.props.userDetails.boxPortalRole)) {
        //             enableSidebarVersions = true
        //         }
        //     }
        // }
        //
        // const showSidebarButton = enableSidebarDetails || enableSidebarMetadata || enableSidebarActivityFeed || enableSidebarVersions;
        //
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('PreviewWithPropertiesDialog = ' , this.props);
        // window.location.pathname.toLowerCase().includes("debug") && console.log (
        //     'enableCustomMetadata = ' , enableCustomMetadata,
        //     'enableSidebarDetails = ', enableSidebarDetails,
        //     'enableSidebarMetadata = ' , enableSidebarMetadata,
        //     'enableSidebarActivityFeed = ' , enableSidebarActivityFeed,
        //     'enableSidebarVersions = ' , enableSidebarVersions
        // );

        const showMenuBar = enableCustomMetadata;

        window.location.pathname.toLowerCase().includes("debug") && console.log('showHeader: ', this.props.actionsConfig.previewDocument.showHeader);

        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                height="90vh"
            >
                <DialogContent>
                    <React.Fragment>
                        <div style={{width: '100%', height: "90vh"}}>
                        {/*<div style={{width: '100%', height: "100%", display: 'flex'}}>*/}
                        {/*    <div style={{width: '100%' , height: "100%", display: 'flex', float: 'left'}}>*/}
                                <Grid container spacing={0} style={{height: "100%", overflowY: "none"}}>

                                    {/*Preview **************************/}
                                    <Grid item xs={this.state.showSidepanel ? 8 : (showMenuBar ? 11 : 12)} style={{height: "100%", overflowY: "auto"}}>
                                        <IntlProvider locale="en">
                                            <Preview boxDocID={this.props.boxDocID}
                                                     fileContent={this.state.doc ? this.state.doc.content : ""}
                                                     userDetails={this.props.userDetails}
                                                     showHeader={this.props.actionsConfig.previewDocument.showHeader}
                                                     actionsConfig={this.props.actionsConfig}
                                                     showSidebar={true}
                                                     triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            />
                                        </IntlProvider>
                                    </Grid>

                                    {/*Side Menu Bar **********************/}

                                    {
                                        showMenuBar &&

                                        <Grid item xs={1}
                                              style={{height: "100%", overflowY: "auto", textAlign: 'center'}}>

                                            <div className={classes.sideMenu}>

                                                <React.Fragment>
                                                    {
                                                        this.state.showSidepanel &&

                                                        <Tooltip title={"Close sidepanel"}>
                                                            <IconButton onClick={this.hideSidepanel}
                                                                        className={classes.sideMenuButton}>
                                                                <i className='material-icons'>chevron_right</i>
                                                            </IconButton>
                                                        </Tooltip>

                                                    }


                                                    {
                                                        enableCustomMetadata && (!this.state.showSidepanel || (this.state.showSidepanel && this.state.showSidepanelOption !== "metadata")) &&

                                                        <Tooltip title={"Show Metadata"}>
                                                            <IconButton onClick={() => this.showSidepanel("metadata")}
                                                                        className={classes.sideMenuButton}>
                                                                <i className="material-icons">info_outline</i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }


                                                    {/*{*/}
                                                    {/*    enableComments && (!this.state.showSidepanel || (this.state.showSidepanel && this.state.showSidepanelOption !== "comments")) &&*/}

                                                    {/*    <Tooltip title={"Show Comments"}>*/}
                                                    {/*        <IconButton onClick={() => this.showSidepanel("comments")}*/}
                                                    {/*                    className={classes.sideMenuButton}>*/}
                                                    {/*            <i className="material-icons">comment</i>*/}
                                                    {/*        </IconButton>*/}
                                                    {/*    </Tooltip>*/}
                                                    {/*}*/}

                                                </React.Fragment>
                                            </div>

                                        </Grid>

                                    }

                                    {/*Side Panel showing metadata*/}

                                    {
                                        this.state.showSidepanel && this.state.showSidepanelOption === "metadata" &&

                                        <Grid item
                                              xs={3}
                                              style={{height: "100%", overflowY: "auto"}}
                                        >
                                            <Typography variant={"h6"} color={"textPrimary"} className={classes.sidePanelHeader}>
                                                Metadata
                                            </Typography>

                                            {
                                                Object.entries(this.state.metadataFields).length > 0 &&
                                                <DisplayMetadata
                                                    metadataFields={this.state.metadataFields}
                                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.optionsConfig}
                                                    handleOnChangeGenericMetadata={this.handleOnChangeGenericMetadata}
                                                    handleOnChangeDocumentSpecificMetadata={this.handleOnChangeDocumentSpecificMetadata}
                                                    handleOnChangeDocumentSpecificMetadataDate={this.handleOnChangeDocumentSpecificMetadataDate}
                                                    hasEditAccess = {hasEditAccess}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                />
                                            }
                                        </Grid>

                                    }

                                    {
                                        this.state.showSidepanel && this.state.showSidepanelOption === "comments" &&

                                        <Grid item
                                              xs={3}
                                              style={{height: "100%", overflowY: "auto"}}
                                        >
                                            <Typography variant={"h6"} color={"textPrimary"} className={classes.sidePanelHeader}>
                                                Comments
                                            </Typography>

                                            {
                                                <div>show comments here</div>
                                            }
                                        </Grid>

                                    }

                                </Grid>
                        {/*    </div>*/}
                        </div>
                    </React.Fragment>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.handleCloseDialog(false)} color="primary">
                        Close
                    </Button>
                    {
                        (hasEditAccess && this.state.metadataHasChanged) &&
                        <Button onClick={this.setMetadata}
                                variant = "contained"
                                color = "primary"
                                disabled={this.state.isFetching || !this.state.metadataHasChanged}>
                            {this.state.isFetching ? "Updating..." : "Save"}
                        </Button>
                    }
                </DialogActions>
            </Dialog>

        );
    }
}

PreviewWithPropertiesDialog.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired, // so that we can get the workspace details when a document is returned via Search All or when document has a different workspace property value to the selected workspace
    selectedWorkspaceConfig: PropTypes.object.isRequired,  //pick up uploadConfig from here instead
    actionsConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    showMetadataOnOpen: PropTypes.bool
};

export default withSnackbar(withStyles(styles, { withTheme: true} )(PreviewWithPropertiesDialog));
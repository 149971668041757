import React from 'react';
import Button from "@material-ui/core/Button/Button";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Input from "@material-ui/core/Input/Input";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Popover from "@material-ui/core/Popover";
import SearchTemplate from "./SearchTemplate";
import {withStyles} from "@material-ui/core";
import {withSnackbar} from 'notistack';
import {getFieldConfig, hasAccess, formatDate, getErrorMessageFromResponse} from "../common/helper";
import {getDataForSearchResultsTableElastic, getDataForSearchResultsTableMetadataSearch, getUserValue} from "./helper";

const styles = theme => ({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '30px 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
    grow: {
        flexGrow: 1,  //needed for right alignment of search icon on Advanced Search toolbar
    },
    searchField: {
        paddingLeft: "7px",
        paddingTop: "5px",
        width: "400px",
        backgroundColor: "white"
    },
    searchFieldDisabled: {
        paddingLeft: "7px",
        paddingTop: "5px",
        width: "400px",
        backgroundColor: "#eee"
    }

});

let counter = 0;

function SearchButton(props) {

    return (
        <Button variant="contained"
                color="secondary"
                onClick={props.onClick}
                style={{width: "200px", marginTop: "20px"}}>
                Search Content
        </Button>
    );
}

SearchButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

function ClearSearchButton(props) {
    return (
        <Button variant="contained"
                color="secondary"
                onClick={props.onClick}
                style={{width: "200px", marginTop: "20px"}}>
            Clear Search
        </Button>
    );
}

ClearSearchButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

const ref = React.createRef();
const element = ref.current;

class Search extends React.Component {

    constructor(props) {

        //const debug = window.location.pathname.toLowerCase().includes("debug");

        super(props);
        this.handleSearchClickSimple = this.handleSearchClickSimple.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleOnChangeSearchCriteria = this.handleOnChangeSearchCriteria.bind(this);
        this.handleClosePopover = this.handleClosePopover.bind(this);
        this.handleOnChangeSearchCriteriaSimple = this.handleOnChangeSearchCriteriaSimple.bind(this);
        this.handleShowMessageDialog = this.handleShowMessageDialog.bind(this);
        this.handleCloseMessageDialog = this.handleCloseMessageDialog.bind(this);
        this.state = {
            searchCriteriaSimple:props.searchCriteriaSimple,
            searchCriteria: props.searchCriteria,
            searchResults: [],
            anchorEl: element,
            //auto open Advanced Search if autosearch not enabled and there are search fields configured for either the document or folder search
            autoOpenAdvancedSearch: !this.props.disableAutoOpenAdvancedSearch && this.props.newSearch && this.props.selectedSearchConfig.autoSearch === "" &&
                (this.props.selectedSearchConfig.documentSearch.searchFields.length > 0 || this.props.selectedSearchConfig.folderSearch.searchFields.length > 0)
        };

       // this.searchFieldRef = React.createRef();

       //debug && console.log ('Search: constructor props=', props);
    }

    UNSAFE_componentWillMount() {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        if (this.props.searchOffset === 0 && this.props.selectedSearchConfig.autoSearch !== "" &&  !this.props.disableAutoSearch) {

            //debug && console.log ('search.js - componentWillMount - serachOffset = 0 ');

            if (this.props.selectedSearchConfig.autoSearch === "document" && this.props.mode !== "reporting" ) {
                //autosearch docs
                debug && console.log ('search.js - componentWillMount autosearch document');

                this.doSearchMetadata("file", this.props.newSearch);
                this.props.autoDrawerClose();

            } else if (this.props.selectedSearchConfig.autoSearch === "folder" && this.props.mode !== "reporting" ) {
                //autosearch folder
                //debug && console.log ('search.js - componentWillMount autoSearch folder');

                if (window.REACT_APP_FOLDER_SOURCE === "elastic"  ) {
                    //debug && console.log ('useCaseAPI so doCaseSearch')
                    this.doCaseSearch(this.props.newSearch);
                } else {
                    this.doSearchMetadata("folder", this.props.newSearch);
                }
                this.props.autoDrawerClose();

            } else {

                debug && console.log ('search.js - componentWillMount - final else - updateSearchResults');

                //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount, nextMarker)
                this.props.updateSearchResults([], "", false, [], "")
            }
        } else {
            //check if simple or advanced search
            if (this.state.searchCriteriaSimple !== "") {
                this.doSearchSimple(false)
            } else {
                if (this.props.searchType !== "") {
                    // check if using Case API for Case searching
                    //if searchType = folder, check if we are using Case API
                    if (this.props.searchType === "folder") {
                        if (window.REACT_APP_FOLDER_SOURCE === "elastic" ) {
                            this.doCaseSearch(false);
                        } else {
                            this.doSearchMetadata(this.props.searchType, false)

                        }
                    } else {
                        debug && console.log(' ====get next files');
                        this.doSearchMetadata("file", false);
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.remountSearch();
    }

    handleClearSearch() {

        this.setState({
            searchCriteria: [],
            searchCriteriaSimple: ""
        });
    }

    doSearchSimple = async (newSearch) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        //TODO change header if switching from a search template to simple search / combine simple search
        //TODO  new parameter newSearch (or use offset???)
        debug && console.log ('doSearchSimple newSearch = ', newSearch, "searchOffset = " , this.props.searchOffset);

        // updateIsFetching(isFetching, folderDetails , newSearch, searchOffset) {
        this.props.updateIsFetching(true, {}, newSearch, newSearch ? 0 : this.props.searchOffset);

        let searchOffset = newSearch ? 0 : this.props.searchOffset;

        const folderIds = this.props.selectedSearchConfig.documentSearch.folderIds.toString();

        const watson = this.props.selectedSearchConfig.documentSearch.watson;

        let type = "";
        const searchFiles = this.props.simpleSearchActionConfig.searchFiles;
        const searchFolders = this.props.simpleSearchActionConfig.searchFolders;
        if (searchFiles && searchFolders) {
            type = ""
        } else if (searchFiles) {
            type = "file"
        } else {
            type = "folder"
        }

        const queryStr =  "?query=" + this.state.searchCriteriaSimple + "&userId=" + this.props.userDetails.boxId + '&folderIds=' + folderIds + '' +
            '&limit=' + this.props.searchLimit + '&offset=' + searchOffset + '&watson=' + watson + '&type=' + type;

        let rows=[];
        let totalCount = 0;

        await this.props.triggerRefreshAuthToken();

        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_SIMPLE + queryStr;
        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        debug && console.log ('doSearchSimple REQUEST url: ', url, 'request: ', request);
        fetch(url, request)
            .then(response => {
                debug && console.log ('doSearchSimple response:', response);
                if (response.ok) {
                    return (response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, "retrieving search results" ))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log (" doSearchSimple error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(resultA => {
                debug && console.log ('doSearchSimple response.json:', resultA);
                counter = resultA.offset;
                totalCount = resultA.total_count;
                if (totalCount === 0) {
                    rows = [];
                } else {
                    resultA.entries.forEach((item, i) => {
                        counter += 1
                        debug && console.log(i, item);
                        const resultsColumns =  JSON.parse(JSON.stringify(this.props.selectedSearchConfig.documentSearch.resultsColumns)); //deep clone
                        rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig, counter))
                    })
                }
            })
            .then(result =>
                //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple) {
                this.props.updateSearchResults(rows, type, true, this.state.searchCriteria, this.state.searchCriteriaSimple, totalCount)
            )
            .catch(e => {
                debug && console.log (" doSearchSimple Exception:", e, "url:", url, "request: ", request);
                this.props.enqueueSnackbar("Exception: " + e , {variant: 'error'});
                this.props.updateIsFetching(false, {}, newSearch, newSearch ? 0 : this.props.searchOffset);
            })
    };

    doCaseSearch = async (newSearch) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('doCaseSearch newSearch = ', newSearch);

        let searchConfig =  this.props.selectedSearchConfig.folderSearch;
        let searchCriteriaArray = [];
        let indexes = searchConfig.elastic.indexes;
        let rows=[];
        let totalCount = 0;
        let searchOffset = newSearch ? 0 : this.props.searchOffset;
        const page = newSearch ? 0 : (this.props.searchOffset/this.props.searchLimit)

        let params =  "?page=" + page + "&pageSize=" + this.props.searchLimit;

        if(searchConfig.elastic.defaultSort){
            params = params + "&sort=" + searchConfig.elastic.defaultSort;
        }

        let url, pathVar, request, body;

        if (searchConfig.elastic.searchRaw) {
            // Raw Search

            debug && console.log ('doCaseSearch raw search.  state.searchCriteria=', this.state.searchCriteria);
            pathVar = (indexes.length > 0 ?  "/" + indexes.toString()  : "")  + "/search-raw";
            url = window.REACT_APP_CASE_API_BASE_URL + pathVar + params;
            body = JSON.parse(JSON.stringify(searchConfig.elastic.searchRaw)); //deep clone to avoid config being updated
            searchCriteriaArray = this.state.searchCriteria;

            let bodyString = JSON.stringify(body);

            //TODO also need to handle other $USER_ values, and multiples
            if (bodyString.indexOf("$USER_EMAIL") > -1 ){
                bodyString= bodyString.replace("$USER_EMAIL", getUserValue("$USER_EMAIL", this.props.userDetails));
                body = JSON.parse(bodyString)
            }

            if (Object.entries(this.state.searchCriteria).length > 0){

                if (body.bool && body.bool.must && Array.isArray(body.bool.must)) {

                    let mustArray = body.bool.must;

                    Object.entries(this.state.searchCriteria).forEach(entry => {
                        //get field config
                        const templateKey = entry[0].split("~")[0];
                        const metadataKey = entry[0].split("~")[1];
                        const fieldConfig = getFieldConfig(this.props.metadataConfig, templateKey, metadataKey);
                        if (fieldConfig.elasticField) {

                            //append to body
                            debug && console.log('body = ', body)
                            debug && console.log('add object to raw search for criteria: ', entry)

                            if (fieldConfig.type === "date") {
                                if (fieldConfig.searchRange) {
                                    mustArray.push({
                                        "range": {
                                            ["metadata." + fieldConfig.elasticField]: {
                                                "gte": formatDate(entry[1].from),
                                                "lte": formatDate(entry[1].to)
                                        }
                                    }})
                                } else {
                                    mustArray.push({
                                        term: {
                                            ["metadata." + fieldConfig.elasticField]: formatDate(entry[1])
                                        }
                                    })
                                }
                            } else {
                                if (fieldConfig.operator && fieldConfig.operator === "contains") {
                                  mustArray.push({
                                      bool: {
                                          should: [
                                              {
                                                  wildcard: {
                                                      ["metadata." +  fieldConfig.elasticField +".keyword"]: "*" + (fieldConfig.searchUpperCase ? entry[1].toUpperCase() : entry[1]) + "*"
                                                  }
                                              },
                                              {
                                                  term: {
                                                      ["metadata." +  fieldConfig.elasticField +".keyword"]: fieldConfig.searchUpperCase ? entry[1].toUpperCase() : entry[1]
                                                  }
                                              }
                                          ]
                                      }
                                  })
                                } else {
                                    mustArray.push({
                                        term: {
                                            ["metadata." + fieldConfig.elasticField + ".keyword"]: fieldConfig.searchUpperCase ? entry[1].toUpperCase() : entry[1]
                                        },
                                    })
                                }
                            }
                        } else {
                            debug && console.log('Unable to find elasticField for ' + templateKey + ' - ' + metadataKey);
                        }
                    });

                    //update body with advanced search criteria entered in ui
                    body.bool.must = mustArray;

                } else {
                    debug && console.log('Search Config incorrect, needs to have bool.must at top level to support advanced search and case_type.');
                }
            } else {
                debug && console.log ('doCaseSearch raw search.  No search criteria entered in UI');
            }

        } else {
            // non-Raw Search (using fixedSearchCriteria)
            if (newSearch) {

                //add fixed search criteria
                if (searchConfig.fixedSearchCriteria) {
                    const fixedSearchCriteria = searchConfig.fixedSearchCriteria;
                    let newSearchCriteria = this.state.searchCriteria;
                    for (let i = 0; i < fixedSearchCriteria.length; ++i) {
                        let val = fixedSearchCriteria[i].value
                        if (val.startsWith("$USER")) {
                            val = getUserValue(val, this.props.userDetails)
                        }
                        newSearchCriteria[fixedSearchCriteria[i].templateKey + "~" + fixedSearchCriteria[i].metadataKey] = val;
                    }
                    this.setState({"searchCriteria": newSearchCriteria});
                }

                //extract templateKey & metadataKey and populate search criteria array
                Object.entries(this.state.searchCriteria).forEach(entry => {
                    //get field config
                    const templateKey = entry[0].split("~")[0];
                    const metadataKey = entry[0].split("~")[1];
                    let fieldConfig = getFieldConfig(this.props.metadataConfig, templateKey,metadataKey);
                    if (fieldConfig.elasticField) {
                        searchCriteriaArray.push({
                            metadata_key: fieldConfig.elasticField,
                            value: entry[1],
                            type: fieldConfig.operator ? fieldConfig.operator : "equals"
                        })
                    } else {
                        debug && console.log ('Unable to find elasticField for ' +  templateKey + ' - '+ metadataKey);
                    }
                });
            } else {
                searchCriteriaArray = this.state.searchCriteria;
            }

            //Add caseType to searchCriteriaArray
            if (searchConfig.elastic.caseType) {
                if (searchConfig.elastic.caseType !== "") {
                    searchCriteriaArray.push({
                        raw_key: "case_type",
                        value: searchConfig.elastic.caseType
                    })
                }
            }

            pathVar = (indexes.length > 0 ?  "/" + indexes.toString()  : "") + window.REACT_APP_CASE_API_SEARCH
            url = window.REACT_APP_CASE_API_BASE_URL + pathVar + params;
            body = searchCriteriaArray;
        }

        await this.props.triggerRefreshAuthToken();

        request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
            body: JSON.stringify(body)
        };
        debug && console.log ('doCaseSearch URL:', url, 'BODY: ', body, 'REQUEST: ', request);



        const isFetching = true;
        this.props.updateIsFetching(isFetching, {}, newSearch,searchOffset);
        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    debug && console.log ('response ok - doCaseSearch RESPONSE: ', response);
                    return response.json();
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, "retrieving search results" ))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log (" doCaseSearch error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(resultA => {
                debug && console.log ('doCaseSearch response.json: ', resultA);

                if (resultA) {
                    //TODO NEXT - populate search results table - note paging changes as per Dave's slack
                    //counter = resultA.offset;
                    counter = 0 //TODO counter =0 temporarily
                    totalCount = resultA.total_hits;

                    //Add folderdetails fields & header fields to list of fields to be extracted from the search results - used when folder is opened from the search results table
                    const resultsCols = searchConfig.resultsColumns.concat(searchConfig.folderDetails.fields).concat(searchConfig.folderDetails.header)
                    let resultsColumns = JSON.parse(JSON.stringify(resultsCols)); //deep clone

                    for (let i = 0; i < resultA.results.length; i++) {
                        rows.push(getDataForSearchResultsTableElastic(resultA.results[i], resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig, counter))
                    }
                }

            })
            .then(result => {
                //Update Parent State with search results
                // updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount)
                this.props.updateSearchResults(rows, "folder", true, searchCriteriaArray, "", totalCount );
                this.handleClearSearch();
            })
            .catch(e => {
                debug && console.log (" doCaseSearch Exception:", e, "url:", url, "request: ", request);
                this.props.updateIsFetching(false, {}, true, searchOffset);
                // //if showing folder details and the search errors then hide the search results
                // const displaySearchResults = !Object.entries(folderDetails).length;
                // this.props.updateSearchResults(rows, searchType, displaySearchResults, searchCriteriaArray, "", totalCount);
            })
    };

    doSearchMetadata = async (searchType, newSearch) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        try {

            let request = {};
            let url = "";

            let searchOffset = newSearch ? 0 : this.props.searchOffset;

            let searchConfig = {};
            if (searchType === "folder") {
                searchConfig = this.props.selectedSearchConfig.folderSearch
            } else {
                searchConfig = this.props.selectedSearchConfig.documentSearch
            }
            //const watson = searchConfig.watson;
            const recursive = searchConfig.recursive;
            const searchQuery =searchConfig.box;

            let fields = [];
            //add standard fields
            fields.push("name", "created_by","created_at","modified_at","version","shared_link","sequence_id","size")
            //get metadata list to query from config
            if (searchQuery.metadataKeys) {
                for (let i = 0; i < searchQuery.metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey + "." + searchQuery.metadataKeys[i])
                }
            }

            //Add a field for each result column to allow us to get metadata from other metadata templates
            const resultsColumns =  JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone
            resultsColumns.forEach(col => {
                if (col.templateKey !== "n/a") {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + col.templateKey + "." + col.metadataKey)
                }
            });

            let query = [];
            let query_params = {};
            let queryValid = true;
            let order_by= searchQuery.order_by ? searchQuery.order_by : [];
            let queryParams = searchQuery.queryParams;

            let n=0;
            for (let p = 0; p < queryParams.length; p++)  {

                //if values provided in config, use them, otherwise get values from search values entered on searchTemplate
                if (queryParams[p].values) {
                    let vals = [];
                    for (let v = 0; v < queryParams[p].values.length; v++) {
                        vals.push(":val" + n);
                        let val = queryParams[p].values[v];
                        if (val.startsWith("$USER")) {
                            val = getUserValue(val, this.props.userDetails)
                        }
                        query_params["val" + n] = val;
                        n++
                    }
                    if (vals.length > 1 ){
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " (" + vals.join() + ")")
                    } else {
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + vals[0]);
                    }

                    if (newSearch) {
                        //update fixed values in state.searchCriteria
                        let newSearchCriteria = this.state.searchCriteria;
                        //TODO Need to update to handle multiple values when paging implemented????????????????....will work for Hyperion but need to future proof
                        //debug && console.log ('queryParams[p] = ', p, queryParams[p])
                        newSearchCriteria[searchQuery.templateKey + "~" + queryParams[p].paramName] = queryParams[p].values[0];
                        this.setState({"searchCriteria": newSearchCriteria});
                        //debug && console.log ('searchCriteria = ', newSearchCriteria)
                    }
                }

                else {
                    //get values from searchCriteria
                    let val = this.state.searchCriteria[searchQuery.templateKey + "~" + queryParams[p].metadataKey];
                    let fieldConfig = getFieldConfig(this.props.metadataConfig,searchQuery.templateKey , queryParams[p].metadataKey )

                    if (!val) {
                        if (queryParams[p].mandatory) {
                            //mandatory value not provided so return an error
                            queryValid = false
                            debug && console.log (" Unable to find " + searchQuery.templateKey + "~" + queryParams[p].metadataKey + " value required for document search")
                            this.props.enqueueSnackbar(fieldConfig.label + " value required for document search", {variant: 'error'});
                        }
                    } else {

                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " :val" + n);

                        if (fieldConfig.type === "date") {
                            let dateVal = new Date(val);
                            dateVal.setUTCHours(0,0,0,0);
                            query_params["val" + n] = dateVal ;
                        } else {
                            if (queryParams[p].operation === "ILIKE" || queryParams[p].operation === "LIKE") {
                                query_params["val" + n] = '%' + val + '%';
                            } else {
                                query_params["val" + n] = val;
                            }
                        }
                        n++;
                    }
                }
            }

            debug && console.log ('doSearchMetadata query = ', query, 'query_params = ', query_params);

            const folderId = searchConfig.folderIds[0];

            if (!folderId || folderId === "" ){
                this.props.enqueueSnackbar("Folder ID required for search", {variant: 'error'});
            }


            if (query.length ===  0) {
                //============================================= Get all files in folder================================

                debug && console.log ('- No search criteria specified so get all files/folders in folder');

                //No search criteria specified so return all files/folders in folder
                let pathVar = "/" + folderId + "/" ;
                if (searchType === "folder") {
                    pathVar = pathVar + "folders";
                } else {
                    pathVar = pathVar + "files";
                }

                //&fields=metadata.scope.templateName
                //get template names required from search results columns config
                let templateNames = [];
                if (searchConfig.resultsColumns) {
                    searchConfig.resultsColumns.forEach(col => {
                        if (col.templateKey !== "n/a") {
                            if (templateNames.indexOf(col.templateKey) === -1) {
                                templateNames.push(col.templateKey)
                            }
                        }
                    })
                }

                let fieldsStr = "name,created_at,modified_at,version,created_by,shared_link,sequence_id,size";
                if (templateNames.length > 0) {
                    const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
                    fieldsStr = fieldsStr + "," + templateNamesStr
                }

                const queryString =  "?userId=" + this.props.userDetails.boxId + "&recursive=" + recursive + '&limit=' + this.props.searchLimit + '&offset=' + searchOffset + '&fields=' + fieldsStr;

                url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + queryString;

                request = {
                    headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
                };

                debug && console.log ('doSearchMetadata (no filters) REQUEST.  url:', url, 'request: ', request);

                let rows = [];
                let totalCount = 0;
                counter = 0;

                //update parent state with search status
                const folderDetails = this.props.folderIdSearch !== "" ? this.props.folderDetails : {};

                const isFetching = true;
                this.props.updateIsFetching(isFetching, folderDetails, newSearch, searchOffset);

                await this.props.triggerRefreshAuthToken();
                fetch(url, request)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            debug && console.log ('response not ok - doSearchMetadata (no filters) RESPONSE: ', response);
                            Promise.resolve(getErrorMessageFromResponse(response, "retrieving search results" ))
                                .then(message => {
                                    this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                                })

                            debug && console.log (" doSearchMetadata (no filters) error. url:", url, "request: ", request, "response:", response);
                            return null
                        }
                    })
                    .then(resultA => {
                        debug && console.log ('doSearchMetadata (no filters) response.json: ', resultA);
                        if (!resultA ) {
                            rows=[]
                        } else {
                            counter = resultA.offset;
                            totalCount = resultA.total_count;
                            if (!resultA.entries || resultA.entries.length === 0) {
                                //note, when getting all files in folder, entries property doesn't exist in response if no results
                                rows = [];
                            } else {
                                resultA.entries.forEach((item, i) => {
                                    const resultsColumns = JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone
                                    debug && console.log(i, item);
                                    rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig, counter))
                                })
                            }
                        }
                    })
                    .then(result => {
                        //Update Parent State with search results
                        // updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount)
                        this.props.updateSearchResults(rows, searchType, true, this.state.searchCriteria, "", totalCount );
                        this.handleClearSearch();
                    })
                    .catch(e => {
                        debug && console.log (" doSearchMetadata (no filters) Exception:", e, "url:", url, "request: ", request);
                        this.props.updateIsFetching(false, folderDetails, true, 0);
                        //if showing folder details and the search errors then hide the search results
                        const displaySearchResults = !Object.entries(folderDetails).length;
                        this.props.updateSearchResults(rows, searchType, displaySearchResults, this.state.searchCriteria, "", totalCount, "");
                    })

            } else {

                //============================================= Metadata API Search ================================

                let nextMarker = this.props.nextMarker;

                //Proceed if all required values are available
                if (queryValid && folderId ) {
                    let body = {
                        "ancestor_folder_id": folderId,
                        "fields": fields,
                        "limit": window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT,
                        "from": "enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey,
                        "query": query.join(" AND "),
                        "query_params": query_params,
                        "order_by": order_by,
                        "marker": newSearch ? "" : nextMarker
                    }

                    await this.props.triggerRefreshAuthToken();

                    request = {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + this.props.userDetails.accessToken
                        },
                        body: JSON.stringify(body)
                    };
                    url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;
                    debug && console.log ('doSearchMetadata REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

                    let rows = [];
                    let totalCount = 0;

                    const isFetching = true;
                    //updateIsFetching(isFetching, folderDetails , newSearch, searchOffset
                    this.props.updateIsFetching(isFetching, {}, newSearch, searchOffset);

                    fetch(url, request)
                        .then(response => {
                            if (response.ok) {
                                return (response.json())
                            } else {
                                //debug && console.log ('response not ok - doSearchMetadata RESPONSE: ', response);
                                Promise.resolve(getErrorMessageFromResponse(response, "retrieving search results" ))
                                    .then (message => {
                                        this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                                    })

                                debug && console.log ("doSearchMetadata error. url:", url, "request: ", request, "response:", response);
                                this.setState({isFetching: false});
                                return null
                            }
                        })
                        .then(resultA => {
                            debug && console.log ('doSearchMetadata response.json: ', resultA);

                            //with paging
                            if (resultA) {
                                const resultsColumns =  JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone
                                nextMarker = resultA.next_marker;
                                totalCount = resultA.total_count;
                                if (totalCount === 0 || !resultA.entries) {
                                    rows = [];
                                } else {
                                    resultA.entries.forEach((item, i) => {
                                        debug && console.log(i, item);
                                        rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                                    })
                                }
                            }
                        })
                        .then(result => {
                            //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount, nextMarker)
                            this.props.updateSearchResults(rows, searchType,true, this.state.searchCriteria, "", totalCount, nextMarker);
                        })
                        .catch(e => {
                            this.setState({isFetching: false});
                            debug && console.log (" doSearchMetadata Exception:", e, "url:", url, "request: ", request);
                        })
                } else {
                    debug && console.log ('query params incomplete')
                    this.setState({isFetching: false});
                    this.props.updateSearchResults([], searchType, true, this.state.searchCriteria, "", 0, "", newSearch);
                }
            }
        } catch (e) {
            console.error(e);
            this.setState({isFetching: false});
            this.props.updateSearchResults([], searchType, true, this.state.searchCriteria, "", 0, "", newSearch);
        }
    };


    /**
     * handleOnChangeSearchCriteria
     *
     * Note, input fields are named the same as the state properties
     * so use this generic function to update the state properties
     *
     * @param event
     */

    handleOnChangeSearchCriteria = (id, newValue) => {

        // const debug = window.location.pathname.toLowerCase().includes("debug");
        //console.log ('handleOnChangeSearchCriteria id=', id, 'newValue=', newValue)

        //need this temporary object to ensure all values are maintained
        let newSearchCriteria = this.state.searchCriteria;

        if (newValue === "" ){
            delete newSearchCriteria[id]
        } else {
            newSearchCriteria[id] = newValue;
        }

        this.setState({"searchCriteria" : newSearchCriteria});

    };


    handleOnChangeSearchCriteriaSimple = event => {

        //need this temporary object to ensure all values are maintained
        let newSearchCriteria = this.state.searchCriteriaSimple;
        newSearchCriteria = event.target.value;

        this.setState({"searchCriteriaSimple" : newSearchCriteria});
    };

    handleSearchClickSimple() {

        if (this.state.searchCriteriaSimple === "") {
            alert ('Please enter search criteria');
        } else {
            //this.props.resetSearchOffset();
            this.doSearchSimple(true);
        }
    }

    handleShowMessageDialog() {

        // this.setState({
        //     showMessageDialog: true});
        this.setState({
            messageDialog: {
                show: true,
                title: "title here",
                message: "message..."
            }
        })
    }

    handleCloseMessageDialog = () => {

        this.setState({
            messageDialog: {
                show: false,
                title: "",
                message: ""
            }
        });
    };

    handleOpenPopover = event => {
        this.handleClearSearch(); //New to prevent criteria from url search paramaters from persisting
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClosePopover = () => {
        this.setState({
            anchorEl: null,
            autoOpenAdvancedSearch: false
        });
    };

    onClickNoAction = (event) => {

        //dummy action to prevent console warning
    };

    render() {

        //const debug = window.location.pathname.toLowerCase().includes("debug");
        //console.log ('Search: render.  props= ', this.props);

        const { anchorEl } = this.state;
        const popoverOpen = Boolean(anchorEl) || this.state.autoOpenAdvancedSearch;
        const { classes, ...other } = this.props;

        /*
            If simple search disabled/don't have access then grey it out and on clicking it, auto-expand advanced search
            If user doesn't have access to advanced search for files or folders
            If simple search not available and advanced search not available then hide the search field altogether
        */

        const enableSimpleSearch = this.props.simpleSearchActionConfig.enabled && hasAccess(this.props.simpleSearchActionConfig, this.props.userDetails.boxPortalRole);

        const enableAdvancedSearchFiles = this.props.advancedSearchFilesActionConfig.enabled &&
            hasAccess(this.props.advancedSearchFilesActionConfig, this.props.userDetails.boxPortalRole) &&
                this.props.selectedSearchConfig.documentSearch.searchFields.length > 0;

        const enableAdvancedSearchFolders = this.props.advancedSearchFoldersActionConfig.enabled &&
            hasAccess(this.props.advancedSearchFoldersActionConfig, this.props.userDetails.boxPortalRole) &&
                this.props.selectedSearchConfig.folderSearch.searchFields.length > 0;

        const enableAdvancedSearch = enableAdvancedSearchFiles || enableAdvancedSearchFolders;

        const showSearch = enableSimpleSearch || enableAdvancedSearch;

        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                this.handleSearchClickSimple();
            }
        }

        return (
            <React.Fragment>
                <span style={{display: showSearch ? "inline" : "none"}}>
                    <FormControl style={{verticalAlign: "middle"}}>
                        <Input
                            id="inputSearch"
                            name="inputSearch"
                            value={this.state.searchCriteriaSimple}
                            placeholder= {enableSimpleSearch ? "Enter search criteria" : ""}
                            type="search"
                            margin="dense"
                            variant="outlined"
                            autoComplete=""
                            onKeyDown={handleKeyDown}
                            onClick={(!enableSimpleSearch && !popoverOpen) ? this.handleOpenPopover : this.onClickNoAction}
                            onChange={(enableSimpleSearch) ? this.handleOnChangeSearchCriteriaSimple : (!popoverOpen) ? this.handleOpenPopover : this.onClickNoAction}
                            className={popoverOpen ? classes.searchFieldDisabled : classes.searchField}
                            endAdornment={
                                <InputAdornment position="start">

                                    {!popoverOpen && enableSimpleSearch &&
                                        <i ref={ref} style={{paddingBottom: "4px"}} className="material-icons md-dark" onClick={this.handleSearchClickSimple}>search</i>
                                    }

                                    {enableAdvancedSearch && this.state.anchorEl === null &&
                                        <i style={{paddingBottom: "6px"}} className='material-icons  md-dark' onClick={this.handleOpenPopover}>expand_more</i>
                                    }

                                    {enableAdvancedSearch && this.state.anchorEl !== null &&
                                        <i style={{paddingBottom: "6px"}} className='material-icons  md-dark' onClick={this.handleClosePopover}>expand_less</i>
                                    }

                                    <Popover
                                        open={popoverOpen}
                                        anchorReference={"anchorPosition"}
                                        anchorPosition={{left: ((window.innerWidth + this.props.drawerWidth)/2)-204, top: 65}}
                                        //anchorPosition={{left: (window.innerWidth - this.props.searchPopoverPosition.left), top: (this.props.searchPopoverPosition.top)}}
                                        onClose={this.handleClosePopover}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                                        transformOrigin={{vertical: 'top', horizontal: 'left',}}
                                    >

                                        <SearchTemplate
                                            {...other}
                                            handleOnChangeSearchCriteria={this.handleOnChangeSearchCriteria}
                                            handleClosePopover={this.handleClosePopover}
                                            doSearchMetadata={this.doSearchMetadata}
                                            doCaseSearch={this.doCaseSearch}
                                            updateSearchResults={this.props.updateSearchResults}
                                            searchCriteria={this.state.searchCriteria}
                                            selectedSearchConfig={this.props.selectedSearchConfig}
                                            metadataConfig={this.props.metadataConfig}
                                            optionsConfig={this.props.optionsConfig}
                                            advancedSearchFilesActionConfig={this.props.advancedSearchFilesActionConfig}
                                            advancedSearchFoldersActionConfig={this.props.advancedSearchFoldersActionConfig}
                                            handleClearSearch={this.handleClearSearch}
                                        />

                                    </Popover>

                                </InputAdornment>
                            }
                        />
                    </FormControl>
                &nbsp;&nbsp;
            </span>
            </React.Fragment>

        );
    }
}

Search.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    updateSearchResults: PropTypes.func.isRequired,
    updateIsFetching: PropTypes.func.isRequired,
    autoDrawerClose: PropTypes.func.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    remountSearch: PropTypes.func.isRequired,
    folderDetails: PropTypes.object, //optional parameter, only required for searching for documents within a folder
    drawerWidth: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    searchOffset: PropTypes.number.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    newSearch: PropTypes.bool.isRequired,
   // resetSearchOffset: PropTypes.func.isRequired,
    simpleSearchActionConfig: PropTypes.object.isRequired,
    advancedSearchFilesActionConfig: PropTypes.object.isRequired,
    advancedSearchFoldersActionConfig: PropTypes.object.isRequired,
    searchType: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    disableAutoOpenAdvancedSearch: PropTypes.bool.isRequired,
    disableAutoSearch: PropTypes.bool.isRequired,
    nextMarker: PropTypes.string,
    //searchPopoverPosition: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(Search));
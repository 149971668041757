import React from 'react';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {getDataForSearchResultsTableElastic, getDataForSearchResultsTableMetadataSearch} from "../../search/helper";
import {getErrorMessageFromResponse, getFieldConfig} from "../../common/helper";

const INITIAL_STATE = {};
let counter = 0;

class FolderDocumentsGet extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('FolderDocumentsGet props = ', props)

    }

    UNSAFE_componentWillMount() {
        if (window.REACT_APP_DOCUMENT_SOURCE === "elastic") {

            this.doElasticSearch(this.props.newSearch)

        } else {

            if (this.props.sign) {
                //TODO TEMP RPS
                //get all documents in sign subfolder
                this.getAllFolderDocumentsBoxSign()
            } else {
                if (this.props.selectedSearchConfig.folderDocuments.box.queryParams.length === 0) {
                    //no filters in config so get all files in the folder
                    window.location.pathname.toLowerCase().includes("debug") && console.log('get all files in folder');
                    this.getAllFolderDocumentsBox()
                } else {
                    //metadata search
                    window.location.pathname.toLowerCase().includes("debug") && console.log('metadata search for folder documents');
                    this.searchFolderDocumentsMetadataBox()
                }
            }
        }
    }

    componentDidMount(){
        //window.location.pathname.toLowerCase().includes("debug") && console.log('boxFolderId:', this.props.boxFolderID);
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    doElasticSearch = async (newSearch) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('FolderDocumentsGet doElasticSearch');

        let searchConfig = this.props.selectedSearchConfig.folderDocuments;
        let rows=[];
        let totalCount = 0;

        //content api
        // const url = window.REACT_APP_CONTENT_API_BASE_URL + "/api/elastic/search-raw/metadata";
        // const headers = {
        //     "Content-Type": "application/json",
        //     "Authorization": "Bearer " + this.props.userDetails.accessToken,
        //     "case-token": this.props.userDetails.caseAccessToken
        // }

        await this.props.triggerRefreshAuthToken();

        //screening api
        const url = window.REACT_APP_BASE_URL_SCREENING + "/internal/case/screeningresults";
        const headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.props.userDetails.accessToken,
            "case-token": this.props.userDetails.caseAccessToken
        }

        const body = {
            term: {
                "metadata.case_id.keyword": {
                    "value": this.props.folderDetails.id
                }
            }
        }

        let searchOffset = newSearch ? 0 : this.props.searchOffset;

        //TODO Add Paging?  Supported by rawsearch?
        //const page = newSearch ? 0 : (this.props.searchOffset/this.props.searchLimit)
        //const params =  "?page=" + page + "&pageSize=" + this.props.searchLimit;
        //url = url + params;

        const request = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('doElasticSearch REQUEST.  url:', url, 'body: ', body, 'request: ', request);

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('response.ok - doElasticSearch RESPONSE: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('response not ok - doElasticSearch RESPONSE: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log ((new Date()).toLocaleTimeString() + " doElasticSearch error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(resultA => {

                window.location.pathname.toLowerCase().includes("debug") && console.log ('doElasticSearch response.json: ', resultA);

                if (resultA) {

                    //TODO NEXT - populate search results table - note paging changes as per Dave's slack
                    //counter = resultA.offset;
                    counter = 0 //TODO counter =0 temporarily
                    totalCount = resultA.total_hits;

                    //Add folderdetails fields & header fields to list of fields to be extracted from the search results - used when folder is opened from the search results table
                    let resultsColumns = JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone

                    for (let i = 0; i < resultA.length; i++) {
                        rows.push(getDataForSearchResultsTableElastic(resultA[i], resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig, counter))
                    }
                } else {
                    this.props.enqueueSnackbar("Error retrieving search results ", {variant: 'error'});
                }

            })
            .then(result => {
                //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount) {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('rows = ', rows);
                this.props.updateSearchResults(rows, totalCount, this.props.newSearch, searchOffset );
            })
            .catch(e => {
                this.setState({isFetching: false});
                window.location.pathname.toLowerCase().includes("debug") && console.log("doElasticSearch Exception:", e, "url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error retrieving search results " + e, {variant: 'error'});
                this.props.updateSearchResults(rows, totalCount, this.props.newSearch, searchOffset );
            })
    };

    getAllFolderDocumentsBox = async () => {
        //Get all files in folder

        let searchConfig = this.props.selectedSearchConfig.folderDocuments;
        let searchOffset = this.props.newSearch ? 0 : this.props.searchOffset;

        //get template names required from search results columns config
        let templateNames = [];
        if (searchConfig.resultsColumns) {
            searchConfig.resultsColumns.forEach(col => {
                if (col.templateKey !== "n/a") {
                    if (templateNames.indexOf(col.templateKey) === -1) {
                        templateNames.push(col.templateKey)
                    }
                }
            })
        }

        let fieldsStr = "name,created_at,modified_at,version,shared_link,sequence_id,size";
        if (templateNames.length > 0) {
            const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
            fieldsStr = fieldsStr + "," + templateNamesStr
        }

        const folderId = this.props.folderDetails.box_id ? this.props.folderDetails.box_id : this.props.folderDetails.id;
        const pathVar = "/" + folderId + "/files";
        const queryString =  "?userId=" + this.props.userDetails.boxId + "&recursive="+ searchConfig.recursive + "&limit=" + this.props.searchLimit + "&offset=" + this.props.searchOffset + '&fields=' + fieldsStr;
       
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + queryString;

        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log('getAllFolderDocumentsBox REQUEST.  url:', url, 'request: ', request);

        let rows = [];
        let totalCount = 0;
        this.setState({isFetching: true});

        await this.props.triggerRefreshAuthToken();
        fetch(url, request)
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('getAllFolderDocumentsBox response =', response);
                if (response.ok) {
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - getAllFolderDocumentsBox RESPONSE: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                        .then(message => {
                            this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getAllFolderDocumentsBox error. url:", url, "request: ", request, "response:", response);
                    this.setState({isFetching: false});
                    return null
                }
            })
            .then(resultA => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('getAllFolderDocumentsBox response.json: ', resultA);

                if (Array.isArray(resultA)) {
                    //without paging
                    resultA.forEach((item, i) => {
                        rows.push(getDataForSearchResultsTableMetadataSearch(item, this.props.selectedSearchConfig.folderDocuments.resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                    })
                } else {
                    //with paging
                    if (resultA !== null) {
                        totalCount = resultA.total_count;
                        if (totalCount === 0) {
                            rows = [];
                        } else {
                            resultA.entries.forEach((item, i) => {
                                rows.push(getDataForSearchResultsTableMetadataSearch(item, this.props.selectedSearchConfig.folderDocuments.resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                            })
                        }
                    }
                }
            })
            .then(result => {
                //updateSearchResults(searchResults, totalCount, newSearch, nextMarker, searchOffset) {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('rows = ', rows);
                this.props.updateSearchResults(rows, totalCount, this.props.newSearch , null ,searchOffset);
            })
            .catch(e => {
                this.setState({isFetching: false});
                window.location.pathname.toLowerCase().includes("debug") && console.log("getAllFolderDocumentsBox Exception:", e, "url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error retrieving search results " + e, {variant: 'error'});
                this.props.updateSearchResults(rows, totalCount, this.props.newSearch );
            })
    };

    getAllFolderDocumentsBoxSign = async () => {

        console.log ('****** Getting Sign Documents....');
        //Get all files in folder

        let searchConfig = this.props.selectedSearchConfig.folderDocuments;
        let searchOffset = this.props.newSearch ? 0 : this.props.searchOffset;

        //get template names required from search results columns config
        let templateNames = [];
        if (searchConfig.resultsColumns) {
            searchConfig.resultsColumns.forEach(col => {
                if (col.templateKey !== "n/a") {
                    if (templateNames.indexOf(col.templateKey) === -1) {
                        templateNames.push(col.templateKey)
                    }
                }
            })
        }

        let fieldsStr = "name,created_at,modified_at,version,shared_link,sequence_id,size";
        if (templateNames.length > 0) {
            const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
            fieldsStr = fieldsStr + "," + templateNamesStr
        }

        //const folderId = this.props.folderDetails.box_id ? this.props.folderDetails.box_id : this.props.folderDetails.id;
        //TODO TEMP
        const folderId = "171882296039"

        const pathVar = "/" + folderId + "/files";
        const queryString =  "?userId=" + this.props.userDetails.boxId + "&recursive="+ false + "&limit=" + this.props.searchLimit + "&offset=" + this.props.searchOffset + '&fields=' + fieldsStr;

        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + queryString;

        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log('getAllFolderDocumentsBox REQUEST.  url:', url, 'request: ', request);

        let rows = [];
        let totalCount = 0;
        this.setState({isFetching: true});

        await this.props.triggerRefreshAuthToken();
        fetch(url, request)
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('getAllFolderDocumentsBox response =', response);
                if (response.ok) {
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - getAllFolderDocumentsBox RESPONSE: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                        .then(message => {
                            this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getAllFolderDocumentsBox error. url:", url, "request: ", request, "response:", response);
                    this.setState({isFetching: false});
                    return null
                }
            })
            .then(resultA => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('getAllFolderDocumentsBox response.json: ', resultA);

                if (Array.isArray(resultA)) {
                    //without paging
                    resultA.forEach((item, i) => {
                        rows.push(getDataForSearchResultsTableMetadataSearch(item, this.props.selectedSearchConfig.folderDocuments.resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                    })
                } else {
                    //with paging
                    if (resultA !== null) {
                        totalCount = resultA.total_count;
                        if (totalCount === 0) {
                            rows = [];
                        } else {
                            resultA.entries.forEach((item, i) => {
                                rows.push(getDataForSearchResultsTableMetadataSearch(item, this.props.selectedSearchConfig.folderDocuments.resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                            })
                        }
                    }
                }
            })
            .then(result => {
                //updateSearchResults(searchResults, totalCount, newSearch, nextMarker, searchOffset) {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('rows = ', rows);
                this.props.updateSearchResults(rows, totalCount, this.props.newSearch , null ,searchOffset);
            })
            .catch(e => {
                this.setState({isFetching: false});
                window.location.pathname.toLowerCase().includes("debug") && console.log("getAllFolderDocumentsBox Exception:", e, "url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error retrieving search results " + e, {variant: 'error'});
                this.props.updateSearchResults(rows, totalCount, this.props.newSearch );
            })
    };

    searchFolderDocumentsMetadataBox = async () => {

        try {

            window.location.pathname.toLowerCase().includes("debug") && console.log('searchFolderDocumentsMetadataBox.  props=', this.props);

            let request = {};
            let url = "";
            let nextMarker = this.props.newSearch ? null : this.props.nextMarker;

            //const watson = this.props.selectedSearchConfig.folderDocuments.watson;
            //const recursive = this.props.selectedSearchConfig.folderDocuments.recursive;
            //let searchOffset = this.props.newSearch ? 0 : this.props.searchOffset;

            url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;

            const searchQuery = this.props.selectedSearchConfig.folderDocuments.box;

            let fields = [];
            //add standard fields
            fields.push("name", "created_by","created_at","modified_at","version","shared_link","sequence_id","size")
            //get metadata list to query from config
            if (searchQuery.metadataKeys) {
                for (let i = 0; i < searchQuery.metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey + "." + searchQuery.metadataKeys[i])
                }
            }

            //Add a field for each result column to allow us to get metadata from other metadata templates
            this.props.selectedSearchConfig.folderDocuments.resultsColumns.forEach(col => {
                if (col.templateKey !== "n/a"){
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + col.templateKey + "." + col.metadataKey)
                }
            });

            let query = [];
            let query_params = {};
            let queryValid = true;
            let order_by= searchQuery.order_by ? searchQuery.order_by : [];

            let queryParams = searchQuery.queryParams;
            //Dawsongroup workaround for Compliance Pack until Box release fix for indexing issue
            //let doc_type_workaround = false;
            if (this.props.filterFolderDocumentsByMetadata) {
                let filterFolderDocumentsByMetadataActionConfig = this.props.actionsConfig.filterFolderDocumentsByMetadata;
                if (filterFolderDocumentsByMetadataActionConfig) {

                    if (filterFolderDocumentsByMetadataActionConfig.additionalQueryParams) {
                        if (filterFolderDocumentsByMetadataActionConfig.additionalQueryParams.length > 0) {
                            queryParams = queryParams.concat(filterFolderDocumentsByMetadataActionConfig.additionalQueryParams)
                        }
                    }

                    //apply filter based on dependency (e.g. business unit for dawson)
                    if (filterFolderDocumentsByMetadataActionConfig.additionalQueryParamsWithDependency) {
                        const dependency = filterFolderDocumentsByMetadataActionConfig.additionalQueryParamsWithDependency.dependency;
                        if (dependency) {
                            //get value for dependency
                            const dependencyValue = this.props.folderDetails[dependency.templateKey + "~" + dependency.metadataKey];
                            let additionalParams = filterFolderDocumentsByMetadataActionConfig.additionalQueryParamsWithDependency.params[dependencyValue];
                            window.location.pathname.toLowerCase().includes("debug") && console.log('additionalQueryParamsWithDependency dependency =', dependency, 'dependencyValue = ', dependencyValue, ' additionalParams = ', additionalParams);
                            if(additionalParams) {
                                if (additionalParams.length > 0) {
                                    queryParams = queryParams.concat(additionalParams)
                                }
                            }

                        }
                    }

                    order_by = order_by.concat(filterFolderDocumentsByMetadataActionConfig.additional_order_by)
                }
            }

            let n=0;
            for (let p = 0; p < queryParams.length; p++)  {
                //if values provided in config (e.g. for metadata filter) , use them, otherwise get values from folder
                if (queryParams[p].values) {
                    let vals = [];
                    for (let v = 0; v < queryParams[p].values.length; v++) {
                        vals.push(":val" + n);
                        query_params["val" + n] = queryParams[p].values[v];
                        n++
                    }
                    if (vals.length > 1 ){
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " (" + vals.join() + ")")
                    } else {
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + vals[0]);
                    }
                } else {
                    //get values from folder
                    let val = this.props.folderDetails[queryParams[p].templateKey + "~" + queryParams[p].metadataKey];
                    let fieldConfig = getFieldConfig(this.props.metadataConfig,queryParams[p].templateKey , queryParams[p].metadataKey )

                    if (!val) {
                        if (queryParams[p].mandatory) {
                            //mandatory value not provided so return an error
                            queryValid = false
                            window.location.pathname.toLowerCase().includes("debug") && console.log("Unable to find " + queryParams[p].templateKey + "~" + queryParams[p].metadataKey + " value required for document search")
                            this.props.enqueueSnackbar(fieldConfig.label + " value required for document search", {variant: 'error'});
                        }
                    } else {
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " :val" + n);
                        if (fieldConfig.type === "date") {
                            let dateVal = new Date(val);
                            dateVal.setUTCHours(0,0,0,0);
                            query_params["val" + n] = dateVal ;
                        } else {
                            if (queryParams[p].operation === "ILIKE" || queryParams[p].operation === "LIKE") {
                                query_params["val" + n] = '%' + val + '%';
                            } else {
                                query_params["val" + n] = val;
                            }
                        }
                        n++;
                    }
                }
            }


            window.location.pathname.toLowerCase().includes("debug") && console.log ('query = ', query, 'query_params = ', query_params);

            const folderId = this.props.folderDetails.box_id ? this.props.folderDetails.box_id : this.props.folderDetails.id;

            if (!folderId ){
                this.props.enqueueSnackbar("Folder ID required for document search", {variant: 'error'});
            }

            //Proceed if all required values are available
            if (queryValid && folderId ) {
                let body = {
                    "ancestor_folder_id": folderId,
                    "fields": fields,
                    "limit": window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT,
                    "from": "enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey,
                    "query": query.join(" AND "),
                    "query_params": query_params,
                    "order_by": order_by,
                    "marker": nextMarker,
                  //  "doc_type_workaround": doc_type_workaround //TODO TEMP fix
                }

                if (this.props.filterFolderDocuments) {
                     let filterFolderDocumentsActionsConfig = this.props.actionsConfig.filterFolderDocuments;
                     if (filterFolderDocumentsActionsConfig) {
                         if (filterFolderDocumentsActionsConfig.order_by) {
                             body.order_by = order_by.concat(filterFolderDocumentsActionsConfig.order_by);
                         }
                         if (filterFolderDocumentsActionsConfig.group_by) {
                             body.group_by = filterFolderDocumentsActionsConfig.group_by
                         }
                     }
                } else {
                    let filterFolderDocumentsActionsConfig = this.props.actionsConfig.filterFolderDocuments;
                    if (filterFolderDocumentsActionsConfig) {
                        if (filterFolderDocumentsActionsConfig.offQuery) {
                            if (filterFolderDocumentsActionsConfig.offQuery.order_by) {
                                body.order_by = order_by.concat(filterFolderDocumentsActionsConfig.offQuery.order_by);
                            }
                            if (filterFolderDocumentsActionsConfig.offQuery.group_by) {

                                let group_by = JSON.parse(JSON.stringify(filterFolderDocumentsActionsConfig.offQuery.group_by));  //deep clone
                                let only_docs_val;
                                if (group_by) {
                                    if (group_by.only_docs) {
                                        if (group_by.only_docs[0].value) {
                                            if (group_by.only_docs[0].value.templateKey) {
                                                only_docs_val = this.props.folderDetails[group_by.only_docs[0].value.templateKey + "~" + group_by.only_docs[0].value.metadataKey];
                                                window.location.pathname.toLowerCase().includes("debug") && console.log ("only_docs_val = ", only_docs_val);
                                            }
                                            let fieldConfig = getFieldConfig(this.props.metadataConfig,group_by.only_docs[0].value.templateKey , group_by.only_docs[0].value.metadataKey  )
                                            if (only_docs_val) {
                                                group_by.only_docs[0].value = fieldConfig.type === "date" ? new Date(only_docs_val) : only_docs_val;
                                            }
                                        }
                                    }
                                }

                                //exclude group by if only_docs value not available - i.e. hireStartDate for Internal DawsonGroup
                                if (only_docs_val) {
                                    body.group_by = group_by;
                                }
                            }
                        }
                    }
                }

                request = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };

                window.location.pathname.toLowerCase().includes("debug") && console.log('searchFolderDocumentsMetadataBox REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

                let rows = [];
                let totalCount = 0;
                this.setState({isFetching: true});

                await this.props.triggerRefreshAuthToken();
                fetch(url, request)
                    .then(response => {
                        if (response.ok) {
                            return (response.json())
                        } else {
                            window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - searchFolderDocumentsMetadataBox RESPONSE: ', response);
                            Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                                .then(message => {
                                    this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                                })
                            window.location.pathname.toLowerCase().includes("debug") && console.log("searchFolderDocumentsMetadataBox error. url:", url, "request: ", request, "response:", response);
                            this.setState({isFetching: false});
                            return null
                        }
                    })
                    .then(resultA => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('searchFolderDocumentsMetadataBox response.json: ', resultA);
                        const resultsColumns = this.props.selectedSearchConfig.folderDocuments.resultsColumns;

                        if (Array.isArray(resultA)) {
                            //without paging
                            window.location.pathname.toLowerCase().includes("debug") && console.log('without paging');
                            resultA.forEach((item, i) => {
                                rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                            })
                        } else {
                            window.location.pathname.toLowerCase().includes("debug") && console.log('with paging resultA = ', resultA);
                            //with paging
                            if (resultA !== null) {
                                //totalCount = resultA.total_count;
                                totalCount = null; //need to set to null as it is sometimes returning and if set it breaks the footer (next_marker null on dawson filtered searches)
                                nextMarker = resultA.next_marker;
                                window.location.pathname.toLowerCase().includes("debug") && console.log('nextMarker = ', nextMarker);
                                if (totalCount === 0 || !resultA.entries) {
                                    rows = [];
                                } else {
                                    resultA.entries.forEach((item, i) => {
                                        window.location.pathname.toLowerCase().includes("debug") && console.log(i, item);
                                        rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                                    })
                                }
                            }
                        }
                    })
                    .then(result => {
                        this.props.updateSearchResults(rows, totalCount, this.props.newSearch, nextMarker);
                    })
                    .catch(e => {
                        this.setState({isFetching: false});
                        window.location.pathname.toLowerCase().includes("debug") && console.log("searchFolderDocumentsMetadataBox Exception:", e, "url:", url, "request: ", request);
                        this.props.enqueueSnackbar("Error retrieving search results " + e, {variant: 'error'});
                        this.props.updateSearchResults(rows, totalCount, this.props.newSearch, nextMarker );
                    })
            } else {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('query params incomplete')
                this.setState({isFetching: false});
                this.props.updateSearchResults([], 0, this.props.newSearch );
            }
        } catch (e) {
            console.error(e);
            this.setState({isFetching: false});
            this.props.updateSearchResults([], 0, this.props.newSearch );
        }
    };


    render() {

        return (

            <React.Fragment/>

        );
    }
}

FolderDocumentsGet.propTypes = {
    //boxFolderID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchLimit: PropTypes.number.isRequired,
    updateSearchResults: PropTypes.func.isRequired,
    newSearch: PropTypes.bool.isRequired,
    filterFolderDocuments: PropTypes.bool.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    nextMarker: PropTypes.string,
    searchOffset: PropTypes.number,
    sign: PropTypes.bool //temp for demo
};

export default withSnackbar((FolderDocumentsGet));
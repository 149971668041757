import {green, grey, red} from "@material-ui/core/colors";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {getReadableFileSizeString} from "../../../../common/helper";
import PropTypes from "prop-types";

export function ConfirmationMessage(props){

    const getColour = (statusIconClass) => {

        switch(statusIconClass) {
            case "redAvatar":
                return(red[500]);
            case "greenAvatar":
                return(green[500]);
            default:
                return(grey[500])
        }
    }

    return(
        <React.Fragment>
            {(props.uploadProgress > 0 && !props.isUploadComplete) &&
            <React.Fragment>
                <LinearProgress color = "secondary"/>
                <ListSubheader disableSticky>Uploading {props.uploadProgress} of {props.files.length}</ListSubheader>
            </React.Fragment> }

            <List>
                {
                    props.files.map(file => {

                        const isUploading = file.statusMessage.substring(0, 9) === "Uploading";
                        const largeFileWarning = file.useChunkApi && isUploading ? (file.partProgress !== "" ? file.partProgress : "  Large file, please wait...") : "";
                        return(
                            <React.Fragment key={"frag" + file.name}>
                                <ListItem key={"li" + file.name}>
                                    <ListItemAvatar>
                                        <Avatar style={{backgroundColor: getColour(file.statusIconClass)}}>
                                            <i className='material-icons'>{file.statusIcon}</i>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText key={"liText" + file.name }
                                                  primary={file.statusIcon === "file_upload" ?
                                                      file.name + " (" + getReadableFileSizeString(file.size) + ")":
                                                      file.name }
                                                  secondary={file.statusMessage + largeFileWarning}/>
                                </ListItem>
                            </React.Fragment>
                        )})
                }
            </List>
        </React.Fragment>
    )
}
ConfirmationMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    uploadProgress: PropTypes.number.isRequired,
    isUploadComplete: PropTypes.bool.isRequired,
    actionConfig: PropTypes.object.isRequired
};

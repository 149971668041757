import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import {getDataForSearchResultsTableElastic, getUserValue} from "../helper";
import {formatDate, getErrorMessageFromResponse, getFieldConfig} from "../../common/helper";

const styles = (theme) => ({});

function SearchCaseElastic(props){


    useEffect(() => {

        //const debug = window.location.pathname.toLowerCase().includes("debug");
        const debug = true;
        debug && console.log('SearchCaseElastic props = ', props);

        //get searchConfig from action config

        let searchConfig =  {}
        if (props.actionConfig && props.actionConfig.searchConfigByRole && Object.entries(props.actionConfig.searchConfigByRole).length > 0){

            //URL Search is role dependent.  Loop through each role configured in actionConfig to determine which searchConfig to use
            Object.entries(props.actionConfig.searchConfigByRole).every(roleConfig => {
                const userRoles = props.userDetails.boxPortalRole;
                const regex = new RegExp(roleConfig[1].regexFilter);
                const matches = userRoles.filter(role => role.match(regex));
                debug && console.log('urlSearch roleConfig=', roleConfig, 'userRoles=', userRoles, 'regexFilter=', roleConfig[1].regexFilter, 'matches=', matches)
                if (matches.length > 0) {
                    searchConfig = roleConfig[1].searchConfig && roleConfig[1].searchConfig.folderSearch ? roleConfig[1].searchConfig.folderSearch : {}
                    debug && console.log('urlSearch match found, use ', roleConfig[0], ' config - ', searchConfig)
                    //if match found return false (equivalent of break;)
                    return false
                } else {
                    //no match found, keep looping
                    return true
                }
            })

        } else {
            //search is not role dependent
            searchConfig = props.actionConfig && props.actionConfig.searchConfig && props.actionConfig.searchConfig.folderSearch;
        }

        async function doCaseSearch()  {

            let counter = 0;
            let indexes = searchConfig.elastic.indexes;
            let rows=[];
            let totalCount = 0;
            let searchOffset = 0;
            const page = 0

            let params =  "?page=" + page + "&pageSize=" + props.searchLimit;

            if(searchConfig.elastic.defaultSort){
                params = params + "&sort=" + searchConfig.elastic.defaultSort;
            }

            let url, pathVar, request, body;

            //Assume Raw Search
            pathVar = (indexes.length > 0 ?  "/" + indexes.toString()  : "")  + "/search-raw";
            url = window.REACT_APP_CASE_API_BASE_URL + pathVar + params;
            body = JSON.parse(JSON.stringify(searchConfig.elastic.searchRaw)); //deep clone to avoid config being updated

            let bodyString = JSON.stringify(body);

            //TODO also need to handle other $USER_ values, and multiples
            if (bodyString.indexOf("$USER_EMAIL") > -1 ){
                bodyString= bodyString.replace("$USER_EMAIL", getUserValue("$USER_EMAIL", props.userDetails));
                body = JSON.parse(bodyString)
            }

            if (Object.entries(props.searchCriteria).length > 0){

                if (body.bool && body.bool.must && Array.isArray(body.bool.must)) {

                    let mustArray = body.bool.must;

                    Object.entries(props.searchCriteria).forEach(entry => {

                        if (entry[0] === "case_type") {
                            //if case_type one of criteria in action config then add that separately as it's not metadata
                            mustArray.push({
                                term: {
                                    ["case_type.keyword"]: entry[1].toUpperCase()
                                },
                            })
                        } else {
                            //metadata
                            //get field config
                            const templateKey = entry[0].split("~")[0];
                            const metadataKey = entry[0].split("~")[1];
                            const fieldConfig = getFieldConfig(props.metadataConfig, templateKey, metadataKey);
                            if (fieldConfig.elasticField) {

                                //append to body
                                if (fieldConfig.type === "date") {
                                    if (fieldConfig.searchRange) {
                                        mustArray.push({
                                            "range": {
                                                ["metadata." + fieldConfig.elasticField]: {
                                                    "gte": formatDate(entry[1].from),
                                                    "lte": formatDate(entry[1].to)
                                                }
                                            }})
                                    } else {
                                        mustArray.push({
                                            term: {
                                                ["metadata." + fieldConfig.elasticField]: formatDate(entry[1])
                                            }
                                        })
                                    }
                                } else {
                                    mustArray.push({
                                        term: {
                                            ["metadata." + fieldConfig.elasticField + ".keyword"]: fieldConfig.searchUpperCase ? entry[1].toUpperCase() : entry[1]
                                        },
                                    })
                                }
                            } else {
                                console.log('SearchCaseElastic Unable to find elasticField for ' + templateKey + ' - ' + metadataKey);
                                props.enqueueSnackbar("Unable to find field for " + templateKey + ' - ' + metadataKey, {variant: 'error'});
                            }
                        }
                    });

                    //update body with advanced search criteria entered in ui
                    body.bool.must = mustArray;

                } else {
                    debug && console.log('SearchCaseElastic Search Config incorrect, needs to have bool.must at top level to support advanced search.');
                }
            } else {
                debug && console.log ('SearchCaseElastic doCaseSearch raw search.  No search criteria');
            }
            await props.triggerRefreshAuthToken();

            request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.userDetails.accessToken,
                    "case-token": props.userDetails.caseAccessToken
                },
                body: JSON.stringify(body)
            };
            debug && console.log ('doCaseSearch URL:', url, 'BODY: ', body, 'REQUEST: ', request);

            const isFetching = true;
            props.updateIsFetching(isFetching, {}, true,searchOffset);
            fetch(url, request)
                .then(response => {
                    if (response.ok) {
                        debug && console.log ('SearchCaseElastic response ok - doCaseSearch RESPONSE: ', response);
                        return (response.json())
                    } else {
                        debug && console.log ('SearchCaseElastic response NOT ok - doCaseSearch RESPONSE: ', response);
                        Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                            .then(message => {
                                props.enqueueSnackbar(message, {variant: 'error'});
                            })
                        debug && console.log ("SearchCaseElastic doCaseSearch error. url:", url, "request: ", request, "response:", response);
                        return null
                    }
                })
                .then(resultA => {
                    debug && console.log ('SearchCaseElastic doCaseSearch response.json: ', resultA);

                    if (resultA) {
                        counter = 0
                        totalCount = resultA.total_hits;

                        //Add folderdetails fields & header fields to list of fields to be extracted from the search results - used when folder is opened from the search results table
                        const resultsCols = searchConfig.resultsColumns.concat(searchConfig.folderDetails.fields).concat(searchConfig.folderDetails.header)
                        let resultsColumns = JSON.parse(JSON.stringify(resultsCols)); //deep clone

                        for (let i = 0; i < resultA.results.length; i++) {
                            rows.push(getDataForSearchResultsTableElastic(resultA.results[i], resultsColumns, window.REACT_APP_ENTERPRISE_ID, props.metadataConfig, counter))
                        }
                    }

                })
                .then(result => {
                    //Update Parent State with search results
                    // updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount)
                    props.updateSearchResults(rows, "folder", true, props.searchCriteria, "", totalCount );
                })
                .catch(e => {
                    debug && console.log ("SearchCaseElastic doCaseSearch Exception:", e, "url:", url, "request: ", request);
                    props.updateIsFetching(false, {}, true, searchOffset);
                })
        }

        if (searchConfig) {
            doCaseSearch();
        } else {
            props.enqueueSnackbar("Search config not available for url search", {variant: 'error'});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (<React.Fragment/>)
}

SearchCaseElastic.propTypes = {
    userDetails: PropTypes.object.isRequired,
    updateSearchResults: PropTypes.func.isRequired,
    updateIsFetching: PropTypes.func.isRequired,
    //selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    searchLimit: PropTypes.number.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(SearchCaseElastic))
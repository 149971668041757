import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import WorkspaceMenuList from "../menus/WorkspaceMenuList"
import {Grid} from "@material-ui/core";
import List from "@material-ui/core/List/List";
import Divider from "@material-ui/core/Divider/Divider";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LogoutMenuItem from "../auth/LogoutMenuItem";
import {hasAccess} from "../common/helper"
import CardMedia from '@material-ui/core/CardMedia'
import PublicLinkDialog from "../browse/PublicLinkDialog";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import AdminMenu from "./AdminMenu";
import TaskMenu from "./TaskMenu";
import {getInitials, getColour} from "../common/helper";
import DashboardMenu from "./DashboardMenu";
import {withSnackbar} from "notistack";
import BulkUploadButton from "../document/addDocument/bulkUpload/BulkUploadButton";

const styles = (theme) => ({
    list: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
    },

    listItemLevel1: {
        paddingBottom: "2px",
        paddingTop: "2px",
    },

    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    listAvatar: {
        //width: theme.spacing(3),
        //height: theme.spacing(3),
    },

    listItemText: {
        color: theme.menuListItem.colour
    },

    // topLevel: {
    //     paddingLeft: theme.spacing(0),
    //     paddingTop: theme.spacing(0),
    //     paddingBottom: theme.spacing(0),
    // },
    //
    topLevelText: {
        // fontWeight: 'normal',
        // marginTop: theme.spacing(.5),
        // marginBottom: theme.spacing(.5),
        // color: theme.menuListItem.groupHeaderColour,
        color: theme.menuListItem.colour
    },

    listIconNested: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    nestedLevel1: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(0),
        color: theme.menuListItem.colour
    }

});


let INITIAL_STATE = {
    showPublicLinkDialog: false
};

class MainMenu extends Component {

    constructor(props) {
        super(props);
        INITIAL_STATE.links = props.actionsConfig.links && props.actionsConfig.links.autoExpand
        this.state = INITIAL_STATE;
    }

    handlePublicLinkDialogClose = () => {
        this.setState({
            showPublicLinkDialog: false,
            publicLinkUrl: "",
            publicLinkLabel: ""
        });
    };


    openLink = (link) => {
        let url = link.url[window.REACT_APP_ENV]

        if (link.newWindow) {
            let w = window.open(url,"_blank",);
            w.document.title = link.label;
        } else {
            //open in dialog
            this.setState({
                showPublicLinkDialog: true,
                publicLinkUrl: url,
                publicLinkLabel: link.label
            })
        }
    }


    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }));

    };

    openAdminApp = () => {
        const url = window.REACT_APP_ADMIN_APP_URL;
        if (url) {
            window.open(url,"_blank" )
        } else {
            this.props.enqueueSnackbar("Admin App URL not configured", {variant: 'error'});
        }

    }

    render() {

        const { classes, ...other  } = this.props;


        const showTasks = this.props.actionsConfig.tasksWorkspaceAll && this.props.actionsConfig.tasksWorkspaceAll.enabled && hasAccess(this.props.actionsConfig.tasksWorkspaceAll, this.props.userDetails.boxPortalRole)

        return (

            <Grid container spacing={0} alignItems={'flex-start'} style={{paddingTop: '10px'}}>
                <Grid item xs={10}
                      style={{paddingLeft: '15px'}}>
                        <CardMedia
                            component="img"
                            image={window.location.origin + '/images/logo.png'}
                            title="Logo"
                        />

                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={this.props.manualDrawerClose} color={'secondary'}>
                        <i className='material-icons'>chevron_left</i>
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{height: "100%", overflowY: "auto"}}>
                    <List className={classes.list}>
                        <WorkspaceMenuList
                            {...other}
                            workspaceConfig={this.props.workspaceConfig}
                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                            handleClickMenuItem={this.props.handleSelectWorkspace}
                            listTitle={this.props.workspaceFilter !== "" ? (this.props.workspaceFilter + " Workspaces") : "Workspaces"}
                            mode={this.props.mode}
                            workspaceFilter={this.props.workspaceFilter}
                            updateWorkspaceFilter={this.props.updateWorkspaceFilter}
                            actionsConfig={this.props.actionsConfig}
                        />

                        <Divider/>



                        {
                            (this.props.actionsConfig.myDocuments.enabled && hasAccess(this.props.actionsConfig.myDocuments, this.props.userDetails.boxPortalRole)) &&

                            <React.Fragment>
                                <ListSubheader disableSticky color={'primary'}>Browse</ListSubheader>
                                <ListItem
                                    button={true}
                                    key={'mydocuments'}
                                    id={'mydocuments'}
                                    onClick={this.props.handleShowMyDocuments}
                                >
                                    <ListItemIcon  className={classes.menuListItem}><i className='material-icons'>folder_open</i></ListItemIcon>
                                    <ListItemText className={classes.menuListItem} primary={this.props.actionsConfig.myDocuments.label} />
                                </ListItem>
                                <Divider/>
                            </React.Fragment>
                        }

                        {/*{*/}
                        {/*    (this.props.actionsConfig.activityFeed && this.props.actionsConfig.activityFeed.enabled && hasAccess(this.props.actionsConfig.activityFeed, this.props.userDetails.boxPortalRole)) &&*/}
                        {/*    <React.Fragment>*/}
                        {/*        <ListSubheader disableSticky color={'primary'}>Recent Activity</ListSubheader>*/}
                        {/*            x docs uploaded in last 7 days*/}
                        {/*        <Divider/>*/}
                        {/*    </React.Fragment>*/}
                        {/*}*/}


                        {
                            (this.props.actionsConfig.bulkUpload && this.props.actionsConfig.bulkUpload.enabled && hasAccess(this.props.actionsConfig.bulkUpload, this.props.userDetails.boxPortalRole)) &&
                                <React.Fragment>
                                    <ListSubheader disableSticky color={'primary'}>Actions</ListSubheader>
                                    <BulkUploadButton
                                        {...other}
                                        parentClasses={this.props.classes}
                                        userDetails={this.props.userDetails}
                                        uploadConfig={this.props.actionsConfig.bulkUpload}
                                        metadataConfig={this.props.metadataConfig}
                                        optionsConfig={this.props.optionsConfig}
                                        closeAddDocDialog={this.closeAddDocDialog}
                                        cancelAddDocDialog={this.cancelAddDocDialog}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        actionConfig={this.props.actionsConfig.bulkUpload}
                                    />
                                    <Divider/>
                                </React.Fragment>
                        }

                        {
                            this.props.publicLinksConfig.length > 0 && window.REACT_APP_ENV !== ""  && this.props.actionsConfig.links && this.props.actionsConfig.links.enabled && hasAccess(this.props.actionsConfig.links, this.props.userDetails.boxPortalRole) &&
                            <React.Fragment>

                                <ListItem button onClick={this.handleExpandCollapse('links')}>
                                    <ListItemIcon className={classes.listIcon}>{this.state['links'] ? <ExpandMore/> : <ChevronRight/>}</ListItemIcon>
                                    <ListItemText className={classes.topLevelText}>{this.props.actionsConfig.links.label}</ListItemText>
                                </ListItem>

                                <Collapse in={this.state['links']} timeout="auto" unmountOnExit>
                                    {this.props.publicLinksConfig.map(
                                        (entry,index )=> {
                                            return(
                                                <ListItem button={true} key={'linkext'+index} id={'linkext'+index}
                                                          className={classes.nestedLevel1}
                                                          onClick={() =>this.openLink(entry)}
                                                >
                                                    <ListItemIcon className={classes.listIcon}><i className='material-icons'>{entry.icon}</i></ListItemIcon>
                                                    <ListItemText primary= {entry.label}/>
                                                    {/*<ListItemText*/}
                                                    {/*    primary={<Link href={window.REACT_APP_APP_BAR_LOGO_LINK_URL} target="_blank">{entry.label}</Link>}/>*/}
                                                </ListItem>
                                            )
                                        })
                                    }
                                </Collapse>

                                <Divider/>

                            </React.Fragment>
                        }

                        {
                            this.state.showPublicLinkDialog &&
                            <PublicLinkDialog handleClose={this.handlePublicLinkDialogClose} url={this.state.publicLinkUrl} label={this.state.publicLinkLabel}/>
                        }


                        {
                            (this.props.actionsConfig.forms.enabled && hasAccess(this.props.actionsConfig.forms, this.props.userDetails.boxPortalRole)) &&
                            <React.Fragment>

                                <ListSubheader disableSticky color={'primary'}>Forms</ListSubheader>

                                <ListItem
                                    button={true}
                                    key={'form'}
                                    id={'form'}
                                    onClick={this.props.handleShowForms}
                                >
                                    <ListItemIcon className={classes.listIcon}><i className='material-icons'>edit</i></ListItemIcon>
                                    <ListItemText
                                        className={classes.menuListItem}
                                        primary={this.props.actionsConfig.forms.label}
                                    />
                                </ListItem>

                                <Divider/>

                            </React.Fragment>
                        }

                        {showTasks &&
                            <TaskMenu
                                parentClasses={classes}
                                actionsConfig={this.props.actionsConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                handleShowAdminGeneral={this.props.handleShowAdminGeneral}
                                userDetails={this.props.userDetails}
                                openTasks={this.props.openTasks}
                            />
                        }

                        <DashboardMenu
                            parentClasses={classes}
                            userDetails={this.props.userDetails}
                            actionConfig={this.props.actionsConfig.dashboardMain}
                            dashboardsConfig={this.props.dashboardsConfig}
                            handleShowDashboard={this.props.handleShowDashboard}/>


                        <AdminMenu
                            parentClasses={classes}
                            actionsConfig={this.props.actionsConfig}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            handleShowAdminGeneral={this.props.handleShowAdminGeneral}
                            userDetails={this.props.userDetails}
                        />


                        {
                            (this.props.actionsConfig.admin && this.props.actionsConfig.admin.enabled && hasAccess(this.props.actionsConfig.admin, this.props.userDetails.boxPortalRole)) &&
                            <React.Fragment>

                                <ListItem className={classes.menuListItem} button={true} key={'admin'} id={'admin'} onClick={this.openAdminApp}>
                                    <ListItemIcon className={classes.listIcon}><i className='material-icons'>settings</i></ListItemIcon>
                                    <ListItemText className={classes.menuListItem} primary={"Administration"}/>
                                </ListItem>

                                <Divider/>

                            </React.Fragment>
                        }




                        {/*<ListSubheader disableSticky color={'primary'}>Logout</ListSubheader>*/}

                        <ListItem
                            button={false}
                            key={'profile'}
                            id={'profile'}>

                            <ListItemAvatar>
                                <Avatar className={classes.listAvatar} style={{backgroundColor: getColour(this.props.userDetails.userName)}}>
                                    {getInitials(this.props.userDetails.userName)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={this.props.userDetails.userName}
                                          secondary={<span className={classes.menuListItem}>{this.props.userDetails.userEmail}</span>}
                            />
                        </ListItem>

                        <LogoutMenuItem {...other}
                                        logout={this.props.logout}
                                        userDetails={this.props.userDetails}/>
                    </List>
                </Grid>
            </Grid>

        );
    }
}

MainMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    handleSelectWorkspace: PropTypes.func.isRequired,
    manualDrawerClose: PropTypes.func.isRequired,
    handleShowMyDocuments: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    handleShowDashboard: PropTypes.func.isRequired,
    handleShowForms: PropTypes.func.isRequired,
    handleShowAdminGeneral: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    publicLinksConfig: PropTypes.array.isRequired,
    dashboardsConfig: PropTypes.array.isRequired,
    workspaceFilter: PropTypes.string.isRequired,
    updateWorkspaceFilter: PropTypes.func.isRequired,
    openTasks: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(MainMenu));
import React from 'react';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import DocumentMenu from "../../menus/DocumentMenu";
import TasksButton from "../../document/tasks/TasksButton";
import MLButton from "../../document/ml/MLButton";
import MLButtonDemo from "../../document/ml_demo/MLButton";
import PreviewWithPropertiesButton from "../../document/preview/PreviewWithPropertiesButton";
import Preview from "../../document/preview/Preview";
import AddDocButton from "../../document/addDocument/AddDocButton"
import {withSnackbar} from "notistack";
import RelatedDocumentsButton from "../../document/relatedDocuments/RelatedDocumentsButton";
import DeleteFile from "../../document/DeleteFile";
import MoveDocumentToCaseButton from "../../document/move/MoveDocumentToCaseButton"
import ViewFileLinkButton from "../../document/link/ViewFileLinkButton";
import ViewSharedLinkButton from "../../document/link/ViewSharedLinkButton";
import DownloadFile from "../../document/download/DownloadFile";
import CommentsButton from "../../document/comments/CommentsButton";
import DownloadResults from "../../document/download/DownloadResults";
import FilterFolderDocuments from "./FilterFolderDocuments";
import FilterFolderDocumentsByMetadata from "./FilterFolderDocumentsByMetadata";
import FolderDocumentsRefresh from "./FolderDocumentsRefresh";
import {Paper} from "@material-ui/core";
import SearchCriteriaTooltip from "../../search/SearchCriteriaTooltip";
import ColumnIcon from "../../search/results/ColumnIcon";
import {
    getFieldConfig,
    getOptions,
    hasAccess,
    numberWithCommas,
    getObjectByKey,
    getYearOptions,
    isNumeric,
    getErrorMessageFromResponse
} from "../../common/helper"
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ActionButtonsDocument from "../../document/workflow/ActionButtonsDocument";
import {dynamicSort} from "../../search/helper";
import {ContentOpenWith} from "box-ui-elements";
import CompareParentButton from "../../document/compare/CompareParentButton";
import ComparePreviousButton from "../../document/compare/ComparePreviousButton";
import CompareCustomButton from "../../document/compare/CompareCustomButton";
import AuditButton from "../../audit/AuditButton";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import DisplayOptionButtons from "../../search/results/DisplayOptionButtons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CustomGroupRow from "../../common/customMaterialTable/CustomGroupRow";
import PreviewWithPropertiesDialog from "../../document/preview/PreviewWithPropertiesDialog";
import { withTranslation} from 'react-i18next'
import InfiniteScrollLoader from "../../search/results/InfiniteScrollLoader";
import InfiniteScrollEndMessage from "../../search/results/InfiniteScrollEndMessage";
import InfiniteScroll from 'react-infinite-scroll-component';
import SignRequestButton from "../../document/sign/SignRequestButton";
import FileIcon from "../../common/FileIcon";
import FileIconThumbnail from "../../common/FileIconThumbnail";

const styles = theme => ({});
const tableTheme = theme => createTheme({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides overrides the overrides in the parent theme in app.css
    overrides: {

        MuiToolbar: {
            gutters: {
                paddingLeft: theme.spacing(2)
            },
            root: {
                paddingLeft: "0px"
            },
            regular:{
                paddingLeft: "0px"
            }
        },
        MTableToolbar: {
            root: {
                paddingLeft: "0px"
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px',
                //paddingLeft: "24px"
            },
            head: {
                color: theme.fieldLabel.colour,
                "&:hover": {
                    // fontWeight: 'bold'
                }
            },
        },
        //MuiTableSortLabel-root-522.MuiTableSortLabel-active-523
        MuiTableSortLabel: {
            icon: {
                color: theme.fieldLabel.colour
            },
            root: {
                color: theme.fieldLabel.colour,
                "&:active": {
                    color: theme.fieldLabel.colour,
                    fontWeight: 'bold'
                },
            },
            active: {
                color: theme.fieldLabel.colour,
                fontWeight: 'bold'
            }
                // MuiTableSortLabel: {
                //     icon: {
                //         color: theme.fieldLabel.colour
                //     },
                //     active: {
                //         color: theme.fieldLabel.colour,
                //         fontWeight: 'bold'
                //     }
                // },
        },
        // MuiTableSortLabel: {
        //     icon: {
        //         color: theme.fieldLabel.colour
        //     },
        //     active: {
        //         color: theme.fieldLabel.colour,
        //         fontWeight: 'bold'
        //     }
        // },


        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
            },
            colorInherit: {
                color: theme.palette.secondary.main
            }
        },

        MuiAvatar: {
            colorDefault: {
                backgroundColor: theme.palette.secondary.main
            }
        }
    }
});

class SearchResultsDocumentsFolderDocsTable extends React.Component {

    constructor(props) {

        super(props);

        //window.location.pathname.toLowerCase().includes("debug") && console.log('SearchResultsDocumentsFolderDocsTable props = ' , props);

        const searchConfig = props.selectedWorkspaceConfig.searchConfig.folderDocuments;

        //Add a column for each column in workspaceConfig
        let columns = [];
        let hasEditableColumns = false;
        let enableGrouping = false;

        const tableConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.table;
        const showFileIcon = tableConfig && tableConfig.showFileIcon;
        const showThumbnail = tableConfig && tableConfig.showThumbnail;

        if (showFileIcon) {
            columns.push({
                label: "",
                field: 'name',
                filtering: false,
                sorting: false,
                cellStyle: {
                    padding: 0,
                    textAlign: "center",
                    // maxWidth: 30,
                    width: 30,
                    paddingRight: '8px'
                },
                headerStyle: {
                    textAlign: "center",
                    maxWidth: 30,
                    width: 30,
                },
                editable: "never",
                grouping: false,
                editComponent: rowData => {return (<span/>)}, //even though editable set to 'never' this is required to prevent editable field from appearing for icons
                render: rowData => {
                    return (
                        <React.Fragment>
                            {
                                showThumbnail ?
                                    <FileIconThumbnail height={'24px'} width={'24px'} file={rowData} userDetails={this.props.userDetails} triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <FileIcon height={'24px'} width={'24px'} fileName={rowData.name}/>
                            }
                        </React.Fragment>
                    )
                }
            });
        }

        searchConfig.resultsColumns.forEach((column) => {

            let fieldConfig = getFieldConfig(props.metadataConfig, column.templateKey, column.metadataKey);

            if (!column.hide) {

                if (column.displayAsIcon) {

                    let iconConfig = fieldConfig.iconConfig;
                    if (!iconConfig) {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('iconConfig not available for ' + column.templateKey + "-" + column.metadataKey);
                    }

                    columns.push({
                        title: "",
                        field: column.templateKey + "~" + column.metadataKey,
                        metadataKey: column.metadataKey,
                        templateKey: column.templateKey,
                        filtering: false,
                        sorting: false,
                        cellStyle: {
                            // maxWidth: 10,
                            // width: 10,
                            padding: 0,
                            textAlign: "center",
                            // fontSize: "13px"
                            // marginRight: 40
                        },
                        headerStyle: {
                            textAlign: "center",
                            maxWidth: 10,
                            width: 10,
                        },
                        editable: "never",
                        grouping: false,
                        editComponent: rowData => {
                            return (<span/>)
                        }, //even though editable set to 'never' this is required to prevent editable field from appearing for icons
                        render: rowData => {
                            return (
                                <ColumnIcon val={rowData[column.metadataKey]} iconConfig={iconConfig}/>
                            )
                        }
                    });

                } else {


                    let col = JSON.parse(JSON.stringify(column)); //deep clone
                    //col.cellStyle = {fontSize: "13px", fontFamily: "roboto"};
                    col.cellStyle = (e, rowData) => {
                        return {
                            color: (rowData && rowData["language"] === "en" && rowData["documentType"] === "master" && rowData["uploaded"] === "false") && "#00a3e0",
                            fontSize: "13px",
                            fontFamily: "roboto",
                            //textAlign: col.type === "numeric" ? "center" : "left" // force center alignment of numeric vals
                            paddingRight: ((col.type === "numeric" && (!col.defaultGroupOrder || col.defaultGroupOrder === 0)) || col.editable ==='always' ) ? "16px" : "0px"
                        };
                    };
                    col.headerStyle = {paddingRight: (col.type === "numeric" && (!col.defaultGroupOrder  || col.defaultGroupOrder === 0)) ? "16px" : "0px"}
                    col.title = col.label;
                    col.editable = col.editable ? "always" : "never";
                    col.field = col.templateKey === "n/a" ? col.metadataKey : col.templateKey + "~" + col.metadataKey;

                    if (col.groupOrderConditional) {
                        //override defaultGroupOrder with value from folder
                        if (props.folderDetails[col.groupOrderConditional.templateKey + "~" + col.groupOrderConditional.metadataKey]) {
                            let val = props.folderDetails[col.groupOrderConditional.templateKey + "~" + col.groupOrderConditional.metadataKey]
                            if (val) {
                                col.defaultGroupOrder = val
                            }
                        }
                    }

                    if(col.defaultGroupOrder === 0) {
                        delete col.defaultGroupOrder
                    }

                    //grouping
                    if (!col.defaultGroupOrder ) {
                        col.grouping = false
                        if (col.groupOrderConditional) {
                            //if conditional grouping criteria not met then hide the column
                            col.hidden = true
                        }
                    } else {
                        enableGrouping = true //enable grouping for table if defaultGroupOrder set for any columns
                    }

                    let options = fieldConfig.useYearOptions ? getYearOptions(fieldConfig.previousYears, fieldConfig.futureYears) : fieldConfig.options;
                    let lookup = {};
                    if (options) {
                        if (Object.entries(fieldConfig).length > 0) {
                            if (options.length > 0) {
                                lookup[fieldConfig.type === "number" ? null : ""] = '- ' + fieldConfig.placeholder + ' -'
                            }
                            for (let p = 0; p < options.length; p++) {
                                const opt = options[p];
                                lookup[opt.value] = opt.icon ?
                                    <Tooltip title={<span><i className={'material-icons'} style={{color: '#fff', fontSize: "36px"}}>{opt.icon}</i>{opt.label}</span>}>
                                        <span style={{width: '100%', display: 'block'}}>
                                            <i className={'material-icons'} style={{color: '#646464', fontSize: "14px", verticalAlign: "middle", paddingRight: '4px'}}>{opt.icon}</i>
                                            {opt.label}
                                        </span>
                                    </Tooltip>:
                                    opt.avatar ?
                                        <ListItem style={{padding: '0px'}}>
                                            <Tooltip title={<CardMedia component="img" image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                <ListItemIcon style={{minWidth: '32px'}}>
                                                    <Avatar component={'span'} alt={opt.label} style={{ width: '18px', height: '18px' }} src={window.location.origin + '/images/' + opt.avatar} />
                                                </ListItemIcon>
                                            </Tooltip>
                                            <ListItemText primary={opt.label} />
                                        </ListItem>:
                                        opt.label
                            }
                        }
                    }

                    if (Object.entries(lookup).length > 0) {
                        if (fieldConfig.fieldType ==='Input' && fieldConfig.useYearOptions && fieldConfig.type==='number') {
                            //Note, need fieldType in metadata config to be Input for the optionsDependencies to show a value in read mode on the Material-Table component in search results
                            //use custom edit component
                            window.location.pathname.toLowerCase().includes("debug") && console.log('numeric dropdown so Use custom edit component for ', col.label)
                            col.editComponent = props => {
                                const value = props.value;
                                return (
                                    <TextField
                                        select
                                        value={value}
                                        //placeholder={fieldConfig.placeholder}
                                        type={fieldConfig.type}
                                        native={true}
                                        margin="none"
                                        autoComplete=""
                                        disabled={!col.editable}
                                        // onClick={ () => { if (options.length === 0 && fieldConfig.optionsDependencies) {validate(fieldConfig.optionsDependencies, props.metadataConfig, options)}}}
                                        onChange={e => props.onChange(e.target.value)}
                                    >
                                        {
                                            options.length > 0 &&
                                            <MenuItem value={fieldConfig.type === "number" ? null : ""}>
                                                {(options[0].avatar || options[0].icon) && <ListItemIcon/>}
                                                <Typography
                                                    variant="inherit">{'- ' + fieldConfig.placeholder + ' -'}</Typography>
                                            </MenuItem>
                                        }
                                        {options.map(opt => {
                                            return (
                                                <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                                    {opt.icon ?
                                                        <Tooltip title={<span><i className={'material-icons'} style={{
                                                            color: '#fff',
                                                            fontSize: "36px"
                                                        }}>{opt.icon}</i>{opt.label}</span>}>
                                                        <span style={{width: '100%', display: 'block'}}>
                                                            <i className={'material-icons'} style={{
                                                                color: '#646464',
                                                                fontSize: "14px",
                                                                verticalAlign: "middle",
                                                                paddingRight: '4px'
                                                            }}>{opt.icon}</i>
                                                            {opt.label}
                                                        </span>
                                                        </Tooltip> :
                                                        opt.avatar ?
                                                            <Tooltip title={<CardMedia component="img"
                                                                                       image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                                <ListItem style={{padding: '0px'}}>
                                                                    <ListItemIcon style={{minWidth: '32px'}}>
                                                                        <Avatar component={'span'} alt={opt.label}
                                                                                style={{width: '18px', height: '18px'}}
                                                                                src={window.location.origin + '/images/' + opt.avatar}/>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={opt.label}/>
                                                                </ListItem>
                                                            </Tooltip> :
                                                            opt.label}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                )
                            }
                        } else {
                            col.lookup = lookup;
                            //using default edit component...
                        }
                    } else if (fieldConfig.optionsDependencies) {
                        let dependencyLabels = [];
                        if (fieldConfig.optionsDependencies.length > 0) {

                            for (let i = 0; i < fieldConfig.optionsDependencies.length; i++) {
                                const dependencyFieldConfig = getFieldConfig(this.props.metadataConfig, fieldConfig.optionsDependencies[i].templateKey, fieldConfig.optionsDependencies[i].metadataKey)
                                dependencyLabels.push(dependencyFieldConfig ? dependencyFieldConfig.label : "");
                            }

                            function validate(dependencies, metadataConfig, rowData) {
                                //check if required fields have been entered
                                let missingValues = [];
                                for (let i = 0; i < dependencies.length; i++) {
                                    if (!rowData[dependencies[i].templateKey + "~" + dependencies[i].metadataKey] && !dependencies[i].optional) {
                                        const dependencyFieldConfig = getFieldConfig(metadataConfig, dependencies[i].templateKey, dependencies[i].metadataKey)
                                        missingValues.push(dependencyFieldConfig ? dependencyFieldConfig.label : "");
                                    }
                                }
                                if (missingValues.length > 0) {
                                    if (missingValues.length === 1) {
                                        props.enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                                    } else {
                                        props.enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                                    }
                                    return false
                                } else {
                                    return true
                                }
                            }

                            col.editComponent = props => {
                                let requiredFieldsAvailable = validate(fieldConfig.optionsDependencies, this.props.metadataConfig, props.rowData);
                                let value = props.value ? props.value : "";
                                if (requiredFieldsAvailable) {
                                    options = getOptions(this.props.optionsConfig, col.templateKey, col.metadataKey, fieldConfig.optionsDependencies, props.rowData, "edit");
                                    if (options.length === 0) {
                                        if (dependencyLabels.length === 1) {
                                            //this.props.enqueueSnackbar('No options available for ' + fieldConfig.label + " with the selected " + dependencyLabels.slice(-1), {variant: 'info'});
                                            window.location.pathname.toLowerCase().includes("debug")  && console.log ('No options available for ' + fieldConfig.label + " with the selected " + dependencyLabels.slice(-1))
                                        } else {
                                            //this.props.enqueueSnackbar('No options available for ' + fieldConfig.label + " with the selected " + dependencyLabels.slice(0, dependencyLabels.length - 1).join(', ') + " and " + dependencyLabels.slice(-1), {variant: 'info'});
                                            window.location.pathname.toLowerCase().includes("debug")  && console.log('No options available for ' + fieldConfig.label + " with the selected " + dependencyLabels.slice(0, dependencyLabels.length - 1).join(', ') + " and " + dependencyLabels.slice(-1))
                                        }

                                        //Clear value if no options available to avoid invalid value being saved
                                        value = "";
                                    }
                                } else {
                                    options = []
                                }

                                //check if current value is in the list of options, clear it if not to avoid invalid value being saved
                                //or display an error?
                                let notInList = false;
                                let previousValue;
                                if (value !== "" && value !== null && value !== 'undefined') {
                                    let optionsValueList = [];
                                    for (let v = 0; v < options.length; v++) {
                                        optionsValueList.push(options[v].value)
                                    }
                                    if (!optionsValueList.includes(value)) {
                                        previousValue = value;
                                        //window.location.pathname.toLowerCase().includes("debug") && console.log ('clear value as not in list');
                                        //value = ""
                                        notInList = true;
                                    }
                                }

                                //TODO causes error re nested state - do validation onRowUpdate instead
                                // if (notInList) {
                                //     this.setState({validationError: true});
                                //     this.setState({validationErrorDetails: "'" + previousValue + "' is not a valid option"});
                                // }

                                return (

                                    <TextField
                                        select
                                        value={value}
                                        placeholder={fieldConfig.placeholder}
                                        type={fieldConfig.type}
                                        native={true}
                                        margin="none"
                                        error={notInList}
                                        helperText={notInList && "'" + previousValue + "' is not a valid option"}
                                        autoComplete=""
                                        disabled={!col.editable}
                                        // onClick={ () => { if (options.length === 0 && fieldConfig.optionsDependencies) {validate(fieldConfig.optionsDependencies, props.metadataConfig, options)}}}
                                        onChange={e => props.onChange(e.target.value)}
                                    >
                                        {
                                            options.length > 0 &&
                                            <MenuItem value = {fieldConfig.type === "number" ? null : ""}>
                                                {(options[0].avatar || options[0].icon) && <ListItemIcon/>}
                                                <Typography variant="inherit">{'- ' + fieldConfig.placeholder+ ' -'}</Typography>
                                            </MenuItem>
                                        }
                                        {options.map(opt => {
                                            return (
                                                <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                                    {opt.icon ?
                                                        <Tooltip title={<span><i className={'material-icons'} style={{color: '#fff', fontSize: "36px"}}>{opt.icon}</i>{opt.label}</span>}>
                                                            <span style={{width: '100%', display: 'block'}}>
                                                                <i className={'material-icons'} style={{color: '#646464', fontSize: "14px", verticalAlign: "middle", paddingRight: '4px'}}>{opt.icon}</i>
                                                                {opt.label}
                                                            </span>
                                                        </Tooltip>:
                                                        opt.avatar ?
                                                            <Tooltip title={<CardMedia component="img" image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                                <ListItem style={{padding: '0px'}}>
                                                                    <ListItemIcon style={{minWidth: '32px'}}>
                                                                        <Avatar component={'span'} alt={opt.label} style={{ width: '18px', height: '18px' }} src={window.location.origin + '/images/' + opt.avatar} />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={opt.label} />
                                                                </ListItem>
                                                            </Tooltip> :
                                                            opt.label}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                )
                            }
                        }
                    }

                    //to cater for IQEQ documentPeriod, where the month values and label are different
                    if (col.defaultGroupOrder && col.defaultGroupOrder > 0 && fieldConfig.optionsDependencies && fieldConfig.optionsDependencies.length > 0) {
                        col.optionsDependencies = fieldConfig.optionsDependencies;
                    }

                    columns.push(col)

                    if (column.editable) {
                        hasEditableColumns = true;
                    }

                }
            }

        });  //end resultsColumns

        //Actions columns - don't add for Sign requests

        if(!this.props.sign) {

            //append a column for ContentOpenWith component
            //if action is enabled and user role listed in config then add a column action
            if (!this.props.sign && props.actionsConfig.openWithFromTable && props.actionsConfig.openWithFromTable.enabled) {
                if (hasAccess(props.actionsConfig.openWithFromTable, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 110,
                        cellStyle: {
                            width: 110,
                            maxWidth: 110,
                            padding: 0,
                            paddingLeft: "8px"
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 110,
                            maxWidth: 110,
                            padding: 0,
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {

                            const usePreviewFor = props.actionsConfig.openWithFromTable.usePreviewFor;
                            const fileType = rowData.name.substring(rowData.name.lastIndexOf("."));
                            let usePreview = false;
                            if (usePreviewFor && usePreviewFor.length > 0) {
                                if (usePreviewFor.indexOf(fileType) > -1) {
                                    usePreview = true
                                }
                            }

                            const usePreviewWhen = props.actionsConfig.openWithFromTable.usePreviewWhen;

                            if (usePreviewWhen && Object.entries(usePreviewWhen).length > 0) {
                                if (rowData[usePreviewWhen.templateKey + "~" + usePreviewWhen.metadataKey] && usePreviewWhen.values.indexOf(rowData[usePreviewWhen.templateKey + "~" + usePreviewWhen.metadataKey]) !== -1) {
                                    usePreview = true
                                }
                            }

                            let hide = false;
                            const hideWhen = props.actionsConfig.openWithFromTable.hideWhen;
                            if (hideWhen && Object.entries(hideWhen).length > 0) {
                                if (rowData[hideWhen.templateKey + "~" + hideWhen.metadataKey] && (rowData[hideWhen.templateKey + "~" + hideWhen.metadataKey] === hideWhen.value)) {
                                    hide = true;
                                }
                            }

                            return (
                                hide ?
                                    <span/> :
                                    usePreview ?
                                        <PreviewWithPropertiesButton
                                            parentClasses={this.props.classes}
                                            boxDocID={rowData.id}
                                            userDetails={this.props.userDetails}
                                            metadataConfig={this.props.metadataConfig}
                                            optionsConfig={this.props.optionsConfig}
                                            workspaceConfig={this.props.workspaceConfig}
                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                            actionsConfig={this.props.actionsConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                        /> :
                                        <ContentOpenWith
                                            fileId={rowData.id}
                                            token={this.props.userDetails.boxAccessToken}
                                        />
                            )
                        },
                    })
                }
            }

            //append a column for PreviewDocumentWithProperties component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.previewDocument.enabled &&
                hasAccess(props.actionsConfig.previewDocument, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <PreviewWithPropertiesButton
                                    parentClasses={this.props.classes}
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                />
                            )
                        },
                    })
            }

            //File Workflow actions
            if (props.workflowConfig && props.workflowConfig.length > 0) {
                // Build list of enabled file workflow actions that the user has access to
                let enabledActions = [];
                for (let i = 0; i < props.workflowConfig.length; i++) {
                    if (props.workflowConfig[i].enabled && hasAccess(props.workflowConfig[i], props.userDetails.boxPortalRole)) {
                        enabledActions.push(
                            props.workflowConfig[i]
                        )
                    }
                }
                //If user has access to any enabled workflow actions then add a single column to display all the available actions
                if (enabledActions.length > 0) {
                    columns.push({
                        title: 'Workflow',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return(
                                <ActionButtonsDocument
                                    documentDetails={rowData}
                                    actions={enabledActions}
                                    folderId={this.props.folderDetails.id}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    unmountSearchResults={props.unmountFolderDocumentsGet}
                                    metadataConfig={props.metadataConfig}
                                    updateFolderDetails={props.updateFolderDetails}
                                    unmountFolderDocumentsGet={props.unmountFolderDocumentsGet}
                                />
                            )
                        },
                    });
                }
            }

            //append a column for Download File component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.downloadFile.enabled) {
                if (hasAccess(props.actionsConfig.downloadFile, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <DownloadFile
                                    boxDocID={rowData.id}
                                    fileName={rowData.name ? rowData.name : rowData.original_name}
                                    userDetails={this.props.userDetails}
                                    downloadFileActionConfig={this.props.actionsConfig.downloadFile}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Download File component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.viewLinkFile.enabled) {
                if (hasAccess(props.actionsConfig.viewLinkFile, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <ViewFileLinkButton
                                    boxDocID={rowData.id}
                                    actionConfig={this.props.actionsConfig.viewLinkFile}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for compareParent button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.compareParent && props.actionsConfig.compareParent.enabled) {
                if (hasAccess(props.actionsConfig.compareParent, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            const actionConfig = props.actionsConfig.compareParent;
                            return (
                                rowData[actionConfig.compareToIdMetadataKey] && rowData[actionConfig.compareToIdMetadataKey] !== "" ?
                                    <CompareParentButton
                                        parentClasses={this.props.classes}
                                        id={rowData.id}
                                        parentFileId={rowData[actionConfig.compareToIdMetadataKey]}
                                        actionConfig={actionConfig}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <span/>
                            )
                        },
                    })
                }
            }

            //append a column for comparePrevious button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.comparePrevious && props.actionsConfig.comparePrevious.enabled) {
                if (hasAccess(props.actionsConfig.comparePrevious, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {

                            const displayWhen = props.actionsConfig.comparePrevious.displayWhen;
                            let display;
                            if (displayWhen) {
                                if (Object.entries(displayWhen).length === 0) {
                                    display = true;
                                } else {
                                    display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                }
                            } else {
                                display = true
                            }

                            return (
                                display && rowData.sequence_id && rowData.sequence_id !== "" && parseInt(rowData.sequence_id) > 1 ?
                                    <ComparePreviousButton
                                        parentClasses={this.props.classes}
                                        id={rowData.id}
                                        actionConfig={this.props.actionsConfig.comparePrevious}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <span/>
                            )
                        },
                    })
                }
            }

            //append a column for compareCustom button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.compareCustom && props.actionsConfig.compareCustom.enabled) {
                if (hasAccess(props.actionsConfig.compareCustom, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {width: 48, maxWidth: 48, padding: 0},
                        headerStyle: {width: 48, maxWidth: 48, padding: 0},
                        disableClick: true,

                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            const displayWhen = props.actionsConfig.compareCustom.displayWhen;
                            let display;
                            if (displayWhen) {
                                if (Object.entries(displayWhen).length === 0) {
                                    //no criteria specified so display
                                    display = true;
                                } else {
                                    display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                }
                            } else {
                                display = true
                            }

                            return (
                                display ?
                                    <CompareCustomButton
                                        parentClasses={this.props.classes}
                                        id={rowData.id}
                                        parentFileId={rowData.parentFileId}
                                        actionConfig={this.props.actionsConfig.compareCustom}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <span/>
                            )
                        },
                    })
                }
            }

            //append a column for Shared Link button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.viewSharedLinkFile && props.actionsConfig.viewSharedLinkFile.enabled) {
                if (hasAccess(props.actionsConfig.viewSharedLinkFile, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                rowData.shared_link && rowData.shared_link !== "" ?
                                    <ViewSharedLinkButton
                                        sharedLink={rowData.shared_link}
                                        actionConfig={this.props.actionsConfig.viewSharedLinkFile}/> :
                                    <span/>)

                        },
                    })
                }
            }

            //append a column for Comments Button component
            //if action is enabled and user role listed in config then add a column action
            if (props.actionsConfig.comments.enabled) {
                if (hasAccess(props.actionsConfig.comments, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            let comments = [];
                            if (window.REACT_APP_DOCUMENT_SOURCE === "elastic" && window.REACT_APP_COMMENT_SOURCE === "elastic") {
                                if (rowData.metadata && rowData.metadata.comments) {
                                    comments = rowData.metadata.comments;
                                }
                            }
                            return (
                                <CommentsButton
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    comments={comments}
                                    unmountSearchResults={props.unmountFolderDocumentsGet}
                                    documentDetails={rowData}
                                />
                            )
                        },
                    });
                }
            }

            if (props.actionsConfig.watson.enabled) {
                if (hasAccess(props.actionsConfig.watson, props.userDetails.boxPortalRole)) {

                    //create a json with watson results so that we can use them
                    //append a column for Watson component
                    columns.push({
                            title: '',
                            field: 'id',
                            filtering: false,
                            sorting: false,
                            width: 48,
                            cellStyle: {
                                width: 48,
                                maxWidth: 48,
                                padding: 0,
                                // marginRight: 40
                            },
                            headerStyle: {
                                width: 48,
                                maxWidth: 48,
                                padding: 0,
                                // marginRight: 40
                            },
                            disableClick: true,
                            editComponent: rowData => {
                                return (<span/>)
                            },
                            render: rowData => {
                                if (window.REACT_APP_ENV === "dev" &&
                                    (window.REACT_APP_APP_TITLE === "Contract Management Portal" || window.REACT_APP_APP_TITLE === "RPS CLM Portal")) {

                                    const displayWhen = props.actionsConfig.watson.displayWhen;
                                    let display;
                                    if (displayWhen) {
                                        if (Object.entries(displayWhen).length === 0) {
                                            display = true;
                                        } else {
                                            display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                        }
                                    } else {
                                        display = true
                                    }

                                    return (
                                        display ?
                                            <MLButtonDemo
                                                boxDocID={rowData.id}
                                                userDetails={this.props.userDetails}
                                                metadataConfig={this.props.metadataConfig}
                                                uploadConfig={this.props.uploadConfig}
                                                actionsConfig={this.props.actionsConfig}
                                                workspaceConfig={this.props.workspaceConfig}
                                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                folderDetails={this.props.folderDetails}
                                                documentDetails={rowData}
                                                updateFolderDetails={props.updateFolderDetails}
                                            /> :
                                            <span/>
                                    )
                                } else {
                                    return (
                                        <MLButton
                                            boxDocID={rowData.id}
                                            userDetails={this.props.userDetails}
                                            metadataConfig={this.props.metadataConfig}
                                            uploadConfig={this.props.uploadConfig}
                                            actionsConfig={this.props.actionsConfig}
                                            workspaceConfig={this.props.workspaceConfig}
                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                            folderDetails={this.props.folderDetails}
                                            documentDetails={rowData}
                                            updateFolderDetails={props.updateFolderDetails}
                                        />
                                    )
                                }
                            },
                        }
                    );
                }
            }

            if (props.actionsConfig.tasks.enabled) {
                if (hasAccess(props.actionsConfig.tasks, props.userDetails.boxPortalRole)) {

                    //append a column for TasksButton component
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            if (window.REACT_APP_APP_TITLE !== "Pearson Education Portal" || rowData["pearson~documentType"] === "Assignment") {
                                return (
                                    <TasksButton
                                        parentClasses={this.props.classes}
                                        boxDocID={rowData.id}
                                        userDetails={this.props.userDetails}
                                        metadataConfig={this.props.metadataConfig}
                                        uploadConfig={this.props.uploadConfig}
                                        actionsConfig={this.props.actionsConfig}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    />

                                )
                            }
                        },
                    });
                }
            }

            if (props.actionsConfig.signRequest && props.actionsConfig.signRequest.enabled) {
                if (hasAccess(props.actionsConfig.signRequest, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            //if (rowData.objectType === "document") {
                            const displayWhen = props.actionsConfig.signRequest && props.actionsConfig.signRequest.displayWhen;
                            let display;
                            if (displayWhen) {
                                if (Object.entries(displayWhen).length === 0) {
                                    display = true;
                                } else {
                                    display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                }
                            } else {
                                display = true
                            }
                            return (
                                display ?
                                    <SignRequestButton
                                        parentClasses={this.props.classes}
                                        boxDocID={rowData.id}
                                        fileName={rowData.name}
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        unmountSearchResults={props.unmountFolderDocumentsGet}
                                        folderDetails={this.props.folderDetails}
                                        documentDetails={rowData}
                                        actionConfig={props.actionsConfig.signRequest}
                                    /> :
                                    <span/>
                            )
                            //}
                        },
                    });
                }
            }

            //append a column for Related Documents component  //TODO DawsonGroup specific
            if (props.actionsConfig.relatedDocuments.enabled) {
                if (hasAccess(props.actionsConfig.relatedDocuments, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <RelatedDocumentsButton
                                    userDetails={this.props.userDetails}
                                    actionsConfig={this.props.actionsConfig}
                                    searchConfig={this.props.selectedWorkspaceConfig.searchConfig.folderDocuments}
                                    metadataConfig={this.props.metadataConfig}
                                    folderDetails={this.props.folderDetails}
                                    documentDetails={rowData}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Move Document to Case
            if (props.actionsConfig.moveDocumentToCase && props.actionsConfig.moveDocumentToCase.enabled) {
                if (hasAccess(props.actionsConfig.moveDocumentToCase, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <MoveDocumentToCaseButton
                                    boxDocID={rowData.id}
                                    rowData={rowData}
                                    folderDetails={this.props.folderDetails}
                                    userDetails={this.props.userDetails}
                                    actionConfig={this.props.actionsConfig.moveDocumentToCase}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Delete
            if (props.actionsConfig.delete.enabled) {
                if (hasAccess(props.actionsConfig.delete, props.userDetails.boxPortalRole)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <DeleteFile
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    actionConfig={props.actionsConfig.delete}
                                />
                            )
                        },
                    })
                }
            }

            //append a column for Audit History
            if (props.actionsConfig.audit && props.actionsConfig.audit.document && props.actionsConfig.audit.document.enabled) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {
                        return (<span/>)
                    },
                    render: rowData => {
                        return (
                            <AuditButton
                                parentClasses={this.props.classes}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                docId={rowData.id}
                                type={"document"}
                            />
                        )
                    },
                })

            }


            if (props.actionsConfig.documentDropDownMenu.enabled) {
                if (hasAccess(props.actionsConfig.documentDropDownMenu, props.userDetails.boxPortalRole)) {

                    //append a column to render the document menu
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            //maxWidth: 48,
                            paddingRight: 15,
                            paddingLeft: 0,
                            marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <DocumentMenu
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    uploadConfig={this.props.uploadConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                />
                            )
                        },
                    });
                }
            }
        }

        //add data for each item in search results

        let data = [];

        props.searchResults.forEach(sr => {
            data.push(sr)
        });

        if (props.selectedWorkspaceConfig.searchConfig.folderDocuments.sortResultsByName) {
            data.sort( dynamicSort("name") );
        }

        console.log ('columns=', columns)

        this.state = {
            searchResults: props.searchResults,
            data: data,
            columns: columns,
            hasEditableColumns: hasEditableColumns,
            enableGrouping : enableGrouping,
            showPreview: false,
            showPreviewDialog: false
        }

        //console.log ('*** columns=', columns);
    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.state.searchResults)!== JSON.stringify(this.props.searchResults)){
            this.props.unmountComponent();
         } else {
            //window.location.pathname.toLowerCase().includes("debug") && console.log ('no change to search Results')
        }
    }

    componentWillUnmount() {

        this.props.remountComponent();

    }

    rowClickNoAction = (event,rowData) => {
        //dummy action to make row go grey when hovered over
    };

    rowClickOpen = (event, rowData) => {
        this.setState({
            showPreviewDialog: true,
            showPreviewDocId: rowData.id
        });
    };

    handleClosePreviewDialog = (metadataUpdated) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('handleClosePreviewDialog metadataUpdated= ', metadataUpdated);

        this.setState({
            showPreviewDialog: false,
            showPreviewDocId: ""
        });
        if (metadataUpdated) {
            if (this.props.unmountFolderDocumentsGet) {
                this.props.unmountFolderDocumentsGet();
            }
        }
    }

    render() {

        const hasEditAccess = hasAccess(this.props.actionsConfig.edit, this.props.userDetails.boxPortalRole);

        const {t} = this.props;
        const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

        let columns = this.state.columns;
        columns.forEach(col => col.title = translate(col.label))

        //editable MaterialTable Documentation:
        //https://material-table.com/#/docs/features/editable
        /*
            To make table editable, you should set editable prop that has onRowAdd, onRowUpdate, onRowDelete functions to manipulate data.
            Every function must return a promise.

            To add a custom action

            https://material-table.com/#/docs/features/actions
         */
        //const classes = this.props.classes;

        let docMenuPosition = this.state.columns.length - 1;

        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;

        if ((!this.props.searchTotalCount || this.props.searchTotalCount === 0) || this.props.nextMarker) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount
        }

        let tableTitle =
            numberWithCommas(totalCountDisp) + " " +
                (totalCountDisp === 1 ?
                    translate(this.props.sign ? " sign document" : this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitleSingular) :
                    translate(this.props.sign ? " sign documents": this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitlePlural));

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderDocuments;

        //Determine icon & iconColour from config
        const groupingIcon = searchConfig.resultsDisplay.table && searchConfig.resultsDisplay.table.groupingIcon ?
            searchConfig.resultsDisplay.table.groupingIcon : {};

        let iconColour = this.props.theme.palette.secondary.main; //default colour

        if (searchConfig.resultsDisplay.table && searchConfig.resultsDisplay.table.groupingIconColour) {
            if (searchConfig.resultsDisplay.table.groupingIconColour.default) {
                iconColour = searchConfig.resultsDisplay.table.groupingIconColour.default
            }
            //check if a custom field is provided
            if (searchConfig.resultsDisplay.table.groupingIconColour.custom && searchConfig.resultsDisplay.table.groupingIconColour.custom.templateKey && searchConfig.resultsDisplay.table.groupingIconColour.custom.metadataKey) {
                const templateKey = searchConfig.resultsDisplay.table.groupingIconColour.custom.templateKey;
                const metadataKey = searchConfig.resultsDisplay.table.groupingIconColour.custom.metadataKey;
                //check if value available on folder
                let val
                if (this.props.folderDetails[templateKey + "~" + metadataKey]) {
                    val = this.props.folderDetails[templateKey + "~" + metadataKey]
                }
                if (val) {
                    let fieldConfig = getFieldConfig(this.props.metadataConfig, searchConfig.resultsDisplay.table.groupingIconColour.custom.templateKey, searchConfig.resultsDisplay.table.groupingIconColour.custom.metadataKey)
                    if (fieldConfig && fieldConfig.options && fieldConfig.options.length > 0 ) {
                        let option = getObjectByKey("value", val, fieldConfig.options);
                        if (option && option.groupingIconColour) {
                            iconColour = option.groupingIconColour
                        }
                    }
                }
            }
        }
        //

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        //Access to Add Doc button now further restricted by roles listed in workspaceConfig.uploadConfig (if available)
        const workspaceUploadRoles =
            this.props.selectedWorkspaceConfig.uploadConfig.roles &&
            Array.isArray(this.props.selectedWorkspaceConfig.uploadConfig.roles) ?
                this.props.selectedWorkspaceConfig.uploadConfig.roles :
                [];

        const showAddDocButton = this.props.actionsConfig.addDocumentWithinFolder.enabled &&
        workspaceUploadRoles.length > 0 ?
            hasAccess(this.props.selectedWorkspaceConfig.uploadConfig, this.props.userDetails.boxPortalRole) :
            hasAccess(this.props.actionsConfig.addDocumentWithinFolder, this.props.userDetails.boxPortalRole)

        return (

            <React.Fragment>
                <ThemeProvider theme={tableTheme}>

                    {/*<Grid container style={{height: "100%"}}>*/}

                    {/*    <Grid item*/}
                    {/*          xs={12}*/}
                    {/*          style={{*/}
                    {/*              height: "100%",*/}
                    {/*              overflowY: "auto",*/}
                    {/*          }}*/}
                    {/*          // id={"scrollableDiv1"}*/}
                    {/*    >*/}

                            <InfiniteScroll
                                dataLength={this.props.searchResults.length}
                                next={this.props.getNextResults}
                                hasMore={hasMore}
                                loader={
                                    <InfiniteScrollLoader
                                        searchReturnedCount={this.props.searchResults.length}
                                        searchTotalCount={this.props.searchTotalCount}
                                        searchLimit={this.props.searchLimit}
                                        isFetching={this.props.isFetching}
                                        nextMarker={this.props.nextMarker}
                                        getNextResults={this.props.getNextResults}
                                    />
                                }
                                endMessage={
                                    <InfiniteScrollEndMessage
                                        searchReturnedCount={this.props.searchResults.length}
                                        searchTotalCount={this.props.searchTotalCount}
                                        nextMarker={this.props.nextMarker}
                                    />
                                }
                                // scrollableTarget="scrollableDiv1"
                            >

                                <MaterialTable
                                    title={
                                        <React.Fragment>
                                            <div style={{display: "inline"}}>

                                                <Typography  variant={"h6"} color={"textPrimary"}>
                                                    {tableTitle}
                                                    <DisplayOptionButtons searchConfig = {searchConfig}
                                                                          showResultsAsGrid={this.props.showResultsAsGrid}
                                                                          showResultsAsTable={this.props.showResultsAsTable}
                                                                          currentDisplay={"table"}
                                                    />
                                                    {
                                                        hasMore &&
                                                        <Tooltip title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                            <span>
                                                                <Button onClick={this.props.getNextResults}
                                                                        key={"ibNext"}
                                                                        disabled={this.props.isFetching}
                                                                        style={{fontWeight: 'bold', minWidth: '0px',paddingLeft: '5px',paddingRight: '5px',paddingTop: '5px', fontSize: '1rem'}}>
                                                                    {this.props.isFetching ?
                                                                        <CircularProgress color="secondary" size={15}/> :
                                                                        "..."}
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        this.props.actionsConfig.searchCriteriaTooltip.enabled &&
                                                        <SearchCriteriaTooltip
                                                            parentClasses={this.props.classes}
                                                            metadataConfig={this.props.metadataConfig}
                                                            searchCriteriaSimple={this.props.searchCriteriaSimple}
                                                            searchCriteria={this.props.searchCriteria}
                                                            iconClass={""}
                                                        />
                                                    }
                                                    {
                                                        this.props.actionsConfig.refreshFolderDocuments.enabled &&
                                                        <FolderDocumentsRefresh
                                                            unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                        />
                                                    }

                                                    {
                                                        !this.props.sign && showAddDocButton &&
                                                        <div style={{display: "inline"}}>
                                                            <AddDocButton
                                                                parentClasses={this.props.classes}
                                                                userDetails={this.props.userDetails}
                                                                uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                                                metadataConfig={this.props.metadataConfig}
                                                                optionsConfig={this.props.optionsConfig}
                                                                folderDetails={this.props.folderDetails}
                                                                iconClass={""}
                                                                unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                actionConfig={this.props.actionsConfig.addDocumentWithinFolder}
                                                                workspaceConfig={this.props.workspaceConfig}
                                                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                                templatesConfig={this.props.templatesConfig}
                                                                actionsConfig={this.props.actionsConfig}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.props.actionsConfig.filterFolderDocuments.enabled &&
                                                        hasAccess(this.props.actionsConfig.filterFolderDocuments, this.props.userDetails.boxPortalRole) &&
                                                        <FilterFolderDocuments
                                                            parentClasses={this.props.classes}
                                                            filterFolderDocumentsActionConfig={this.props.actionsConfig.filterFolderDocuments}
                                                            toggleFilterFolderDocuments={this.props.toggleFilterFolderDocuments}
                                                            filterFolderDocuments={this.props.filterFolderDocuments}
                                                            isFiltering={this.props.isFilteringFolderDocuments}
                                                        />
                                                    }
                                                    {
                                                        this.props.actionsConfig.filterFolderDocumentsByMetadata.enabled &&
                                                            !this.props.disableFilterFolderDocumentsByMetadata &&
                                                        hasAccess(this.props.actionsConfig.filterFolderDocumentsByMetadata, this.props.userDetails.boxPortalRole) &&
                                                        <FilterFolderDocumentsByMetadata
                                                            parentClasses={this.props.classes}
                                                            filterFolderDocumentsByMetadataActionConfig={this.props.actionsConfig.filterFolderDocumentsByMetadata}
                                                            toggleFilterFolderDocumentsByMetadata={this.props.toggleFilterFolderDocumentsByMetadata}
                                                            filterFolderDocumentsByMetadata={this.props.filterFolderDocumentsByMetadata}
                                                            isFiltering={this.props.isFilteringFolderDocumentsByMetadata}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                        />
                                                    }
                                                    {
                                                        this.props.searchResults.length > 0 &&
                                                        this.props.actionsConfig.downloadResults.enabled &&
                                                        hasAccess(this.props.actionsConfig.downloadResults, this.props.userDetails.boxPortalRole) &&
                                                        <div style={{display: "inline"}}>
                                                            <DownloadResults
                                                                userDetails={this.props.userDetails}
                                                                downloadResultsActionConfig={this.props.actionsConfig.downloadResults}
                                                                searchResults={this.props.searchResults}
                                                                fileName = {this.props.folderDetails.name + ".zip"}
                                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            />
                                                        </div>
                                                    }
                                                </Typography>
                                            </div>
                                        </React.Fragment>
                                    }
                                    columns={columns}
                                    // remove shadow from paper container
                                    components={{
                                        Container: props => <Paper {...props} elevation={0}/>,
                                        Groupbar: props => <Paper {...props} style={{display: 'none'}}/>,
                                        GroupRow: (props) => <CustomGroupRow {...props} iconColour={iconColour} groupingIcon={groupingIcon} optionsConfig={this.props.optionsConfig}/>
                                    }}
                                    data={this.state.data}
                                    icons={{
                                        DetailPanel: () => <i className={'material-icons'} style={{color: iconColour}}>chevron_right</i>
                                    }}
                                    options={{
                                        grouping: this.state.enableGrouping,
                                        groupRowSeparator: " ", //to prevent colon from appearing between label and value on grouping
                                        actionsColumnIndex: docMenuPosition,
                                        paging: false,
                                        actionsCellStyle: {
                                            color: "rgba(0, 0, 0, 0.54)",
                                            width: 48,
                                            maxWidth: 48,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        },
                                        headerStyle: {
                                            width: 48,
                                            maxWidth: 48,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                            //TODO TBC
                                        }
                                    }}
                                    onRowClick={
                                        this.props.actionsConfig.previewDocument.openOnRowClick && hasAccess(this.props.actionsConfig.previewDocument, this.props.userDetails.boxPortalRole)?
                                            this.rowClickOpen :
                                            this.rowClickNoAction
                                    }
                                    editable={
                                        this.props.sign || !this.props.actionsConfig.edit.enabled ?
                                            {} :
                                            !hasEditAccess ?
                                                {} :
                                                !this.state.hasEditableColumns ?
                                                    {} :
                                                    {
                                                        onRowUpdate: (newData, oldData) =>

                                                            //TODO if field value not in list of options then set it to blank

                                                            new Promise (async (resolve, reject)  => {

                                                                 window.location.pathname.toLowerCase().includes("debug") && console.log ('newData = ', newData);
                                                                // if (this.state.validationError) {
                                                                //     alert (this.state.validationErrorDetails)
                                                                // }

                                                                let metadataArray = [];
                                                                Object.entries(newData).forEach(entry => {
                                                                    const templateKey = entry[0].split("~")[0];
                                                                    const metadataKey = entry[0].split("~")[1]
                                                                    if (entry[0].indexOf("~") > -1) {
                                                                        let val = entry[1];
                                                                        if (val && typeof val === 'object') {
                                                                            let dateVal = new Date(val)
                                                                            dateVal.setUTCHours(0,0,0,0);
                                                                            val = dateVal;
                                                                        }
                                                                        //if fieldConfig specifies number but we've got text here, convert it (to address issue with material-table saving text for numeric dropdown fields)
                                                                        if (isNumeric(val)){
                                                                            let fieldConfig = getFieldConfig(this.props.metadataConfig, templateKey, metadataKey)
                                                                            if (fieldConfig && fieldConfig.type==="number") {
                                                                                val = parseInt(val)
                                                                            }
                                                                        }

                                                                        metadataArray.push({
                                                                            templateKey: templateKey,
                                                                            metadataKey: metadataKey,
                                                                            value: val
                                                                        });
                                                                    }
                                                                });

                                                                let body = {
                                                                    fileName: newData.name,
                                                                    metadata: metadataArray,
                                                                };


                                                                const pathVar = "/" + newData.id;
                                                                const params = "?folderId=" + this.props.selectedWorkspaceConfig.uploadConfig.folderId + "&userId=" + this.props.userDetails.boxId + '&audit=' + this.props.actionsConfig.edit.audit;
                                                                const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;
                                                                const request = {
                                                                    method: 'PATCH',
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                                                                    },
                                                                    body: JSON.stringify(body)
                                                                };

                                                                window.location.pathname.toLowerCase().includes("debug") && console.log ('edit metadata BODY=', body, 'request=', request);
                                                                this.setState({isFetching: true});

                                                                await this.props.triggerRefreshAuthToken();
                                                                fetch(url, request)
                                                                    .then(response => {
                                                                        if (response.ok) {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log('response=', response);
                                                                            this.props.enqueueSnackbar("Metadata on " + body.fileName + " successfully updated.", {variant: 'success'});
                                                                            this.setState({isFetching: false});
                                                                            const data = [...this.state.data];
                                                                            data[data.indexOf(oldData)] = newData;
                                                                            this.setState({...this.state, data: data});
                                                                            resolve();

                                                                        } else {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log('response=', response);
                                                                            Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                                                                                .then(message => {
                                                                                    this.props.enqueueSnackbar(message, {variant: 'error'});
                                                                                    window.location.pathname.toLowerCase().includes("debug") && console.log("updateMetadata error.  url:", url, "request: ", request);
                                                                                    this.setState({isFetching: false});
                                                                                    reject();
                                                                                })
                                                                        }
                                                                    })
                                                                // .catch(e => {
                                                                //     this.props.enqueueSnackbar("Error updating data.  Exception: " + e);
                                                                //     window.location.pathname.toLowerCase().includes("debug") && console.log("updateMetadata Exception:", e, " url:", url, "request: ", request, );
                                                                //     this.setState({isFetching: false})});
                                                                //     reject();
                                                            }),
                                            }}
                                    detailPanel={
                                        [
                                            {
                                                tooltip: 'Preview',
                                                disabled: false,
                                                width: 48,
                                                style: {color: iconColour},
                                                cellStyle: {
                                                    width: '48px',
                                                    maxWidth: '50px',
                                                    padding: 0,
                                                    // marginRight: 40
                                                },
                                                headerStyle: {
                                                    width: '48px',
                                                    maxWidth: '50px',
                                                    padding: 0,
                                                    // marginRight: 40
                                                },
                                                render: rowData => {
                                                    return (
                                                        <div style={{height: "500px"}}>
                                                            <Preview
                                                                boxDocID={rowData.id}
                                                                userDetails={this.props.userDetails}
                                                                showHeader={false}
                                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                actionsConfig={this.props.actionsConfig}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            }
                                        ]
                                 }
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: '',
                                            editTooltip: this.props.actionsConfig.edit.label,
                                            editRow: {
                                                cancelTooltip: 'Cancel Edit'
                                            }
                                        },
                                        toolbar: {
                                            searchTooltip: 'Filter results',
                                            searchPlaceholder: 'Filter results'
                                        }
                                    }}
                                />
                            </InfiniteScroll>

                    {/*</Grid>*/}

                </ThemeProvider>

                {
                    (this.state.showPreviewDialog) &&
                    <PreviewWithPropertiesDialog
                        parentClasses={this.props.classes}
                        boxDocID={this.state.showPreviewDocId}
                        userDetails={this.props.userDetails}
                        handleCloseDialog={this.handleClosePreviewDialog}
                        calledFromDocumentMenu={true}
                        metadataConfig={this.props.metadataConfig}
                        optionsConfig={this.props.optionsConfig}
                        workspaceConfig={this.props.workspaceConfig}
                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                        actionsConfig={this.props.actionsConfig}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        showMetadataOnOpen={this.props.showMetadataOnOpen}

                    />
                }

            </React.Fragment>
        );
    }
}

SearchResultsDocumentsFolderDocsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    toggleFilterFolderDocuments: PropTypes.func,
    filterFolderDocuments: PropTypes.bool,
    isFilteringFolderDocuments: PropTypes.bool,
    toggleFilterFolderDocumentsByMetadata: PropTypes.func,
    filterFolderDocumentsByMetadata: PropTypes.bool,
    disableFilterFolderDocumentsByMetadata: PropTypes.bool,
    isFilteringFolderDocumentsByMetadata: PropTypes.bool,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderDocuments: PropTypes.bool,
    nextMarker: PropTypes.string,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired,
    showResultsAsGrid: PropTypes.func.isRequired,
    sign: PropTypes.bool //temp for demo
};

export default withTranslation()(withSnackbar(withStyles(styles, { withTheme: true })(SearchResultsDocumentsFolderDocsTable)));


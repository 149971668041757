import React from 'react';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import SearchResultsDocumentsTableFolderDocs from "./SearchResultsDocumentsFolderDocsTable";
import FolderDocumentsGet from "./FolderDocumentsGet";
import {withStyles} from "@material-ui/core/styles";
import SearchResultsDocumentsFolderDocsGrid from "./SearchResultsDocumentsFolderDocsGrid";
import Box from '@material-ui/core/Box';
//import {asyncForEach, getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        paddingTop: '0px',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        border: 'none'
    },
    folderDocuments: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    documentsInTab: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    folderDocSearching: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(0),
        color: '#808080',
        fontSize: '0.75rem',
        lineHeight: '24px',
    }
});

class FolderDocuments extends React.Component {

    constructor(props) {

        super(props);

        let disableFilterFolderDocumentsByMetadata = false;
        const disableWhen = props.actionsConfig.filterFolderDocumentsByMetadata.disableWhen;
        if (disableWhen && Object.entries(disableWhen).length > 0){
            if(props.folderDetails[disableWhen.templateKey + "~" + disableWhen.metadataKey] && (disableWhen.values.indexOf(props.folderDetails[disableWhen.templateKey + "~" + disableWhen.metadataKey]) >= 0)) {
                disableFilterFolderDocumentsByMetadata = true;
            }
        }

        // const documentResultsDisplay = props.selectedSearchConfig.folderDocuments.resultsDisplay;  //table, list or grid
        //
        // //if default is table and results count <  defaultToGridWhenCountLessThan then default display to Grid rather than table
        // let showDocumentResultsAsTable =  !documentResultsDisplay || documentResultsDisplay.default === "table"
        // let showDocumentResultsAsGrid = documentResultsDisplay && documentResultsDisplay.default && documentResultsDisplay.default === "grid";
        //

        //use different limit if getting all documents in folder from box
        const searchLimit = window.REACT_APP_DOCUMENT_SOURCE === "box" && this.props.selectedSearchConfig.folderDocuments.box.queryParams.length === 0 && window.REACT_APP_CONTENT_API_BOX_FOLDER_ITEMS_LIMIT ?
            parseInt(window.REACT_APP_CONTENT_API_BOX_FOLDER_ITEMS_LIMIT) :
            parseInt(window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT)

        this.state = {
            rows: [],
            mountFolderDocumentsGet: true,
            mountSearchResultsDocumentsTable: false,
            mountSearchResultsDocumentsGrid: false,
            searchLimit: searchLimit,
            searchOffset: 0,
            searchTotalCount: 0,
            searchResults: [],
            newSearch: true,
            isFetching: true,
            filterFolderDocuments: props.actionsConfig.filterFolderDocuments.enabled ? props.actionsConfig.filterFolderDocuments.defaultFilterOn : false,
            filterFolderDocumentsByMetadata: (props.actionsConfig.filterFolderDocumentsByMetadata.enabled && !disableFilterFolderDocumentsByMetadata) ? props.actionsConfig.filterFolderDocumentsByMetadata.defaultFilterOn : false,
            disableFilterFolderDocumentsByMetadata: disableFilterFolderDocumentsByMetadata
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log('FolderDocuments props = ', props);

        this.remountFolderDocumentsGet = this.remountFolderDocumentsGet.bind(this);
        this.remountSearchResultsDocumentTable = this.remountSearchResultsDocumentTable.bind(this);
        this.remountSearchResultsDocumentGrid = this.remountSearchResultsDocumentGrid.bind(this);
        this.getNextResults = this.getNextResults.bind(this);
        this.getAllResults = this.getAllResults.bind(this);
        this.updateSearchResults = this.updateSearchResults.bind(this);

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('FolderDocuments props=', props);
    }

    componentDidUpdate(prevProps) {

        //if resultsDisplay property on parent folder has changed then change the display of documents
        if (prevProps.folderDetails &&  this.props.folderDetails) {
            if (JSON.stringify(prevProps.folderDetails) !== JSON.stringify(this.props.folderDetails)) {
                if (this.props.folderDetails && this.props.folderDetails.hasOwnProperty("resultsDisplay")) {
                    if (this.props.folderDetails.resultsDisplay !== prevProps.folderDetails.resultsDisplay) {
                        if (this.props.folderDetails.resultsDisplay === "grid") {
                            this.showDocumentResultsAsGrid()
                        } else if (this.props.folderDetails.resultsDisplay === "table"){
                            this.showDocumentResultsAsTable()
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    toggleFilterFolderDocuments = (filterFolderDocuments) => {
        window.location.pathname.toLowerCase().includes("debug") && console.log ('toggleFilterFolderDocuments filterFolderDocuments = ', filterFolderDocuments);
        this.setState({
            filterFolderDocuments: filterFolderDocuments,
            newSearch: true,
            searchOffset: 0,
            isFilteringFolderDocuments: true
        });

        this.unmountFolderDocumentsGet();
    }

    toggleFilterFolderDocumentsByMetadata = (filterFolderDocumentsByMetadata) => {
        window.location.pathname.toLowerCase().includes("debug") && console.log ('toggleFilterFolderDocuments filterFolderDocumentsByMetadata = ', filterFolderDocumentsByMetadata);
        this.setState({
            filterFolderDocumentsByMetadata: filterFolderDocumentsByMetadata,
            newSearch: true,
            searchOffset: 0,
            isFilteringFolderDocumentsByMetadata: true,
        });

        this.unmountFolderDocumentsGet();
    }

    unmountSearchResultsDocumentTable = () => {
        this.setState({
            mountSearchResultsDocumentsTable : false
        });
    };

    remountSearchResultsDocumentTable = () => {
        this.setState({
            mountSearchResultsDocumentsTable : true});
    };

    showDocumentResultsAsTable = () => {
        this.setState({
            showDocumentResultsAsGrid: false,
            showDocumentResultsAsTable: true,
            mountSearchResultsDocumentsTable: true
        });
    };

    showDocumentResultsAsGrid = () => {
        this.setState({
            showDocumentResultsAsGrid: true,
            showDocumentResultsAsTable: false,
            mountSearchResultsDocumentsGrid: true
        });
    };

    unmountSearchResultsDocumentGrid = () => {
        this.setState({
            mountSearchResultsDocumentsGrid : false
        });
    };

    remountSearchResultsDocumentGrid = () => {
        this.setState({
            mountSearchResultsDocumentsGrid : true});
    };

    unmountFolderDocumentsGet = () => {
        this.setState({
            mountFolderDocumentsGet : false,
            newSearch: true,
            searchOffset: 0
        });
    };

    remountFolderDocumentsGet = () => {
        this.setState({
            mountFolderDocumentsGet : true
        });
    };

    getNextResults = () => {

        this.setState({
            mountFolderDocumentsGet: false,
            newSearch: false,
            isFetching: true,
            searchOffset: this.state.searchOffset + this.state.searchLimit
        });
    };

    getAllResults = () => {
        //TODO
    };


    updateSearchResults(searchResults, totalCount, newSearch, nextMarker, searchOffset) {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('updateSearchResults', " searchResults= ", searchResults, " totalCount= ",
            totalCount, " newSearch=" , newSearch, "nextMarker=", nextMarker, "searchOffset=", searchOffset
        );

        //case searching uses page, content searching uses offset
       // let newSearchResults = (!this.state.searchOffset || this.state.searchOffset === 0)  ? searchResults : this.state.searchResults.concat(searchResults);
        let newSearchResults = newSearch ? searchResults : this.state.searchResults.concat(searchResults);

        const documentResultsDisplay = this.props.selectedWorkspaceConfig.searchConfig.folderDocuments.resultsDisplay;  //table, list or grid

        //if default is table and results count <  defaultToGridWhenCountLessThan then default display to Grid rather than table
        let showDocumentResultsAsTable =  !documentResultsDisplay || documentResultsDisplay.default === "table"
        let showDocumentResultsAsGrid = documentResultsDisplay && documentResultsDisplay.default && documentResultsDisplay.default === "grid";

        if (newSearch) {

            //check if folder has display option specified in metadata, if so then this takes preference
            const folderDetails = this.props.folderDetails;
            if (folderDetails && folderDetails.hasOwnProperty('resultsDisplay') && ["grid","table"].indexOf(folderDetails.resultsDisplay)>=0) {
                if (folderDetails.resultsDisplay === "grid") {
                    showDocumentResultsAsGrid = true;
                    showDocumentResultsAsTable = false;
                } else if (folderDetails.resultsDisplay === "table") {
                    showDocumentResultsAsGrid = false;
                    showDocumentResultsAsTable = true;
                }
            } else {
                const defaultToGridWhenCountLessThan = documentResultsDisplay && documentResultsDisplay.defaultToGridWhenCountLessThan;
                if (showDocumentResultsAsTable && defaultToGridWhenCountLessThan && defaultToGridWhenCountLessThan > 0 && searchResults.length < defaultToGridWhenCountLessThan) {
                    showDocumentResultsAsGrid = true;
                    showDocumentResultsAsTable = false;
                }
            }
        }

        this.setState({
            searchResults: newSearchResults,
            searchTotalCount: totalCount ? totalCount : 0,
            searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT),  //TODO need to reset searchLimit
            isFetching: false,
            mountSearchResultsDocumentsTable: newSearch ,   //trigger remount of results table
            mountSearchResultsDocumentsGrid: newSearch ,   //trigger remount of results table
            newSearch: false,
            isFilteringFolderDocuments: false,
            isFilteringFolderDocumentsByMetadata: false,
            nextMarker: nextMarker,
            searchOffset: searchOffset,
            showDocumentResultsAsTable: showDocumentResultsAsTable,
            showDocumentResultsAsGrid: showDocumentResultsAsGrid
        });

        //if display as grid enabled/selected(?), get thumbnail for each file from Box
        // /api/box/document/1124795448637/thumbnail

        // this.getThumbnailsAsync(newSearchResults)

    }

    // async getThumbnail(file) {
    //
    //     const debug=window.location.pathname.toLowerCase().includes("debug");
    //     debug && console.log('getThumbnail for file id: ', file.id);
    //
    //     //let fileId = '1148939512818'
    //     let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/' + file.id + '/thumbnail';
    //     const request = {
    //         method: 'GET',
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer " + this.props.userDetails.accessToken
    //         }
    //     };
    //     // await this.props.triggerRefreshAuthToken();
    //     fetch(url, request)
    //         .then(response => {
    //             console.log('thumbnail response = ', response)
    //
    //             if (response.ok) {
    //                 //return (response.json())
    //                 return (response.blob())
    //             } else {
    //                 debug && console.log('response not ok - getThumbnail RESPONSE: ', response);
    //                 Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
    //                     .then(message => {
    //                         this.props.enqueueSnackbar(message, {variant: 'error'});
    //                     })
    //                 debug && console.log("thumbnail error. url:", url, "request: ", request, "response:", response);
    //                 return null
    //             }
    //         }) .then(resultA => {
    //             console.log('get thumbnail response.blob: ', resultA);
    //
    //             const thumbnail = URL.createObjectURL(resultA);
    //             //this.setState({thumbnail:thumbnail})
    //             console.log ('getThumbnail, return thumbnail = ', thumbnail)
    //             return thumbnail
    //     })
    // }
    //
    // getThumbnailsAsync = (searchResults) => {
    //
    //     const debug = window.location.pathname.toLowerCase().includes("debug");
    //
    //     //Get thumbnails asynchronously
    //     //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
    //     const start = async () => {
    //
    //         debug && console.log ('getThumbnailsAsync, searchResults = ', searchResults)
    //
    //         console.log ('getThumbnailsAsync start')
    //
    //         await asyncForEach(
    //             searchResults,
    //             async (file) => {
    //                 debug && console.log ('getThumbnailAsync, file = ', file.id)
    //                 //TODO only process latest search results, if previously tried to get thumbnail and failed
    //                 if (!file.thumbnail && !file.thumbnailUnavailable) {
    //                     await this.getThumbnail(file)
    //                         .then(thumbnailResponse => {
    //                             console.log ('returned thumbnail = ', thumbnailResponse)
    //                             if (thumbnailResponse) {
    //                                 file.thumbnail = thumbnailResponse
    //                                 debug && console.log('Thumbnail for ', file, ": ", thumbnailResponse)
    //                                 //todo, now update state?...change - get thumbnail in the card item, then set it back up in the search results afterwards?...or no need?
    //                             } else {
    //                                 file.thumbnailUnavailable = true; //so that we don't retry
    //                             }
    //                         })
    //                 }
    //             });
    //
    //         this.props.enqueueSnackbar("Thumnbnail generation complete", {variant: 'success'});
    //     };
    //
    //     //start upload
    //     start();
    //
    // };


    render() {

        //window.location.pathname.toLowerCase().includes("debug") && console.log('*** FolderDocuments props = ', this.props)

        const { classes } = this.props;

        return (

            <React.Fragment>
                {
                    this.state.mountFolderDocumentsGet &&

                    <FolderDocumentsGet
                        parentClasses={this.props.classes}
                        //boxFolderID={this.props.folderDetails.box_id ? this.props.folderDetails.box_id : this.props.selectedSearchConfig.documentSearch.folderId}
                        userDetails={this.props.userDetails}
                        selectedSearchConfig={this.props.selectedWorkspaceConfig.searchConfig}
                        metadataConfig={this.props.metadataConfig}
                        actionsConfig={this.props.actionsConfig}
                        workspaceConfig={this.props.workspaceConfig}
                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                        folderDetails={this.props.folderDetails}
                        unmountComponent={this.unmountFolderDocumentsGet}
                        remountComponent={this.remountFolderDocumentsGet}
                        searchLimit={this.state.searchLimit}
                        searchOffset={this.state.searchOffset}
                        newSearch={this.state.newSearch}
                        //resetSearchOffset={this.resetSearchOffset}
                        updateSearchResults={this.updateSearchResults}
                        filterFolderDocuments={this.state.filterFolderDocuments}
                        filterFolderDocumentsByMetadata={this.state.filterFolderDocumentsByMetadata}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        nextMarker={this.state.nextMarker}
                        sign={this.props.sign}
                    />
                }

                <div style={{width: "100%"}}>

                    <Grid container
                          spacing={0}
                          style={{overflowY:'auto'}}
                    >
                        <Grid
                            item xs={12}
                            className={this.props.displayInTab ? classes.documentsInTab : classes.folderDocuments}
                        >

                            <Box className={this.props.displayInTab ? classes.documentsInTab : classes.paper}>
                                {
                                    (this.state.isFetching && this.state.newSearch) &&
                                    <div className={classes.folderDocSearching}>Retrieving files...</div>
                                }

                                {
                                    (this.props.folderDetails.resultsDisplay === "table" || this.state.showDocumentResultsAsTable) && this.state.mountSearchResultsDocumentsTable &&
                                    <SearchResultsDocumentsTableFolderDocs
                                        parentClasses={this.props.classes}
                                        searchResults={this.state.searchResults}
                                        searchTotalCount={this.state.searchTotalCount}
                                        searchLimit={this.state.searchLimit}
                                        getNextResults={this.getNextResults}
                                        getAllResults={this.getAllResults}
                                        userDetails={this.props.userDetails}
                                        actionsConfig={this.props.actionsConfig}
                                        metadataConfig={this.props.metadataConfig}
                                        optionsConfig={this.props.optionsConfig}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                        folderDetails={this.props.folderDetails}
                                        isFetching={this.state.isFetching}
                                        remountComponent={this.remountSearchResultsDocumentTable}
                                        unmountComponent={this.unmountSearchResultsDocumentTable}
                                        searchCriteria={this.props.searchCriteria}
                                        searchCriteriaSimple={this.props.searchCriteriaSimple}
                                        unmountFolderDocumentsGet={this.unmountFolderDocumentsGet}
                                        toggleFilterFolderDocuments={this.toggleFilterFolderDocuments}
                                        filterFolderDocuments={this.state.filterFolderDocuments}
                                        isFilteringFolderDocuments={this.state.isFilteringFolderDocuments}
                                        toggleFilterFolderDocumentsByMetadata={this.toggleFilterFolderDocumentsByMetadata}
                                        filterFolderDocumentsByMetadata={this.state.filterFolderDocumentsByMetadata}
                                        disableFilterFolderDocumentsByMetadata={this.state.disableFilterFolderDocumentsByMetadata}
                                        isFilteringFolderDocumentsByMetadata={this.state.isFilteringFolderDocumentsByMetadata}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        folderDocuments={true}
                                        nextMarker={this.state.nextMarker}
                                        templatesConfig={this.props.templatesConfig}
                                        workflowConfig={this.props.workflowConfig}
                                        updateFolderDetails={this.props.updateFolderDetails}
                                        showResultsAsTable={this.showDocumentResultsAsTable}
                                        showResultsAsGrid={this.showDocumentResultsAsGrid}
                                        sign={this.props.sign}
                                    />
                                }

                                {
                                    (this.props.folderDetails.resultsDisplay === "grid" || this.state.showDocumentResultsAsGrid) && this.state.mountSearchResultsDocumentsGrid &&
                                    <SearchResultsDocumentsFolderDocsGrid
                                        parentClasses={this.props.classes}
                                        searchResults={this.state.searchResults}
                                        searchTotalCount={this.state.searchTotalCount}
                                        searchLimit={this.state.searchLimit}
                                        getNextResults={this.getNextResults}
                                        getAllResults={this.getAllResults}
                                        userDetails={this.props.userDetails}
                                        actionsConfig={this.props.actionsConfig}
                                        metadataConfig={this.props.metadataConfig}
                                        optionsConfig={this.props.optionsConfig}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                        folderDetails={this.props.folderDetails}
                                        isFetching={this.state.isFetching}
                                        remountComponent={this.remountSearchResultsDocumentGrid}
                                        unmountComponent={this.unmountSearchResultsDocumentGrid}
                                        searchCriteria={this.props.searchCriteria}
                                        searchCriteriaSimple={this.props.searchCriteriaSimple}
                                        unmountFolderDocumentsGet={this.unmountFolderDocumentsGet}
                                        toggleFilterFolderDocuments={this.toggleFilterFolderDocuments}
                                        filterFolderDocuments={this.state.filterFolderDocuments}
                                        isFilteringFolderDocuments={this.state.isFilteringFolderDocuments}
                                        toggleFilterFolderDocumentsByMetadata={this.toggleFilterFolderDocumentsByMetadata}
                                        filterFolderDocumentsByMetadata={this.state.filterFolderDocumentsByMetadata}
                                        disableFilterFolderDocumentsByMetadata={this.state.disableFilterFolderDocumentsByMetadata}
                                        isFilteringFolderDocumentsByMetadata={this.state.isFilteringFolderDocumentsByMetadata}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        folderDocuments={true}
                                        nextMarker={this.state.nextMarker}
                                        templatesConfig={this.props.templatesConfig}
                                        workflowConfig={this.props.workflowConfig}
                                        updateFolderDetails={this.props.updateFolderDetails}
                                        showResultsAsTable={this.showDocumentResultsAsTable}
                                        showResultsAsGrid={this.showDocumentResultsAsGrid}
                                        //thumbnail={this.state.thumbnail}

                                    />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

FolderDocuments.propTypes = {
    classes: PropTypes.object.isRequired,
    //boxFolderID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    displayInTab: PropTypes.bool,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    sign: PropTypes.bool //temp for demo
};

export default withSnackbar(withStyles(styles, { withTheme: true })(FolderDocuments));
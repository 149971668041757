export function chunkString(str, size) {

    const numChunks = Math.ceil(byteCount(str) / size)
    const chunks = new Array(numChunks)

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size)
    }

    return chunks
}

function byteCount(s) {
    return encodeURI(s).split(/%..|./).length - 1;
}

export function getByteArray(file) {

    return new Promise(function(resolve, reject) {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = function(ev) {
            const array = new Uint8Array(ev.target.result);
            const fileByteArray = [];
            for (let i = 0; i < array.length; i++) {
                fileByteArray.push(array[i]);
            }
            resolve (fileByteArray)
            console.log ('getByteArray - end');

        }
        fileReader.onerror = reject;
    })
}


export function readFileAsyncBase64(file) {
    //https://simon-schraeder.de/posts/filereader-async/

    return new Promise((resolve, reject) => {

        let reader = new FileReader();
        reader.onload = () => {
            let theRes = reader.result;
            let encoded = theRes.replace(/^data:(.*;base64,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            let content = "";
            content = encoded;

            resolve(content);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
}

export function readFileAsyncBinary(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsBinaryString(file);
    })
}

export function readFileAsyncArrayBuffer(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    })
}

export function ArrayBufferToBinary(buffer) {
    var uint8 = new Uint8Array(buffer);
    return uint8.reduce((binary, uint8) => binary + uint8.toString(2), "");
}



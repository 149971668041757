import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl/FormControl";
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import Avatar from '@material-ui/core/Avatar';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import {getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({});

function DisplayComments(props) {

    const source = window.REACT_APP_COMMENT_SOURCE;

    if (props.comments){
        return(
            props.comments.map((comment,index) => {
                const autoFocus = index === (props.comments.length -1)
                const name = source ==="elastic" ? comment.created_by : comment.created_by.name
                return(
                    <React.Fragment>
                        <ListItem
                            //className={classes.button}
                            key = {"li" + comment.id}
                            autoFocus={autoFocus} //auto-scroll to end of list
                            button //needs to be a button for autofocus to work
                            style={{pointerEvents: "none"}} //to prevent hover of button highlighting
                        >
                            <ListItemIcon key = {"liIcon" + comment.id}>
                                <Avatar key = {"avatar" + comment.id} style={{backgroundColor: getColour(name)}}>{getInitials(name)}</Avatar>
                            </ListItemIcon>
                            <ListItemText
                                key = {"liText" + comment.id}
                                primary={<Typography key = {"typog" + comment.id} component="span" variant="body2" color="textPrimary">{name}</Typography>}
                                secondary={
                                    <React.Fragment key = {"fragment1" + comment.id}>
                                        <Tooltip title={new Date(comment.created_at).toString()}
                                                 style={{pointerEvents: "auto"}} //required as hover disabled for ListItem
                                        >
                                            <span><Moment key = {"moment" + comment.id} fromNow>{comment.created_at}</Moment></span>
                                        </Tooltip>
                                        <br/>
                                        {source === "elastic" ? comment.comment : comment.message}
                                    </React.Fragment>}>
                            </ListItemText>
                        </ListItem>
                        <Divider key = {"divider" + comment.id} variant="inset" component="li" />
                    </React.Fragment>
                )
            }))
    } else {
        //in case of error retrieving comments
        return(<span/>)
    }
}

DisplayComments.propTypes = {
    comments: PropTypes.array.isRequired
};

function InputComment(props) {

    return (
        <List>
            <ListItem button={false}>
                <ListItemIcon>
                    <Avatar style={{backgroundColor: getColour(props.userName)}}>{getInitials(props.userName)}</Avatar>
                </ListItemIcon>
                <ListItemText>
                    <FormControl fullWidth >
                        {/*<InputLabel htmlFor="inputComment">Comment</InputLabel>*/}
                        <TextField
                            id="inputComment" name="inputComment"
                            value={props.inputCommentValue}
                            placeholder="Write a comment" type="text" margin="none" variant="outlined" autoComplete=""
                            onChange={props.handleOnChangeComment}
                            autoFocus = {false} // autofocus on last comment instead to ensure it scrolls to latest comment
                            multiline rows={3}
                        />
                    </FormControl>
                </ListItemText>
            </ListItem>
        </List>
    )
}
InputComment.propTypes = {
    inputCommentValue: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    handleOnChangeComment: PropTypes.func.isRequired
};

function getInitials(name) {

    let initials = "";

    if (name) {
        initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }

    return initials
}

function getColour(name) {

    const saturation = 30;
    const lightness = 60;
    let hash = 0;

    if (name) {
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
    }

    let h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';

}

const INITIAL_STATE = {
    open: false,
    inputComment: "",
    comments: []
};

class CommentsPanel extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        this.handleOnChangeComment = this.handleOnChangeComment.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.clearInterval = this.clearInterval.bind(this);

    }

    UNSAFE_componentWillMount() {
        this.getComments();
    }

    componentDidMount(){
        this.setState({ open: true });
        window.location.pathname.toLowerCase().includes("debug") && console.log('boxDocId:', this.props.boxDocID);

        //auto-refresh comments at interval set in config
        this.interval = setInterval(() => this.getComments(), this.props.actionsConfig.comments.viewCommentsPollingIntervalMilliseconds);

    }

    clearInterval = () => {
        clearInterval(this.interval);
        this.interval=0; //clearInterval on its own not  clearing val
    }

    handleCloseDialog = () => {

        this.clearInterval();

        let commentsAdded = false
        if (window.REACT_APP_DOCUMENT_SOURCE ==="elastic" && window.REACT_APP_COMMENT_SOURCE ==="elastic" && this.props.comments && Array.isArray(this.props.comments)) {
            if (this.state.comments.length > this.props.comments.length) {
                //Comments added since this dialog was opened
                commentsAdded = true
            }
        }

        //clear state
        const END_STATE = {
            open: false,
            inputComment: "",
            comments: []
        };
        this.setState(END_STATE);

        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog(commentsAdded)
        }
    };

    getComments = async () => {

        this.setState({isFetching: true});

        await this.props.triggerRefreshAuthToken();

        const source = window.REACT_APP_COMMENT_SOURCE;
        const pathVar = this.props.boxDocID;
        let url;
        let request;

        if(source==="elastic"){
            url = window.REACT_APP_BASE_URL_SCREENING + "/internal/document/" + pathVar + "/comment";
            request = {
                headers: {
                    "case-token": this.props.userDetails.caseAccessToken,
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                }
            };
        } else {
            const params = "?userId=" + this.props.userDetails.boxId;
            url = window.REACT_APP_COMMENT_API_BASE_URL + window.REACT_APP_COMMENT_API_DOCUMENT + "/" + pathVar + params;
            request = {
                headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
            };
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log("getComments:", url);

        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('getComments RESPONSE: ', response);
                if (response.ok) {
                    try {
                        return response.json();
                    } catch(err) {
                        Promise.resolve(getErrorMessageFromResponse(response, 'retrieving comments'))
                            .then(message => {
                                this.props.enqueueSnackbar(message , {variant: 'error'});
                            })
                        return null
                    }
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving comments'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })

                    window.location.pathname.toLowerCase().includes("debug") && console.log("error.  url:", url, "request: ", request);
                    //clear interval so doesn't keep retrying
                    clearInterval(this.interval);
                    this.interval=0; //clearInterval on its own not  clearing val
                    return null
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('result = ', result);
                this.setState({comments: result})
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("getComments Exception:", e, " url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error retrieving comments " + e, {variant: 'error'});
                this.setState({isFetching: false})
                //clear interval so doesn't keep retrying
                clearInterval(this.interval);
                this.interval=0; //clearInterval on its own not  clearing val
            })
    };

    handleOnChangeComment = event => {

        this.setState({inputComment : event.target.value});
    };


    postComment = async () => {

        const pathVar = this.props.boxDocID;
        let url = null;
        let request = null;
        const source = window.REACT_APP_COMMENT_SOURCE;

        await this.props.triggerRefreshAuthToken();

        if(source==="elastic") {
            url = window.REACT_APP_BASE_URL_SCREENING + "/internal/document/" + pathVar + "/comment";

            let body = {
                comment: this.state.inputComment,
            };

            request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "case-token": this.props.userDetails.caseAccessToken,
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

        } else {
            const queryStr = "?userId=" + this.props.userDetails.boxId;
            url = window.REACT_APP_COMMENT_API_BASE_URL + window.REACT_APP_COMMENT_API_DOCUMENT + queryStr;

            let body = {
                item : {
                    "type" : "file",
                    "id" : this.props.boxDocID
                },
                message: this.state.inputComment,
                type: "comment"
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ('body: ', body);

            request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ("postComment url:", url, 'request: ', request);

        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postComment RESPONSE ok: ', response);
                    try {
                        return response.json();
                    } catch(err) {
                        this.props.enqueueSnackbar("Error " + response.status + " posting comment " + response.statusText, {variant: 'error'});
                        return null
                    }
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting comment'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("postComment error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(result => {
                this.setState({inputComment: ""});
                this.getComments(); //refresh comments
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("postComment Exception:", e, "url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error posting comment " + e, {variant: 'error'});
            });

    };

    render() {

        const source = window.REACT_APP_COMMENT_SOURCE;
        return (
            <Grid container style={{height: "100%"}}>
                <Grid item xs={12} style={{height: "calc(100% - 222px)", overflowY: "auto",}}>
                    <List><DisplayComments comments={this.state.comments}/></List>
                </Grid>
                {
                    this.props.enableAddComment &&
                    <Grid item xs={12} style={{height: "200px", overflowY: "auto"}}>
                        <InputComment
                            inputCommentValue={this.state.inputComment}
                            userName={source === "elastic" ? this.props.userDetails.userEmail : this.props.userDetails.userName}
                            handleOnChangeComment={this.handleOnChangeComment}
                        />
                        <DialogActions>
                            {
                                this.props.handleCloseDialog &&
                                <Button onClick={this.handleCloseDialog} color="primary">Close</Button>
                            }
                            {
                                this.props.enableAddComment &&
                                <Button onClick={this.postComment} variant="contained" color="secondary"
                                        disabled={this.state.inputComment === ""}>Post</Button>
                            }
                        </DialogActions>
                    </Grid>
                }
            </Grid>
        );
    }
}

CommentsPanel.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    comments: PropTypes.array, //when document and comment source is elastic
    enableAddComment: PropTypes.bool
};

export default withSnackbar(withStyles(styles)(CommentsPanel));
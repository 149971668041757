import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import FormControl from "@material-ui/core/FormControl/FormControl";
import RenderMetadataField from "../../common/RenderMetadataField";


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// const cols = [
//     { id: 'name', numeric: false, disablePadding: false, label: 'Filename' },
//     { id: 'documentType', numeric: false, disablePadding: false, label: 'Document Type' }
// ];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {

        const { onSelectAllClick, numSelected, rowCount } = this.props;

        let columns = [{ id: 'name', numeric: false, disablePadding: false, label: 'Filename' }];

        //Add a column for each document metadata field in config
        this.props.fields.forEach((fieldConfig) => {
            columns.push({
                id: fieldConfig.metadataKey,
                numeric: fieldConfig.type === "number",
                disablePadding: false,
                label: fieldConfig.label
            })});

        return (
            <TableHead>
                <TableRow>
                    <TableCell key={"colCheckbox"}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {columns.map(
                        col => (
                            <TableCell
                                key={"col" + col.label}
                                align={col.numeric ? 'right' : 'left'}
                                padding={col.disablePadding ? 'none' : 'default'}
                                //sortDirection={orderBy === col.rowId ? order : false}
                            >
                                {col.label}
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    fields: PropTypes.array.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props;
    const tableHeaderStr = "Document Metadata";

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        // variant="h6"
                        id="tableTitle">
                        <b>{tableHeaderStr}</b>
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title={"Action menu"}>
                        <IconButton aria-label="">
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    table: {
        //minWidth: 1020,

    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: theme.spacing(2)
    }
});

class MetadataTableForUploads extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            data: props.files,
            //page: 0,
            //rowsPerPage: 5,
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.rowId) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    // handleChangePage = (event, page) => {
    //     this.setState({ page });
    // };
    //
    // handleChangeRowsPerPage = event => {
    //     this.setState({ rowsPerPage: event.target.value });
    // };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;

        // NB - need to ensure search results updated each time
        const data = this.props.files;

        const { order, orderBy} = this.state;
        //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.rowId);
                                    //generate object with all values in the row
                                    let formValues = {};
                                    Object.entries(this.props.genericMetadata).forEach(entry => {
                                        window.location.pathname.toLowerCase().includes("debug") && console.log('genericMetadata entry = ', entry);
                                        formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
                                    });
                                    let fixedMetadata = this.props.fixedMetadata;
                                    if (fixedMetadata) {
                                        fixedMetadata.forEach((f) => {
                                            window.location.pathname.toLowerCase().includes("debug") && console.log('fixedMetadata f = ', f);
                                            formValues[f.templateKey + "~" + f.metadataKey] = f.value;
                                        })
                                    }

                                    //Add metadata inherited from folder
                                    let metadataFromParentFolder = this.props.metadataFromParentFolder;
                                    let folderDetails = this.props.folderDetails
                                    if (metadataFromParentFolder && folderDetails) {
                                        for (let i=0; i < metadataFromParentFolder.length; i++) {
                                            for (let r = 0; r < metadataFromParentFolder.length; r++) {
                                                //get metadata for each metadataKey listed
                                                metadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                                                    if (folderDetails[metadataKey]) {
                                                        formValues[metadataFromParentFolder[i].templateKey + "~" + metadataKey] = folderDetails[metadataFromParentFolder[i].templateKey + "~" + metadataKey]
                                                    }
                                                })
                                            }
                                        }
                                    }

                                    this.props.fields.map(
                                        field => (formValues[field.templateKey + "~" + field.metadataKey]= (n.metadata[field.templateKey + "~" + field.metadataKey]) && n.metadata[field.templateKey + "~" + field.metadataKey])
                                    )
                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => this.handleClick(event, n.rowId)}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.rowId}
                                            selected={isSelected}
                                        >

                                            <TableCell component="th" scope="row" className={classes.tableCell}>
                                                {n.name}
                                            </TableCell>
                                            {/*Add a column for each document metadata field*/}

                                            {this.props.fields.map(
                                                field => {
                                                    const fieldValue = (n.metadata[field.templateKey + "~" + field.metadataKey])? n.metadata[field.templateKey + "~" + field.metadataKey] : "";
                                                    return(
                                                        <TableCell
                                                            key={field.metadataKey}
                                                            align={field.numeric ? 'right' : 'left'}
                                                            className={classes.tableCell}
                                                        >
                                                            <FormControl fullWidth key={"fc" + field.templateKey + "~" + field.metadataKey + "_" + n.rowId}>
                                                                <RenderMetadataField
                                                                    fieldValue={fieldValue}
                                                                    handleOnChange={this.props.handleOnChangeDocumentMetadata}
                                                                    rowId={n.rowId}
                                                                    metadataConfig={this.props.metadataConfig}
                                                                    optionsConfig={this.props.optionsConfig}
                                                                    metadataKey={field.metadataKey}
                                                                    templateKey={field.templateKey}
                                                                    formValues={formValues}
                                                                    usage={"upload"}
                                                                    required={field.required}
                                                                    //helperText={(field.required && (!fieldValue || fieldValue === "")) ? "Value required" : ""}
                                                                />

                                                            </FormControl>
                                                        </TableCell>
                                                )},
                                                this,
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }
}

MetadataTableForUploads.propTypes = {
    classes: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    fixedMetadata: PropTypes.array.isRequired,
    genericMetadata: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    handleOnChangeDocumentMetadata: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataFromParentFolder: PropTypes.array.isRequired,
    folderDetails: PropTypes.object
}
export default withStyles(styles)(MetadataTableForUploads);

import React from 'react';
import PropTypes from 'prop-types';
import PDFViewer from "../viewer/PDFViewer";
import BoxViewer from "../viewer/BoxViewer";
import {withSnackbar} from "notistack";

/*

fileOptions info:
    https://github.com/box/box-ui-elements/blob/master/src/elements/content-preview/README.md

    Note: Any other option listed here https://github.com/box/box-content-preview#parameters--options,
    which is also not listed or overridden above as a prop, will be passed on as-is to the Preview Library.

    https://github.com/box/box-content-preview#parameters--options

 */
class Preview extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            boxViewer: window.REACT_APP_USE_PDF_VIEWER === "false" || window.REACT_APP_USE_PDF_VIEWER === false,
            mountBoxViewer: true,
            pageNumber: this.props.pageNumber,
            snippet: this.props.snippet
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log('preview props = ', props);

    }

    handleChangeViewer = () => {
        this.setState({boxViewer: !this.state.boxViewer });
    };

    unmountBoxViewer = () => {
        //called by componentWillUnmount on Preview component
        this.setState({mountBoxViewer : false});
    };

    remountBoxViewer = () => {
        //called by componentWillUnmount on Preview component
        this.setState({mountBoxViewer : true});
    };

    render() {

        return (

            <div
                style={{
                    //Fixed height to be specified in parent container
                    height: '100%',
                    width:'100%'
                }}
            >
                {/*{*/}
                {/*    (window.REACT_APP_USE_PDF_VIEWER === "true" || window.REACT_APP_USE_PDF_VIEWER === true) &&*/}

                {/*    <FormControlLabel*/}
                {/*        style={{paddingLeft: '16px'}}*/}
                {/*        control={*/}
                {/*            <Switch*/}
                {/*                checked={this.state.boxViewer}*/}
                {/*                onChange={this.handleChangeViewer}*/}
                {/*                value="boxViewer"*/}
                {/*                color="primary"*/}
                {/*                inputProps={{'aria-label': 'primary checkbox'}}*/}
                {/*            />*/}
                {/*        }*/}
                {/*        label="Box Viewer"*/}
                {/*    />*/}
                {/*}*/}

                {
                    (window.REACT_APP_USE_PDF_VIEWER === "true"
                        || window.REACT_APP_USE_PDF_VIEWER === true
                        || this.props.viewer === "PDFViewer"
                    ) ?

                        <PDFViewer
                            fileContent={this.props.fileContent}
                            userDetails={this.props.userDetails}
                            pageNumber={this.props.pageNumber ? this.props.pageNumber : null}
                            snippet={this.props.snippet ? this.props.snippet : null}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            boxDocID={this.props.boxDocID}
                        /> :

                        (this.state.mountBoxViewer) &&

                        <BoxViewer
                            boxDocID={this.props.boxDocID}
                            userDetails={this.props.userDetails}
                            pageNumber={this.props.pageNumber}
                            showHeader={this.props.showHeader}
                            actionsConfig={this.props.actionsConfig}
                            showSidebar={this.props.showSidebar}
                            unmountComponent={this.unmountBoxViewer}
                            remountComponent={this.remountBoxViewer}
                            preventOpen={this.props.preventOpen}
                    />

                }
            </div>
        );
    }
}

Preview.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileContent: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    pageNumber: PropTypes.number,
    showHeader: PropTypes.bool.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    showSidebar: PropTypes.bool,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    unmountComponent: PropTypes.func, //only required from Watson dialog to facilitate switching pages with ContentPreview (box viewer)
    remountComponent: PropTypes.func, //only required from Watson dialog to facilitate switching pages with ContentPreview (box viewer)
    preventOpen: PropTypes.bool,  //prevent opening when used in SelectTemplateDialog
    viewer: PropTypes.string
};

export default  withSnackbar(Preview);
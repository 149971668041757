import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import {withSnackbar} from "notistack";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class DeleteFile extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleCloseDialog = () => {
        this.setState({
            showDialog: false
        });
    };


    delete = async () => {

        this.setState({isFetching: true});

        const pathVar = "/" + this.props.boxDocID;
        const params =  "?userId=" + this.props.userDetails.boxId + '&audit=' + this.props.actionConfig.audit;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        const request = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken},
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
                if (response.ok) {
                    this.props.enqueueSnackbar("Delete complete", {variant: 'success'});
                } else {
                    this.props.enqueueSnackbar("Error " + response.status + " deleting file " + this.props.boxDocID + " " + response.statusText, {variant: 'error'});
                }
                this.setState({isFetching: false});
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("delete Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false});
            })
    };



    render() {

        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title={this.state.isFetching ? "Deleting..." : "Delete"}>
                    <span> {/*necessary to have tooltip available on disabled button */}
                        <IconButton
                            aria-label = "Menu"
                            aria-owns = {anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup = "true"
                            onClick={this.delete}
                            disabled = { this.state.isFetching }
                        >
                            {
                                this.state.isFetching ?

                                    <CircularProgress color="secondary" size={20}/> :

                                    <i className='material-icons md-36'>
                                        delete
                                    </i>
                            }

                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    }
}

DeleteFile.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(DeleteFile));
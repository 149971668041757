import React from 'react';
import ScriptTag from 'react-script-tag';

export default class Forms extends React.Component{

    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('Forms.js render Forms, props=', this.props);
        return(
            <div align="center" style={{marginTop: "-10px", height: '100%'}}>
                <ScriptTag
                    isHydrating={true}
                    type="text/javascript"
                    src={window.REACT_APP_FORMS_SCRIPT_URL}
                    data-id={window.REACT_APP_FORMS_ID}
                ></ScriptTag>
                <iframe
                    title={"Forms"}
                    src={window.REACT_APP_FORMS_URL}
                    frameBorder={0}
                    scrolling={'auto'}
                    style={{ display:'block', width:'100%', height: '90vh'}}
                    id={window.REACT_APP_FORMS_ID}
                />
            </div>
        )
    }
};

Forms.propTypes = {};
import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from "prop-types";
import {withSnackbar} from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({});

const INITIAL_STATE = {
    isFetching: false
};

class DownloadFile extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('DownloadFile props=', props);
    }

    download = async () => {

        this.setState({isFetching: true});

        let pathVar = "";
        let params = "";
        let url = "";
        let request = "";

        //Build array of ids from the search results
        let files = [];
        files.push(this.props.boxDocID);

        if (window.REACT_APP_DOCUMENT_SOURCE === "elastic") {
            url = window.REACT_APP_CONTENT_API_BASE_URL + "/api/elastic/document/content/" + this.props.boxDocID;
            request = {
                headers: {
                    "Authorization": "Bearer " + this.props.userDetails.accessToken,
                    "case-token": this.props.userDetails.caseAccessToken
                }
            }
        } else {
            pathVar = "/download";
            params = "?userId=" + this.props.userDetails.boxId;
            url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;
            request = {
                method: 'POST',
                headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken},
                body: files
            };
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
                    return (response.blob())
                } else {
                    return null
                }
            })
            .then(result => {

                const url = URL.createObjectURL(result);
                const fileName = this.props.fileName;

                // Create a new anchor element
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName || 'download';
                a.click();

                this.setState({isFetching: false});
                this.props.enqueueSnackbar("Download complete", {variant: 'success'});

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("download Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false});
                this.props.enqueueSnackbar("Error downloading file: " + e, {variant: 'error'});
            })
    };


    render() {

        const {anchorEl} = this.state;
        const progressMessage = "Downloading " + this.props.fileName;
        const tooltipTitle = this.props.downloadFileActionConfig.label + " " + this.props.fileName;

        return (
            <React.Fragment>
                <Tooltip title={this.state.isFetching ? progressMessage : tooltipTitle}>
                    <span>

                        <IconButton
                            aria-label = "Menu"
                            aria-owns = {anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup = "true"
                            onClick={this.download}
                            disabled = { this.state.isFetching }
                        >
                            {
                                this.state.isFetching ?

                                <CircularProgress color="secondary" size={20}/> :

                                <i className='material-icons md-36'>
                                    file_download
                                </i>
                            }

                        </IconButton>

                    </span>
                </Tooltip>
            </React.Fragment>
        )
    }
}

DownloadFile.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    downloadFileActionConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles)(DownloadFile));
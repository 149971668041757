import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from "@material-ui/core/FormControl/FormControl";
import RenderMetadataField from "../../../../common/RenderMetadataField";
import {CircularProgress, LinearProgress, Tooltip} from "@material-ui/core";
import {getErrorMessageFromResponse} from "../../../../common/helper";
import {withSnackbar} from 'notistack';
import AddFolderForFile from "../AddFolderForFile";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    table: {},
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: theme.spacing(2)
    }
});

function FileTable (props) {

    const [order] = useState('asc');
    const [orderBy] = useState ('name');
    const data = props.files;
    const portfolio = props.genericMetadata["howdenConstruction~portfolio"];
    const subfolderName = props.genericMetadata["howdenConstruction~subfolderName"];
    const folders = props.folders;
    const [initValsComplete, setInitValuesComplete] = useState(false);
    const [isFetching, setIsFetching] = useState({})
    const [isChanging, setIsChanging] = useState({})
    const timeout = useRef();

    const debug = window.location.pathname.toLowerCase().includes("debug");

    useEffect(() => {

        async function populateDefaultFieldValues() {

            //extract field value from filename here if specified in config
            let spvs = [];
            props.fields.forEach(field => {
                if (field.populateFromFileName && field.populateFromFileName.autoPopulateFromFileName) {
                    //loop through files
                    data.forEach(file => {
                        const filename = file.name.split('.')[0]
                        let defaultFieldValue = "";

                        //loop through fileNameSplitCharacters to try to determine the SPV (catering for different hyphen lengths – - )
                        const splitChars = field.populateFromFileName.fileNameSplitCharacters;

                        if (splitChars && Array.isArray(splitChars)) {
                            for (let i = 0; i < splitChars.length; i++)  {
                                const splitChar = splitChars[i]

                                console.log('splitChars = ', splitChars)
                                if (filename.indexOf(splitChar) > -1) {
                                    const splitVals = filename.split(splitChar);
                                    console.log('splitVals=', splitVals);
                                    if (typeof (field.populateFromFileName.splitArrayPosition) === "number") {
                                        defaultFieldValue = splitVals[field.populateFromFileName.splitArrayPosition].trim()
                                        spvs.push(defaultFieldValue)
                                    }
                                    //exit loop if any of the split characters found
                                    break;
                                }

                            }

                        } else {
                            defaultFieldValue = filename
                        }
                        //update file on parent component with default value, ready for submit
                        const fieldId = field.templateKey + "~" + field.metadataKey + "_" + file.rowId;
                        props.handleOnChangeDocumentMetadata(fieldId, defaultFieldValue)
                    })
                }
            })

            if (spvs.length === 0 ) {
                console.log ('*** no spvs')
                setInitValuesComplete(true)
            } else {
                folderSearch(spvs.sort())
            }
        }

        async function folderSearch (spvs) {

            let initFolders = {};

            //Search for SPV Names to validate that folders exist
            // Update the document title using the browser API

            ///do the folder search here using the criteria provided
            const folderId = props.searchConfig.folderIds[0];

            let fields = [];
            fields.push("name")
            const templateKey = props.searchConfig.box.templateKey;
            const metadataKeys = props.searchConfig.box.metadataKeys;
            if (metadataKeys) {
                for (let i = 0; i < metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + templateKey + "." + metadataKeys[i])
                }
            }

            let spvVals = []
            let queryParams = {
                "val0": "subfolder",
                "val1": portfolio,
                "val2": subfolderName
            }
            let n = 2;
            spvs.forEach(spv => {
                n++
                spvVals.push(":val" + n);
                queryParams["val" + n] = spv
            })
            let query = "objectType =:val0 AND portfolio =:val1 AND subfolderName ILIKE :val2 AND spvEntity IN (" + spvVals.join() + ")"

            const body = {
                "ancestor_folder_id": folderId,
                "fields": fields,
                "limit": "100",
                "from": "enterprise_"+ window.REACT_APP_ENTERPRISE_ID+"." + templateKey,
                "query": query,
                "query_params": queryParams,
                "order_by": [],
                "marker": ""
            }

            await props.triggerRefreshAuthToken();

            let request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };
            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;
            debug && console.log('folderSearch REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

            fetch(url, request)
                .then(response => {
                    if (response.ok) {
                        return (response.json())
                    } else {
                        //debug && console.log ('response not ok - doSearchMetadata RESPONSE: ', response);
                        Promise.resolve(getErrorMessageFromResponse(response, "retrieving search results"))
                            .then(message => {
                                props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")" : ""), {variant: 'error'});
                            })

                        debug && console.log("search folder error. url:", url, "request: ", request, "response:", response);
                        return null
                    }
                })
                .then(responseJson => {
                    debug && console.log('search response.json: ', responseJson);
                    if (responseJson && responseJson.entries && Array.isArray(responseJson.entries)) {

                        responseJson.entries.forEach(folder => {
                            const spvName = folder.metadata["enterprise_"+window.REACT_APP_ENTERPRISE_ID].howdenConstruction.spvEntity
                            initFolders[spvName.toUpperCase()] = folder;
                        })
                    }

                    console.log ('*** initFolders = ', initFolders);

                    props.updateFolders(initFolders);
                    setInitValuesComplete(true)

                    //If any folders not found then run individual ILIKE searches in background to pick up any folders not matching case, or with special characters in name
                    data.forEach(file => {
                        const spv = file.metadata['howdenConstruction~spvEntity'];
                        if (spv) {
                            const folderFound = initFolders[spv.toUpperCase()];
                            if (!folderFound) {
                                getFolder(spv, file.rowId);
                            }
                        }
                    })
                })
                .catch(e => {
                    debug && console.log("folder search Exception:", e, "url:", url, "request: ", request);
                    setInitValuesComplete(true)
                })
        }

        populateDefaultFieldValues()

    },[]);

    function onChangeField (id, value) {
        console.log ('*** onChangeField id=', id, 'value=', value );
        if (id.indexOf("_") > -1 && id.split('_')[0] === "howdenConstruction~spvEntity") {
            const fileNum = id.split('_')[1]
            console.log ('folders[value]=', folders[value.toUpperCase()]);
            setIsChanging({...isChanging, [fileNum]: true}) //to hide add folder icon while value being updated
            if(value && !folders[value.toUpperCase()]) {
                getFolder(value, fileNum)
            }
        }
        //if SPV changes then re-run SPV search
        props.handleOnChangeDocumentMetadata(id,value)
    }

    async function getFolder(spv, fileNum)  {

        console.log ('getFolder spv=', spv);

        //Clear the previous timeout.
        clearTimeout(timeout.current)

        // Update the document title using the browser API
        ///do the folder search here using the criteria provided
        const folderId = props.searchConfig.folderIds[0];
        let fields = [];
        fields.push("name")
        const templateKey = props.searchConfig.box.templateKey;
        const metadataKeys = props.searchConfig.box.metadataKeys;
        if (metadataKeys) {
            for (let i = 0; i < metadataKeys.length; i++) {
                fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + templateKey + "." + metadataKeys[i])
            }
        }
        const body = {
            "ancestor_folder_id": folderId,
            "fields": fields,
            "limit": "5",
            "from": "enterprise_"+ window.REACT_APP_ENTERPRISE_ID+"." + templateKey,
            "query": "objectType = :val0 AND portfolio = :val1 AND spvEntity ILIKE :val2 AND subfolderName ILIKE :val3",
            "query_params": {
                "val0": "subfolder",
                "val1": portfolio,
                "val2": spv,
                "val3": subfolderName
            },
            "order_by": [],
            "marker": ""
        }

        await props.triggerRefreshAuthToken();

        let request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;

        debug && console.log ('folderSearch REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

        //set timer to prevent search running before user has finished typing
        timeout.current = setTimeout(() => {
            setIsChanging({...isChanging, [fileNum]: false})
            setIsFetching({...isFetching, [fileNum]: true})
            fetch(url, request)
                .then(response => {
                    if (response.ok) {
                        return (response.json())
                    } else {
                        Promise.resolve(getErrorMessageFromResponse(response, "retrieving search results"))
                            .then(message => {
                                props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")" : ""), {variant: 'error'});
                            })

                        debug && console.log("search folder error. url:", url, "request: ", request, "response:", response);
                        return null
                    }
                })
                .then(responseJson => {
                    debug && console.log('search response.json: ', responseJson);

                    if (responseJson) {
                        if (!responseJson.entries || !Array.isArray(responseJson.entries) || responseJson.entries.length === 0) {
                            console.log('folder not found')
                        } else if (responseJson.entries.length > 1) {
                            console.log('More than one match found, please check SPV name')
                        } else if (responseJson.entries.length === 1) {
                            folders[spv.toUpperCase()] = responseJson.entries[0];
                            console.log('folder found, update folders state')
                            props.updateFolders ({...folders, [spv.toUpperCase]: responseJson.entries[0],})

                        }
                    }
                })
                .then(result => {
                    setIsFetching({...isFetching, [fileNum]: false})
                })
                .catch(e => {
                    setIsFetching({...isFetching, [fileNum]: false})
                    debug && console.log("folder search Exception:", e, "url:", url, "request: ", request);
                })
        },3000)

    }



    const desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    const stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    const getSorting = (order, orderBy) => {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    const { classes } = props;

    return (
        <Paper className={classes.root}>
            {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
            {
                !initValsComplete ?

                    <LinearProgress/> :

                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <TableBody>
                                {stableSort(data, getSorting(order, orderBy))
                                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(file => {

                                        console.log ('TableBody file=', file)
                                        const spvEntity = file.metadata["howdenConstruction~spvEntity"] ;
                                        const folderFound = folders[spvEntity.toUpperCase()]

                                        //generate object with all values in the row
                                        let formValues = {};
                                        Object.entries(props.genericMetadata).forEach(entry => {
                                            window.location.pathname.toLowerCase().includes("debug") && console.log('genericMetadata entry = ', entry);
                                            formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
                                        });
                                        let fixedMetadata = props.fixedMetadata;
                                        if (fixedMetadata) {
                                            fixedMetadata.forEach((f) => {
                                                window.location.pathname.toLowerCase().includes("debug") && console.log('fixedMetadata f = ', f);
                                                formValues[f.templateKey + "~" + f.metadataKey] = f.value;
                                            })
                                        }

                                        props.fields.map(
                                            field => (formValues[field.templateKey + "~" + field.metadataKey]= (file.metadata[field.templateKey + "~" + field.metadataKey]) && file.metadata[field.templateKey + "~" + field.metadataKey])
                                        )
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={file.rowId}>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    {file.name}
                                                </TableCell>
                                                {/*Add a column for each document metadata field*/}

                                                {
                                                    props.fields.map(field => {
                                                        const fieldValue = (file.metadata[field.templateKey + "~" + field.metadataKey])? file.metadata[field.templateKey + "~" + field.metadataKey] : "";
                                                        console.log (field, 'fieldValue=', fieldValue)
                                                        return(
                                                            <TableCell
                                                                key={field.metadataKey}
                                                                align={field.numeric ? 'right' : 'left'}
                                                                className={classes.tableCell}
                                                            >
                                                                <FormControl fullWidth key={"fc" + field.templateKey + "~" + field.metadataKey + "_" + file.rowId}>
                                                                    <RenderMetadataField
                                                                        fieldValue={fieldValue}
                                                                        handleOnChange={onChangeField}
                                                                        rowId={file.rowId}
                                                                        metadataConfig={props.metadataConfig}
                                                                        optionsConfig={props.optionsConfig}
                                                                        metadataKey={field.metadataKey}
                                                                        templateKey={field.templateKey}
                                                                        formValues={formValues}
                                                                        usage={"upload"}
                                                                        required={field.required}
                                                                    />

                                                                </FormControl>
                                                            </TableCell>
                                                        )})
                                                }
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    {
                                                        folderFound ?
                                                            <Tooltip title={"SPV found"}><i className="material-icons" style={{padding: "12px"}}>check</i></Tooltip> :
                                                            isChanging[file.rowId] ?
                                                                <span/> :
                                                                isFetching[file.rowId] ?
                                                                    <Tooltip title={"Searching for SPV"}><CircularProgress color="secondary" size={20} style={{padding: "12px"}}/></Tooltip> :
                                                                    spvEntity ?
                                                                    <AddFolderForFile
                                                                        classes={classes}
                                                                        genericMetadata={props.genericMetadata}
                                                                        addFolderConfig={props.addFolderConfig}
                                                                        spvEntity={spvEntity}
                                                                        portfolio={portfolio}
                                                                        userDetails={props.userDetails}
                                                                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                                                        metadata={{...props.genericMetadata, ...file.metadata}} //all metadata
                                                                        getFolder={getFolder}
                                                                        rowId={file.rowId}
                                                                        optionsConfig={props.optionsConfig}
                                                                        metadataConfig={props.metadataConfig}
                                                                    /> :
                                                                    <span/>
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </div>

            }
        </Paper>
    );

}

FileTable.propTypes = {
    classes: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    fixedMetadata: PropTypes.array.isRequired,
    genericMetadata: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    handleOnChangeDocumentMetadata: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataFromParentFolder: PropTypes.array.isRequired,
    folderDetails: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    folders: PropTypes.object.isRequired,
    updateFolders: PropTypes.object.isRequired,
    searchConfig: PropTypes.object.isRequired
}
export default withSnackbar(withStyles(styles)(FileTable));

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import AddDocDialog from "./AddDocDialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import AddDocOptionsDialog from "./AddDocOptionsDialog";
import SelectTemplateDialog from "./template/SelectTemplateDialog";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});

const INITIAL_STATE = {
    //dialog
    open: false,
};

class AddDocButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('AddDocButton props: ', props);

    }

    addClick = () => {

        //if templateConfig set on folder then prompt to select template
        let showTemplateOption = false;
        if (this.props.folderDetails) {
            if (this.props.folderDetails.templateConfig) {
                if (this.props.folderDetails.templateConfig !== "") {
                    showTemplateOption = true
                }
            }
        }

        //if adding doc from main search results table then get templateConfig from addDocumentMain action config rather than folder details
        if (this.props.actionConfig && this.props.actionConfig.templateConfig) {
            if (this.props.actionConfig.templateConfig !== "" ){
                showTemplateOption = true
            }
        }

        showTemplateOption ? this.showAddDocOptionsDialog() : this.showAddDocDialog()

    };

    showAddDocOptionsDialog = () => {
        this.setState({
            showAddDocOptionsDialog: true
        });
    };

    closeAddDocOptionsDialog = (selection) => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showAddDocOptionsDialog: false,
            showAddDocDialog: selection === "upload",
            showSelectTemplateDialog: selection !== "" && selection !== "upload",
            optionSelection: selection
        });
    };

    showAddDocDialog = () => {
        this.setState({
            //open: this.props.isOpen
            showAddDocDialog: true
        });
    };

    closeAddDocDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showAddDocDialog: false,
        });
        //force unmount to refresh folder documents
        if (this.props.unmountFolderDocumentsGet) {
            this.props.unmountFolderDocumentsGet();
        }
    };

    closeSelectTemplateDialog = (filesAdded) => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showSelectTemplateDialog: false,
        });
        //force unmount to refresh folder documents
        if (filesAdded) {
            if (this.props.unmountFolderDocumentsGet) {
                this.props.unmountFolderDocumentsGet();
            }
        }
    };

    cancelAddDocDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showAddDocDialog: false,
        });
    };

    render() {
        const { classes, uploadConfig, ...other } = this.props;

        let templateOptions = [];
        //read from folder metadata if Adding document within a Case
        if (this.props.folderDetails && this.props.folderDetails.templateConfig) {
            templateOptions = this.props.folderDetails.templateConfig.split(",")
        } else if (this.props.actionConfig && this.props.actionConfig.templateConfig) {
            templateOptions = this.props.actionConfig.templateConfig
        }


        let tooltipTitle = this.props.actionConfig.label ? this.props.actionConfig.label : "Add File(s)";
        if (this.props.folderDetails) {
            if (Object.entries(this.props.folderDetails).length > 0) {
                if (this.props.folderDetails.name && this.props.folderDetails.name !== "") {
                    tooltipTitle = tooltipTitle + " to " + this.props.folderDetails.name
                }
            }
        }

        let showUploadOption = true;

        if (uploadConfig.uploadFromTemplateOnly) {
            showUploadOption = false
        }
        if (uploadConfig.uploadFromTemplateOnlyWhen) {
            const templateKey = uploadConfig.uploadFromTemplateOnlyWhen.templateKey;
            const metadataKey = uploadConfig.uploadFromTemplateOnlyWhen.metadataKey;
            const value = uploadConfig.uploadFromTemplateOnlyWhen.value;
            const folderDetails = this.props.folderDetails;
            if (folderDetails && folderDetails.metadata && folderDetails.metadata["enterprise_"+ window.REACT_APP_ENTERPRISE_ID]) {
                const folderMetadata = folderDetails.metadata["enterprise_"+ window.REACT_APP_ENTERPRISE_ID]
                if (folderMetadata[templateKey]) {
                    if (folderMetadata[templateKey][metadataKey]) {
                        if(folderMetadata[templateKey][metadataKey] === value) {
                            showUploadOption = false
                        }
                    }
                }
            }
        }

        const dialogTitle = uploadConfig.dialogTitle ? uploadConfig.dialogTitle : "Add document";

        const folderSearchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;
        const indexes = folderSearchConfig && folderSearchConfig.elastic && folderSearchConfig.elastic.indexes;

        return (
            <React.Fragment>
                <Tooltip title={tooltipTitle}>
                    <IconButton color="inherit" aria-label={dialogTitle} onClick={this.addClick}>
                        <i className={'material-icons md-36 ' + this.props.iconClass}>add</i>
                    </IconButton>
                </Tooltip>

                {this.state.showAddDocOptionsDialog &&
                <AddDocOptionsDialog
                    {...other}
                    parentClasses={this.props.classes}
                    userDetails={this.props.userDetails}
                    handleCloseDialog={this.closeAddDocOptionsDialog}
                    templatesConfig={this.props.templatesConfig}
                    showUploadOption = {showUploadOption}
                    templateOptions={templateOptions}
                    uploadConfig={uploadConfig}
                />}

                {this.state.showAddDocDialog &&
                    <AddDocDialog
                        {...other}
                        parentClasses={this.props.classes}
                        userDetails={this.props.userDetails}
                        uploadConfig={uploadConfig}
                        metadataConfig={this.props.metadataConfig}
                        optionsConfig={this.props.optionsConfig}
                        closeAddDocDialog={this.closeAddDocDialog}
                        cancelAddDocDialog={this.cancelAddDocDialog}
                        folderDetails = {this.props.folderDetails}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        actionConfig={this.props.actionConfig}
                        indexes={indexes}
                    />}

                {this.state.showSelectTemplateDialog &&
                <SelectTemplateDialog
                    {...other}
                    parentClasses={this.props.classes}
                    userDetails={this.props.userDetails}
                    uploadConfig={uploadConfig}
                    handleCloseDialog={this.closeSelectTemplateDialog}
                    //folderDetails = {this.props.folderDetails}
                    destinationFolderId={this.props.folderDetails ? this.props.folderDetails.id : this.props.selectedWorkspaceConfig.uploadConfig.folderId}
                    // parentFolderId={this.props.folderDetails ? this.props.folderDetails.parentFolderId: ""} //only required when adding to a subfolder
                    parentFolderId={
                        this.props.folderDetails ?
                            this.props.folderDetails.parentFolderId ?
                                this.props.folderDetails.parentFolderId: // adding to subfolder
                                this.props.folderDetails.id : //adding to case folder
                            ""}
                    actionConfig={this.props.actionConfig}
                    workspaceConfig={this.props.workspaceConfig}
                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                    templateConfig={this.props.templatesConfig[this.state.optionSelection]}
                    optionsConfig={this.props.optionsConfig}
                />}
            </React.Fragment>
        );
    }
}

AddDocButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object,
    iconClass: PropTypes.string.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    templatesConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    indexes: PropTypes.array //required when folder source is elastic
};


export default withSnackbar(withStyles(styles, { withTheme: true })(AddDocButton));

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddDocDialog from "../document/addDocument/AddDocDialog";
import Search from "../search/Search";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import PreviewWithPropertiesDialog from "../document/preview/PreviewWithPropertiesDialog";
import MyDocumentsDialog from "../browse/MyDocumentsDialog";
import Admin from "../admin/Admin";
import SearchResults from "../search/results/SearchResults";
import Tooltip from '@material-ui/core/Tooltip';
import {Grid} from "@material-ui/core";
import Dashboard from "../reporting/Dashboard";
import Form from "../forms/Forms";
import Link from '@material-ui/core/Link';
import FolderDetails from "../folder/details/FolderDetails";
import WorkspaceMenu from "../menus/WorkspaceMenu";
import MainMenu from "../menus/MainMenu";
import Drawer from "@material-ui/core/Drawer/Drawer";
import {getErrorMessageFromResponse, hasAccess} from "../common/helper"
import FolderSubfolders from '../folder/subfolders/FolderSubfolders';
import FolderContent from "../folder/FolderContent";
import {getDataForSearchResultsTableElastic} from "../search/helper";
import {withSnackbar} from "notistack";
import queryString from 'query-string';
import SearchCaseElastic from "../search/url/SearchCaseElastic";
import RefreshDashboard from "../reporting/RefreshDashboard";
import AllTasks from "../folder/tasks/AllTasks";
import { withTranslation } from 'react-i18next'
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScrollLoader from "../search/results/InfiniteScrollLoader";
import InfiniteScrollEndMessage from "../search/results/InfiniteScrollEndMessage";

let configFiles = null;

const styles = theme => ({

    root: {},
    grow: {
        flexGrow: 1,  //needed for right alignment of logout button
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    searchField: {
        position: 'absolute',
        left: '50%',
        //right: '75px',
        //right: theme.searchFieldPosition,
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    folderDetails: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },

    folderDetailsNoFields: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },

    folderContent: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    folderSubfolders: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: '0px',
        paddingBottom: theme.spacing(1),
        overflowY: "auto"
    },
    appBar: {
        marginBottom: theme.spacing(1)
    },
    appBarLogo: {
        paddingTop: (theme.appBarLogo ? theme.appBarLogo.paddingTop : "0px")
    },
    //search progress message
    searching: {
        color: '#808080',
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '24px',
        paddingLeft: theme.spacing(1)
    },
    searchProgressBar: {
        marginTop: -theme.spacing(1)
    },
    contentShift: {
        width: `calc(100% - ${theme.drawerWidth}px)`,
        marginLeft: theme.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 0,
        marginRight: 0,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: theme.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: theme.drawerWidth,
        borderRightColor: theme.palette.secondary.main,
        backgroundColor: theme.drawerBackgroundColour
    },
    drawerHeader: {
        paddingTop: theme.spacing(2),
        paddingRight: '0px',
        paddingLeft: theme.spacing(2),
        ...theme.mixins.toolbar,
        justifyContent: 'left',
        borderTop: 'solid',
        borderTopColor: theme.palette.secondary.main,
    },

    menuListItemHeader: {
        color: theme.menuListItem.headerColour
    },
    menuListItem: {
        color: theme.palette.primary.main
    },

    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -theme.drawerWidth,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    linkButton :{}

});

class PortalApp extends React.Component {

    constructor(props) {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        super(props);

        debug && console.log ('props=', props);

        configFiles = this.props.configFiles;
        const actionsConfig = configFiles.actionsConfig

        let drawerOpen = false;
        //need to handle string and boolean values as deployment yamls require string but then seem to read them as boolean;
        let drawerOpenOnLaunch = window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH === "true" || window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH === true;
        let drawerOpenOnLaunchWithDashboard = window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH_WITH_DASHBOARD === "true" || window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH_WITH_DASHBOARD === true;
        //if launching with dashboard check
        if (this.props.launchDashboard && actionsConfig.dashboardMain.enabled && hasAccess(actionsConfig.dashboardMain, this.props.userDetails.boxPortalRole)) {
            drawerOpenOnLaunch = drawerOpenOnLaunchWithDashboard;
        }

        const bulkUploadEnabled = actionsConfig.bulkUpload && actionsConfig.bulkUpload.enabled && hasAccess(actionsConfig.bulkUpload, this.props.userDetails.boxPortalRole);

        if (props.match.params.workspace) {
            drawerOpen = false
        } else if (props.workspaceConfig.length > 1 || bulkUploadEnabled) {
            if (drawerOpenOnLaunch) {
                drawerOpen = true
            }
        }

        let routeToFolder = false;
        if (props.match) {
            if (props.match.path) {
                const path = props.match.path.split("/");
                if (path.length >0) {
                    if (path[1] === "folder") {
                        routeToFolder = true;
                    }
                }
            }
        }

        let routeToUpload = false;
        if (props.match) {
            if (props.match.path) {
                const path = props.match.path.split("/");
                if (path.length >0) {
                    if (path[1] === "upload") {
                        routeToUpload = true;
                    }
                }
            }
        }

        let urlSearch = false;
        let searchCriteria=[];
        if (props.match) {
            if (props.match.path) {
                const path = props.match.path.split("/");
                if (path.length >0) {
                    if (path[1] === "search") {
                        //query params
                        let params = queryString.parse(props.location.search);
                        if (Object.entries(params).length > 0) {
                            const urlSearchConfig = actionsConfig.urlSearch;
                            const fields = urlSearchConfig && urlSearchConfig.fields;

                            //Set search criteria from url
                            Object.entries(params).forEach(param => {
                                const paramName = param[0];
                                const field = fields[paramName];
                                if (field) {
                                    const val = param[1]
                                    if (val !== "") {
                                        searchCriteria[field] = val;
                                    }
                                } else {
                                    console.log('Field config not found for parameter', paramName);
                                    this.props.enqueueSnackbar("Parameter " + paramName + " not configured for searching", {variant: 'error'});
                                }
                            });
                            //if no search criteria, don't run url search
                            urlSearch = Object.entries(searchCriteria).length > 0
                        }
                    }
                }
            }
        }

        const mode = (props.launchDashboard && actionsConfig.dashboardMain.enabled && hasAccess(actionsConfig.dashboardMain, props.userDetails.boxPortalRole)) ?
                "reporting" : "search";

        this.state = {
            // value: "en",
            searchResults: [],
            searchTotalCount: 0,
            searchCriteria: searchCriteria,
            searchCriteriaSimple: "",
            searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_LIMIT),
            searchOffset: 0,
            displaySearchResults: false,
            isFetching: false,
            searchType: "",
            drawerOpen: drawerOpen,
            drawerOpenOnLaunch: drawerOpenOnLaunch,
            selectedWorkspaceConfig: this.props.workspaceConfig[0], //default to first workspace in list
            showPreview: !!props.match.params.id ,
            routeToFolder: routeToFolder,
            routeToUpload: routeToUpload,
            showMyDocuments: false,
            showAdminGeneral: false,
            //showReporting: false,
            //mode: 'search', // search / admin / reporting / forms,
            mode: mode,
            mountSearch: true,
            newSearch: true,
            folderDetails: {},
            mountFolderDocuments: true,
            mountFolderTasks: true,
            mountFolderDetails: true,
            mountFolderContent: true,
            urlSearch: urlSearch,
            mountDashboard: mode === "reporting",
            workspaceFilter: ""
        };

        this.updateSearchResults = this.updateSearchResults.bind(this);
        this.updateSearchResultsURLSearch = this.updateSearchResultsURLSearch.bind(this);
        this.updateIsFetching = this.updateIsFetching.bind(this);
        //this.resetSearchOffset = this.resetSearchOffset.bind(this);
        this.manualDrawerClose = this.manualDrawerClose.bind(this);
        this.autoDrawerClose = this.autoDrawerClose.bind(this);
        this.remountSearch = this.remountSearch.bind(this);
        this.remountDashboard = this.remountDashboard.bind(this);
        this.getNextResults = this.getNextResults.bind(this);
        this.getAllResults = this.getAllResults.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.updateWorkspaceFilter = this.updateWorkspaceFilter.bind(this)
    }

    componentDidMount(){

        const debug = window.location.pathname.toLowerCase().includes("debug");
        const actionsConfig = configFiles.actionsConfig;

         if (debug) {
             console.log("configFiles.actionsConfig:", actionsConfig);
             console.log("configFiles.metadataConfig:", configFiles.metadataConfig);
             console.log("configFiles.optionsConfig:", configFiles.optionsConfig);
             console.log("configFiles.publicLinksConfig:", configFiles.publicLinksConfig);
             console.log("configFiles.dashboardsConfig:", configFiles.dashboardsConfig);
             console.log("configFiles.templatesConfig:", configFiles.templatesConfig);
             console.log("configFiles.workflowConfig:", configFiles.workflowConfig);
             console.log("workspaces:", this.props.workspaceConfig);
             console.log ("REACT_APP_ADMIN_APP_URL=", window.REACT_APP_ADMIN_APP_URL);
             console.log ("REACT_APP_APP_BAR_LOGO_LINK_URL=", window.REACT_APP_APP_BAR_LOGO_LINK_URL);
             console.log ("REACT_APP_APP_BAR_LOGO_SHOW=", window.REACT_APP_APP_BAR_LOGO_SHOW);
             console.log ("REACT_APP_APP_TITLE=", window.REACT_APP_APP_TITLE);
             console.log ("REACT_APP_CASE_API_BASE_URL=", window.REACT_APP_CASE_API_BASE_URL);
             console.log ("REACT_APP_CASE_API_SEARCH=", window.REACT_APP_CASE_API_SEARCH);
             console.log ("REACT_APP_SECURITY_API_BASE_URL=", window.REACT_APP_SECURITY_API_BASE_URL);
             console.log ("REACT_APP_CASE_AUTH_REQUIRED=", window.REACT_APP_CASE_AUTH_REQUIRED);
             console.log ("REACT_APP_CASE_AUTH_SECURITY=", window.REACT_APP_CASE_AUTH_SECURITY);
             console.log ("REACT_APP_COMMENT_API_BASE_URL=", window.REACT_APP_COMMENT_API_BASE_URL);
             console.log ("REACT_APP_COMMENT_API_DOCUMENT=", window.REACT_APP_COMMENT_API_DOCUMENT);
             console.log ("REACT_APP_COMMENT_API_FOLDER=", window.REACT_APP_COMMENT_API_FOLDER);
             console.log ("REACT_APP_CONTENT_API_BASE_URL=", window.REACT_APP_CONTENT_API_BASE_URL);
             console.log ("REACT_APP_CONTENT_API_BASE_URL_AI=", window.REACT_APP_CONTENT_API_BASE_URL_AI);
             console.log ("REACT_APP_CONTENT_API_CAPTURE=", window.REACT_APP_CONTENT_API_CAPTURE);
             console.log ("REACT_APP_CONTENT_API_COLLABORATION=", window.REACT_APP_CONTENT_API_COLLABORATION);
             console.log ("REACT_APP_CONTENT_API_DOCUMENT=", window.REACT_APP_CONTENT_API_DOCUMENT);
             console.log ("REACT_APP_CONTENT_API_FOLDER_FOLDER=", window.REACT_APP_CONTENT_API_FOLDER_FOLDER);
             console.log ("REACT_APP_CONTENT_API_FOLDER_SEARCH_ADVANCED=", window.REACT_APP_CONTENT_API_FOLDER_SEARCH_ADVANCED);
             console.log ("REACT_APP_CONTENT_API_FOLDER_SEARCH_SIMPLE=", window.REACT_APP_CONTENT_API_FOLDER_SEARCH_SIMPLE);
             console.log ("REACT_APP_CONTENT_API_SEARCH_ADVANCED=", window.REACT_APP_CONTENT_API_SEARCH_ADVANCED);
             console.log ("REACT_APP_CONTENT_API_SEARCH_LIMIT=", window.REACT_APP_CONTENT_API_SEARCH_LIMIT);
             console.log ("REACT_APP_CONTENT_API_SEARCH_METADATA=", window.REACT_APP_CONTENT_API_SEARCH_METADATA);
             console.log ("REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT=", window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT);
             console.log ("REACT_APP_CONTENT_API_BOX_FOLDER_ITEMS_LIMIT=", window.REACT_APP_CONTENT_API_BOX_FOLDER_ITEMS_LIMIT);
             console.log ("REACT_APP_CONTENT_API_SEARCH_SIMPLE=", window.REACT_APP_CONTENT_API_SEARCH_SIMPLE);
             console.log ("REACT_APP_CONTENT_API_SECURITY_USER_TOKEN=", window.REACT_APP_CONTENT_API_SECURITY_USER_TOKEN);
             console.log ("REACT_APP_DASHBOARD_URL=", window.REACT_APP_DASHBOARD_URL);
             console.log ("REACT_APP_FOLDER_SOURCE=", window.REACT_APP_FOLDER_SOURCE);
             console.log ("REACT_APP_DOCUMENT_SOURCE=", window.REACT_APP_DOCUMENT_SOURCE);
             console.log ("REACT_APP_TASK_SOURCE=", window.REACT_APP_TASK_SOURCE);
             console.log ("REACT_APP_COMMENT_SOURCE=", window.REACT_APP_COMMENT_SOURCE);
             console.log ("REACT_APP_ENTERPRISE_ID=", window.REACT_APP_ENTERPRISE_ID);
             console.log ("REACT_APP_ENV=", window.REACT_APP_ENV);
             console.log ("REACT_APP_FORMS_URL=", window.REACT_APP_FORMS_URL);
             console.log ("REACT_APP_MY_DOCUMENTS_FOLDER", window.REACT_APP_MY_DOCUMENTS_FOLDER);
             console.log ("REACT_APP_OKTA_BASE_URL=", window.REACT_APP_OKTA_BASE_URL);
             console.log ("REACT_APP_OKTA_CASE_ID=", window.REACT_APP_OKTA_CASE_ID);
             console.log ("REACT_APP_OKTA_CLIENT_ID=", window.REACT_APP_OKTA_CLIENT_ID);
             console.log ("REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH=", window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH);
             console.log ("REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH_WITH_DASHBOARD=", window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH_WITH_DASHBOARD);
             console.log ("REACT_APP_TASK_API_BASE_URL=", window.REACT_APP_TASK_API_BASE_URL);
             console.log ("REACT_APP_TASK_API_DOCUMENT_ASSIGNMENT=", window.REACT_APP_TASK_API_DOCUMENT_ASSIGNMENT);
             console.log ("REACT_APP_TASK_API_FOLDER_ASSIGNMENT=", window.REACT_APP_TASK_API_FOLDER_ASSIGNMENT);
             console.log ("REACT_APP_TASK_API_FOLDER_FOLDER=", window.REACT_APP_TASK_API_FOLDER_FOLDER);
             console.log ("REACT_APP_WATSON_APIKEY=", window.REACT_APP_WATSON_APIKEY);
             console.log ("REACT_APP_WATSON_COLLECTIONID=", window.REACT_APP_WATSON_COLLECTIONID);
             console.log ("REACT_APP_WATSON_ENVIRONMENTID=", window.REACT_APP_WATSON_ENVIRONMENTID);
             console.log ("REACT_APP_WATSON_INSTANCEID=", window.REACT_APP_WATSON_INSTANCEID);
             console.log ("REACT_APP_USE_PDF_VIEWER=", window.REACT_APP_USE_PDF_VIEWER);
             console.log ("REACT_APP_SYSTEM_METADATA_TEMPLATE=", window.REACT_APP_SYSTEM_METADATA_TEMPLATE);
             console.log ("REACT_APP_BASE_URL_WORKFLOW=", window.REACT_APP_BASE_URL_WORKFLOW);
             console.log ("REACT_APP_BASE_URL_SCREENING=", window.REACT_APP_BASE_URL_SCREENING);
             console.log ("REACT_APP_ENABLE_TRANSLATION=", window.REACT_APP_ENABLE_TRANSLATION);
         }

        //debug && console.log ('this.props.match.params = ', this.props.match.params);
        if (this.props.launchDashboard && actionsConfig.dashboardMain.enabled && hasAccess(actionsConfig.dashboardMain, this.props.userDetails.boxPortalRole)) {
            this.handleShowDashboard()
        } else if (this.props.match.params.workspace) {
            //debug && console.log ('this.props.match = ', this.props.match);
            this.getWorkspace(this.props.match.params.workspace);
        }

        let routeToFolderId = "";
        if (this.props.match) {
            if (this.props.match.path) {
                const path = this.props.match.path.split("/");
                if (path.length >0) {
                    if (path[1] === "folder") {
                        routeToFolderId = this.props.match.params.folderId;
                        console.log ('routeToFolderId=', routeToFolderId);
                        this.getFolderMetadata(routeToFolderId, true);
                    }
                }
            }
        }
    }


    updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount, nextMarker) {

        // const debug = window.location.pathname.toLowerCase().includes("debug") ;
        // debug && console.log ('updateSearchResults: ', "searchResults= ", searchResults, "searchType= ", searchType, "displaySearchResults= ", displaySearchResults,
        //      "searchCriteria= ", searchCriteria, "searchCriteriaSimple= ", searchCriteriaSimple, "totalCount= ", totalCount, "nextMarker=", nextMarker);

        let newSearchResults = this.state.searchOffset === 0 ? searchResults : this.state.searchResults.concat(searchResults);

        this.setState({
            searchResults: newSearchResults,
            displaySearchResults: displaySearchResults,
            searchCriteria: searchCriteria,
            searchCriteriaSimple: searchCriteriaSimple,
            searchTotalCount: totalCount ? totalCount : 0,
            //reset searchLimit
            searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_LIMIT),
            isFetching: false,
            searchType: searchType,
            folderDetails: {},
            nextMarker: nextMarker
        });

        //If only one result returned, open folder
        if (searchType === "folder" && newSearchResults.length === 1 ) {
            if (this.state.selectedWorkspaceConfig.searchConfig.folderSearch.autoOpenSingleResult) {
                this.openFolder(newSearchResults[0])
            }
        }
    }

    updateSearchResultsURLSearch(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount, nextMarker){

        // const debug = true;
        // debug && console.log ('updateSearchResultsURLSearch: ', "searchResults= ", searchResults, "searchType= ", searchType, "displaySearchResults= ", displaySearchResults,
        //     "searchCriteria= ", searchCriteria, "searchCriteriaSimple= ", searchCriteriaSimple, "totalCount= ", totalCount, "nextMarker=", nextMarker);

        this.setState({
            urlSearch: false
        });
        this.updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount, nextMarker)

        //if one search result then route directly to folder
        if (searchResults.length === 1) {
            this.setState({folderDetails: searchResults[0]})
        }
    }

    updateIsFetching(isFetching, folderDetails , newSearch, searchOffset) {

       // window.location.pathname.toLowerCase().includes("debug") && console.log ('updateIsFetching: isFetching = ', isFetching, " folderDetails = ", folderDetails, " newSearch = ", newSearch, " searchOffset = ", searchOffset);

        this.setState(
            {
                ...this.state,
                isFetching: isFetching,
                folderDetails: folderDetails,
                newSearch: newSearch,
                searchOffset: searchOffset,
            });
    }

    // resetSearchOffset () {
    //
    //     this.setState(
    //         {
    //             ...this.state,
    //             searchOffset: 0,
    //             newSearch: true
    //         });
    // }

    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true });
    };

    manualDrawerClose = () => {

  //    if drawer set to launch on open reset it so that it doesn't keep auto-opening after the user has manually closedd it
        if (this.state.drawerOpenOnLaunch) {
            this.setState({ drawerOpenOnLaunch: false });
            this.setState({ drawerOpen: false });
        } else {
            this.setState({ drawerOpen: false });
        }
    };

    autoDrawerClose = () => {
        //close unless set to auto-open on application launch
        if (!this.state.drawerOpenOnLaunch) {
            this.setState({ drawerOpen: false });
        }
    };

    handleMyDocumentsClose = () => {
        this.setState({showMyDocuments: false});
    };

    handleClosePreviewDialog = () => {
        this.setState({showPreview: false});

    };

    handleSelectWorkspace = (w) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('PortalApp handleSelectWorkspace w=', w);

        const mode = this.state.mode;

        this.autoDrawerClose();
        if (mode === "admin" || mode === "reporting" || mode === "forms" || mode === "tasks") {
            this.setState({
                //clear previous search details
                newSearch: true,
                searchResults: [],
                searchTotalCount: 0,
                searchCriteria: [],
                searchCriteriaSimple :"",
                searchOffset: 0,
                displaySearchResults: 0,
                searchType: "",
                //switch to search mode
                mode: 'search',
                //mountSearch: true //new
            })
        } else {
            //trigger remount of search component
            this.setState({
                //Clear folder details
                folderDetails: {},
                //clear previous search details
                newSearch: true,
                searchResults: [],
                searchTotalCount: 0,
                searchCriteria: [],
                searchCriteriaSimple :"",
                searchOffset: 0,
                displaySearchResults: 0,
                searchType: "",
                //trigger remount of search component
                mountSearch: false,
                routeToFolder: false //should only be true when the app is launched with a folder id in path
            });
        }


        this.setState({
            selectedWorkspaceConfig: w
        });
    };

    updateWorkspaceFilter = (val) => {
        this.setState({workspaceFilter: val});
    };

    remountSearch = () => {
        //called by componentWillUnmount on Search component
        this.setState({mountSearch : true});
    };

    remountDashboard = () => {
        this.setState({mountDashboard: true});
    };

    unmountDashboard = () => {
        this.setState({mountDashboard: false});
    };

    unmountFolderDetails = () => {
        //console.log ('**** portalApp unmount FolderDetails')
        this.setState({mountFolderDetails: false});
    }

    remountFolderDetails = () => {
        this.setState({mountFolderDetails: true});
    }

    // unmountFolderContent = () => {
        //alert ('unmounting folder content')
        //console.log ('**** portalApp unmount FolderDetails')
    //     this.setState({mountFolderContent: false});
    // }

    remountFolderContent = () => {
        this.setState({mountFolderContent: true});
    }

    getNextResults = () => {
       //window.location.pathname.toLowerCase().includes("debug") && console.log ('PortalApp getNextResults');
        //trigger remount of search component
        this.setState({
            mountSearch: false,
            newSearch: false,
            searchOffset: this.state.searchOffset + this.state.searchLimit
        });
    };

    getAllResults = () => {

        // //trigger remount of search component
        // this.setState({
        //     mountSearch: false,
        //     newSearch: false,
        //     searchOffset: this.state.searchOffset + this.state.searchLimit
        // });
    };

    newSearch = () => {
        //trigger remount of search component
        this.setState({
            mountSearch: false,
            //newSearch: false,
            searchOffset: 0 //reset searchOffset
        });
    };

    getFolderMetadata = async(folderId, routeToFolder) => {
        //routeToFolder optional parameter, only required when we are routing direct to an elastic folder
        if (window.REACT_APP_FOLDER_SOURCE === "elastic"  ) {
            this.getFolderMetadataElastic (folderId, routeToFolder)
        } else {
            this.getFolderMetadataBox (folderId)
        }
    }

    getFolderMetadataElastic = async (folderId, routeToFolder) => {
        //if this is being called because we are routing to a folder directly, use search indexes from linkToFolder action config
        const debug = window.location.pathname.toLowerCase().includes("debug") || routeToFolder;
        const searchConfig = this.state.selectedWorkspaceConfig.searchConfig.folderSearch;
        const actionConfig = configFiles.actionsConfig.viewLinkFolder;
        debug && console.log ('getFolderMetadataElastic routeToFolder=', routeToFolder, 'actionConfig=', actionConfig, 'searchConfig=', searchConfig);
        const indexes = routeToFolder && actionConfig && actionConfig.indexes && Array.isArray([actionConfig.indexes]) && actionConfig.indexes.length > 0 ?
                actionConfig.indexes :
                searchConfig.elastic.indexes;
        const pathVar = (indexes.length > 0 ?  "/" + indexes.toString()  : "")  + "/" + folderId;

        await this.props.triggerRefreshAuthToken();

        const url = window.REACT_APP_CASE_API_BASE_URL  + pathVar ;
        const request = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
        };

        debug && console.log ("getFolderMetadataElastic url:", url);

        fetch(url , request )
            .then(response => {
                debug && console.log('getFolderMetadataElastic RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving folder details'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'})
                        })
                    debug && console.log("getFolderMetadataElastic error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(result => {
                debug && console.log ('getFolderMetadataElastic result = ' , result);
                if (result) {
                    const resultsCols = searchConfig.resultsColumns.concat(searchConfig.folderDetails.fields).concat(searchConfig.folderDetails.header)
                    let resultsColumns = JSON.parse(JSON.stringify(resultsCols)); //deep clone
                    let updatedFolderDetails = getDataForSearchResultsTableElastic(Array.isArray(result) ? result[0] : result, resultsColumns, window.REACT_APP_ENTERPRISE_ID, configFiles.metadataConfig, 0)
                    debug && console.log ('updatedFolderDetails = ', updatedFolderDetails);

                    //updatedFolderDetails["sanctioncase~ucr"] = "TESTXXXXXXXXXXX"

                    this.setState({folderDetails: updatedFolderDetails})
                }
            })
            .catch(e => {
                console.log("getFolderMetadataElastic Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    }

    getFolderMetadataBox = async (folderId) => {

        const pathVar = "/" + folderId;
        const params =  "/metadata";
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + params ;

        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("getFolderMetadata url :", url);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                if (response.ok) {
                    return(response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getFolderMetadata error.  url:", url, "request: ", request, "response.text:", response);
                    this.setState({
                        routeToFolder: false,
                        folderDetails:{}
                    })
                    return null
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('getFolderMetadata, result=', result);

                if (result) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('set folderDetails = ', result);

                    //loop through each metadata template and property and add to folderDetails
                    let updatedFolderDetails = this.state.folderDetails;
                    updatedFolderDetails.id = folderId; //needed if routing to this folder via url
                    if (result) {
                        result.forEach(metadataTemplate => {
                            let templateName = metadataTemplate.$template;
                            Object.entries(metadataTemplate).forEach(property => {
                                if (property[0].substring(0, 1) !== "$") {
                                    updatedFolderDetails[templateName + "~" + property[0]] = property[1]
                                }
                            })
                        })
                        //console.log ('*** updatedFolderDetails = ', updatedFolderDetails)
                        this.setState({folderDetails: updatedFolderDetails});
                    }
                }

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("getFolderMetadata Exception:", e, " url:", url, "request: ", request);
                //this.props.enqueueSnackbar("Error retrieving folder - " + e, {variant: 'error'});
                this.setState({isFetching: false})

            })
    };

    updateFolderDetails = (folderDetails) => {

        //Update folder details with response from case workflow action

        window.location.pathname.toLowerCase().includes("debug") && console.log ('PortalApp.js - updateFolderDetails with response from case workflow action service, folderDetails=', folderDetails);
        const searchConfig = this.state.selectedWorkspaceConfig.searchConfig.folderSearch;

        if (window.REACT_APP_FOLDER_SOURCE === "elastic") {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('updateFolderDetails - this.props.selectedWorkspaceConfig = ',this.props.selectedWorkspaceConfig);
            let updatedFolderDetails = getDataForSearchResultsTableElastic(folderDetails, searchConfig.resultsColumns, window.REACT_APP_ENTERPRISE_ID, configFiles.metadataConfig, 0)
            window.location.pathname.toLowerCase().includes("debug") && console.log ('updatedFolderDetails = ', updatedFolderDetails);
            this.setState({folderDetails: updatedFolderDetails})
        } else {
            //todo
        }

    }

    updateFolderDetailsFromWorkflowAction = (templateKey, metadataKey, newValue) => {
        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newFolderDetails = this.state.folderDetails;        //need this temporary object to ensure all values are maintained

        newFolderDetails[templateKey + "~" + metadataKey] = val;

        this.setState({folderDetails : newFolderDetails});
    }


    openFolder = (folderDetails) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('openFolder folderDetails = ', folderDetails);

        //get documents within the specified folder
        this.setState({
            folderDetails: folderDetails,

            //Now clear previous search results
            // searchResults: [],
            // searchTotalCount: 0,
            // searchCriteria: [],
            // searchCriteriaSimple :"",
            // searchOffset: 0,
            displaySearchResults: 0
        });
    };

    returnToSearchResultsFromFolder = () => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('PortalApp returnToSearchResultsFromFolder');

        //return to search results by clearing folderDetails
        this.setState({
            folderDetails: {},

            //Now clear previous search results
            // searchResults: [],
            // searchTotalCount: 0,
            // searchCriteria: [],
            // searchCriteriaSimple :"",
            // searchOffset: 0,
            displaySearchResults: 1
        });
    }

    getWorkspace = (id) => {
        //loop through all workspaces to get correct workspace
        //then set state to show dialog

      //  window.location.pathname.toLowerCase().includes("debug") && console.log ('workspaceConfig = ', this.props.workspaceConfig);
        this.props.workspaceConfig.forEach((workspaceConfig) => {
            if (workspaceConfig.id === id ){
                window.location.pathname.toLowerCase().includes("debug") && console.log ('id = ', id, "workspaceConfig = ", workspaceConfig)
                this.handleSelectWorkspace(workspaceConfig);
                this.showAddDocDialog();
            }
        })
    };

    showAddDocDialog = () => {
        this.setState({
            showAddDocDialog: true
        });
    };

    closeAddDocDialog = () => {
        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showAddDocDialog: false,
        });
    };

    handleShowMyDocuments = () => {
        this.manualDrawerClose();
        this.setState({showMyDocuments: true})
    };

    handleShowAdminGeneral = () => {
        this.manualDrawerClose();
        this.setState({mode: 'admin'});
        this.setState({adminHeader: 'Application Settings'});
        this.setState({showAdminGeneral: true});
    };

    handleShowDashboard = (url, label) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('handleShowDashboard url=', url, label);

        if (!this.state.drawerOpenOnLaunch) {
            this.manualDrawerClose();
        }
        this.setState({mode: 'reporting'});
        this.setState({mountDashboard: true});

        //url & label from configDashboards.json
        this.setState({reportingHeader: (label && label !== "") ? label : 'Dashboard'});
        this.setState({dashboardUrl: (url && url !== "") ? url : window.REACT_APP_DASHBOARD_URL})

    };

    handleShowForms = () => {
        this.setState({mode: 'forms'});
        this.setState({formsHeader: configFiles.actionsConfig.forms.label});
        this.manualDrawerClose();
    };

    openTasks = (tasks) => {
        this.setState({mode: 'tasks'});
    };

    // updateTasksBadge = (tasks) => {
    //     this.setState({tasks: tasks})
    // }

    openLanguageMenu = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    closeLanguageMenu = () => {
        this.setState({anchorEl: null});
    };

    render() {

        const {i18n, t} = this.props;
        const enableTranslate = window.REACT_APP_ENABLE_TRANSLATION === "true";
        const translate = (val) => enableTranslate ? t(val) : val

        const {anchorEl} = this.state;

        const { classes, theme, ...other } = this.props;
        const { drawerOpen } = this.state;
        const selectedWorkspaceConfig = this.state.selectedWorkspaceConfig;
        const actionsConfig = configFiles.actionsConfig;
        const mode = this.state.mode;

        let hasMore = false;
        if (this.state.nextMarker) {
            hasMore = true
        } else if (this.state.searchTotalCount !== 0 && (this.state.searchResults.length < this.state.searchTotalCount)) {
            hasMore = true
        }

        /*
        const lngs = {
          en: { nativeName: 'English' },
          de: { nativeName: 'Deutsch' }
        };
         */
        const languages = enableTranslate && actionsConfig.translate && actionsConfig.translate.languages ? actionsConfig.translate.languages : {};

        let homeTooltipTitle = selectedWorkspaceConfig.title + " Home";
        if (selectedWorkspaceConfig.title.indexOf("\\") > -1) {
            homeTooltipTitle = selectedWorkspaceConfig.title.split("\\")[1] + " Home"
        }

        let showSubfolders = false;
        let showFolderDocuments = false;
        let searchType = "";
        if (this.state.folderDetails){
            if (Object.entries(this.state.folderDetails).length > 0) {
                if (this.state.folderDetails.searchType) {
                    searchType = this.state.folderDetails.searchType;
                    if (searchType === "subfolder"){
                        showSubfolders = true
                    } else {
                        showFolderDocuments = true
                    }
                } else {
                    showFolderDocuments = true
                }
            }
        }

        const hideFolderDetails = selectedWorkspaceConfig.searchConfig.folderSearch.folderDetails.hide;

        return (
            <React.Fragment>
                <AppBar
                    position={"sticky"}
                    color="secondary"
                    className={classNames(classes.appBar, {[classes.contentShift]: drawerOpen })}>
                    <Toolbar
                        disableGutters={false}
                        // className={classes.grow}>
                        className = {classNames(classes.grow, classes.toolbar)}>

                         <Tooltip title={"Menu"}>
                             <IconButton
                                 color="inherit"
                                 aria-label="Open drawer"
                                 onClick={this.handleDrawerOpen}
                                 className={classNames(classes.menuButton, drawerOpen && classes.hide)}>
                                 <i className='material-icons'>menu</i>
                             </IconButton>
                         </Tooltip>
                        {
                            // show home button if folder search enabled, and we have opened a folder
                            (selectedWorkspaceConfig.searchConfig.autoSearch === "folder" && Object.entries(this.state.folderDetails).length>0 && mode==="search") &&
                            <Tooltip title={homeTooltipTitle}>
                                <IconButton
                                    color="inherit"
                                    disabled = {false}
                                    aria-label="Home"
                                    onClick={() => this.handleSelectWorkspace(selectedWorkspaceConfig)}>
                                    <i className='material-icons'>
                                        home
                                    </i>
                                </IconButton>
                            </Tooltip>
                        }

                        {
                            Object.entries(this.state.folderDetails).length>0 &&
                            !this.state.routeToFolder && mode==="search" &&
                            actionsConfig.backToSearchResults &&
                            actionsConfig.backToSearchResults.enabled &&

                            <Tooltip title="Return to search results">
                                <IconButton
                                    color="inherit"
                                    disabled = {false}
                                    aria-label="Return to search results"
                                    onClick={this.returnToSearchResultsFromFolder}>
                                    <i className="material-icons">keyboard_backspace</i>
                                </IconButton>
                            </Tooltip>
                        }

                        {
                            (mode !== 'admin' && mode !== "reporting"  && mode !== "forms" && mode !== "tasks") &&

                            <React.Fragment>
                                <Typography variant="h6" color="inherit" className={classes.grow} style={{paddingTop: "5px"}}>
                                    <WorkspaceMenu
                                        {...other}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                                        handleSelectWorkspace={this.handleSelectWorkspace}
                                        listTitle={"Select Workspace"}
                                        workspaceFilter={this.state.workspaceFilter}
                                        updateWorkspaceFilter={this.updateWorkspaceFilter}
                                        actionsConfig={actionsConfig}
                                    />
                                    {
                                        ((selectedWorkspaceConfig.searchConfig.autoSearch === "document" ||
                                            (selectedWorkspaceConfig.searchConfig.autoSearch === "folder" && Object.entries(this.state.folderDetails).length === 0))
                                            && !this.state.isFetching) &&
                                        <IconButton
                                            color="inherit"
                                            aria-label="Refresh"
                                            onClick={() => this.handleSelectWorkspace(selectedWorkspaceConfig)}
                                            className={classNames(classes.menuButton)}
                                        >
                                            <i className='material-icons'>refresh</i>
                                        </IconButton>
                                    }
                                </Typography>
                            </React.Fragment>
                        }

                        {
                            mode === 'admin' &&
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                {this.state.adminHeader}
                            </Typography>
                        }

                        {
                            mode === 'reporting' &&
                                <React.Fragment>
                                    <Typography variant="h6" color="inherit" className={classes.grow}>
                                        {this.state.reportingHeader}
                                        <RefreshDashboard parentClasses={this.props.classes} unmountDashboard={this.unmountDashboard}/>
                                    </Typography>
                                </React.Fragment>
                        }

                        {
                            mode === 'forms' &&
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                {this.state.formsHeader}
                            </Typography>

                        }

                        {
                            mode === 'tasks' &&
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                {translate('Tasks')}
                            </Typography>

                        }

                        {
                            mode !== 'admin' && mode !== "reporting" && mode !== "forms" && mode !== "tasks" && this.state.urlSearch &&

                            <SearchCaseElastic
                                userDetails={this.props.userDetails}
                                updateSearchResults={this.updateSearchResultsURLSearch}
                                updateIsFetching={this.updateIsFetching}
                                actionConfig={actionsConfig.urlSearch}
                                metadataConfig={configFiles.metadataConfig}
                                searchLimit={this.state.searchLimit}
                                searchCriteria={this.state.searchCriteria}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            />
                        }

                        {
                            mode !== 'admin' && mode !== "reporting" && mode !== "forms" && mode !== "tasks" && this.state.mountSearch  &&

                                <React.Fragment>

                                    <div className={classes.searchField}>
                                        <Search
                                            userDetails={this.props.userDetails}
                                            updateSearchResults={this.updateSearchResults}
                                            updateIsFetching={this.updateIsFetching}
                                            selectedSearchConfig={this.state.selectedWorkspaceConfig.searchConfig}
                                            metadataConfig={configFiles.metadataConfig}
                                            optionsConfig={configFiles.optionsConfig}
                                            remountSearch={this.remountSearch}
                                            autoDrawerClose={this.autoDrawerClose}
                                            folderDetails={this.state.folderDetails}
                                            drawerWidth={drawerOpen ? this.props.drawerWidth : 0}
                                            searchLimit={this.state.searchLimit}
                                            searchOffset={this.state.searchOffset}
                                            newSearch={this.state.newSearch}
                                            searchCriteria={this.state.searchCriteria}
                                            searchCriteriaSimple={this.state.searchCriteriaSimple}
                                            //resetSearchOffset={this.resetSearchOffset}
                                            simpleSearchActionConfig={actionsConfig.simpleSearch}
                                            advancedSearchFilesActionConfig={actionsConfig.advancedSearchFiles}
                                            advancedSearchFoldersActionConfig={actionsConfig.advancedSearchFolders}
                                            searchType={this.state.searchType}
                                            mode={mode}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            disableAutoOpenAdvancedSearch = {this.state.routeToFolder || this.state.urlSearch} //prevent auto-opening of search template if routing to folder
                                            disableAutoSearch={this.state.routeToFolder || this.state.urlSearch}
                                            nextMarker={this.state.nextMarker}
                                            //searchPopoverPosition={this.props.searchPopoverPosition}
                                        />
                                    </div>
                                </React.Fragment>
                        }

                        {/*auto load Upload screen for Helvetica */}
                        {this.state.showDialog &&
                            <AddDocDialog
                                parentClasses={this.props.classes}
                                userDetails={this.props.userDetails}
                                uploadConfig={this.state.selectedWorkspaceConfig.uploadConfig}
                                metadataConfig={configFiles.metadataConfig}
                                optionsConfig={configFiles.optionsConfig}
                                closeAddDocDialog={this.closeAddDocDialog}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                actionConfig={actionsConfig.addDocumentMain}
                            />
                        }
                        {
                            (enableTranslate && languages) &&

                                <React.Fragment>
                                    <Tooltip title={translate("Language")}>
                                        <Button
                                            color={"inherit"} className={classes.menuButton} style={{marginRight: "8px"}}
                                            onClick={this.openLanguageMenu} aria-label="Menu">
                                                <i className='material-icons' style={{paddingRight: '8px'}}>public</i>{i18n.resolvedLanguage}
                                        </Button>
                                    </Tooltip>

                                    <Popover
                                        id="lang-menu"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                                        transformOrigin={{vertical: 'top', horizontal: 'right',}}
                                        open={Boolean(anchorEl)}
                                        onClose={this.closeLanguageMenu}
                                    >
                                        {
                                            Object.keys(languages).map((language) => (
                                                <MenuItem key={language} style={{ fontWeight: i18n.resolvedLanguage === language ? 'bold' : 'normal' }}
                                                    type="submit" onClick={() => {i18n.changeLanguage(language); this.closeLanguageMenu()}}>
                                                    {languages[language].nativeName}
                                                </MenuItem>))
                                        }
                                    </Popover>

                                </React.Fragment>
                        }


                        <Typography variant="h6" color="inherit">
                            {
                                window.REACT_APP_APP_BAR_LOGO_SHOW === "true" || window.REACT_APP_APP_BAR_LOGO_SHOW === true ?
                                    <div className={classes.appBarLogo}>
                                        {
                                            window.REACT_APP_APP_BAR_LOGO_LINK_URL && window.REACT_APP_APP_BAR_LOGO_LINK_URL !== "" ?
                                                <Tooltip title = {window.REACT_APP_APP_BAR_LOGO_LINK_URL}>
                                                    <Link href={window.REACT_APP_APP_BAR_LOGO_LINK_URL} target="_blank">
                                                        <img src={window.location.origin + '/images/logo_appbar.png'} alt="logo" height={theme.appBarLogo.height}/>
                                                    </Link>
                                                </Tooltip> :
                                                <img src={window.location.origin + '/images/logo_appbar.png'} alt="logo" height={theme.appBarLogo.height}/>
                                        }
                                    </div>
                                    : translate(window.REACT_APP_APP_TITLE)
                            }
                        </Typography>

                    </Toolbar>
                </AppBar>

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={drawerOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <MainMenu
                        {...other}
                        drawerOpen={drawerOpen}
                        workspaceConfig={this.props.workspaceConfig}
                        selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                        handleSelectWorkspace={this.handleSelectWorkspace}
                        manualDrawerClose={this.manualDrawerClose}
                        handleShowMyDocuments={this.handleShowMyDocuments}
                        logout={this.props.logout}
                        userDetails={this.props.userDetails}
                        handleShowDashboard={this.handleShowDashboard}
                        handleShowAdminGeneral={this.handleShowAdminGeneral}
                        handleShowForms={this.handleShowForms}
                        mode={mode}
                        actionsConfig={actionsConfig}
                        publicLinksConfig={configFiles.publicLinksConfig}
                        dashboardsConfig={configFiles.dashboardsConfig}
                        workspaceFilter={this.state.workspaceFilter}
                        updateWorkspaceFilter={this.updateWorkspaceFilter}
                        openTasks={this.openTasks}
                        metadataConfig={configFiles.metadataConfig}
                        optionsConfig={configFiles.optionsConfig}
                    />
                </Drawer>

                {/*main div*/}
                <div className={classNames({[classes.contentShift]: drawerOpen,})}>

                    {
                        (mode !== 'admin' && mode !== "reporting" && mode !=="forms" && mode !== "tasks" ) &&
                        <React.Fragment>
                            {/*Progress bar for new searches*/}
                            {
                                (this.state.isFetching && Object.entries(this.state.folderDetails).length === 0 && this.state.newSearch) &&
                                <React.Fragment>
                                    <LinearProgress
                                        className={classes.searchProgressBar}
                                        color="primary"/>
                                    <div className={classes.searching}>Searching...</div>
                                </React.Fragment>
                            }

                            {/*Main Grid */}
                            <Grid container spacing={0} style={{overflowY:'auto'}}>
                                <Grid item xs={12}>

                                    {/*Grid Item for Folder(Case) Details (if applicable) */}
                                    {
                                        Object.entries(this.state.folderDetails).length > 0 && this.state.mountFolderDetails &&
                                            <React.Fragment>
                                                <Grid item xs={12} className={hideFolderDetails ? classes.folderDetailsNoFields : classes.folderDetails}>
                                                    <FolderDetails
                                                        parentClasses={classes}
                                                        userDetails = {this.props.userDetails}
                                                        metadataConfig={configFiles.metadataConfig}
                                                        optionsConfig={configFiles.optionsConfig}
                                                        selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                                                        folderDetails={this.state.folderDetails}
                                                        actionsConfig={actionsConfig}
                                                        workflowConfig={configFiles.workflowConfig}
                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                        unmountComponent={this.unmountFolderDetails}
                                                        remountComponent={this.remountFolderDetails}
                                                        updateFolderDetails={this.updateFolderDetails}
                                                        updateFolderDetailsFromWorkflowAction={this.updateFolderDetailsFromWorkflowAction}
                                                        getFolderMetadata = {this.getFolderMetadata}
                                                        //unmountFolderContent={this.unmountFolderContent}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                    }

                                    {/*Nested Grid for Folder Documents, Tasks & comments*/}
                                    {
                                        showFolderDocuments &&

                                           <Grid item xs={12} className={classes.folderContent}>
                                               <FolderContent
                                                   classes={classes}
                                                   boxFolderID={this.state.folderDetails.box_id}
                                                   userDetails={this.props.userDetails}
                                                   selectedSearchConfig={this.state.selectedWorkspaceConfig.searchConfig}
                                                   metadataConfig={configFiles.metadataConfig}
                                                   optionsConfig={configFiles.optionsConfig}
                                                   actionsConfig={actionsConfig}
                                                   workspaceConfig={this.props.workspaceConfig}
                                                   selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                                                   folderDetails={this.state.folderDetails}
                                                   searchCriteria={this.state.searchCriteria}
                                                   searchCriteriaSimple={this.state.searchCriteriaSimple}
                                                   triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                   templatesConfig={configFiles.templatesConfig}
                                                   workflowConfig={configFiles.workflowConfig}
                                                   updateFolderDetails={this.getFolderMetadata}
                                               />
                                           </Grid>
                                    }

                                    {
                                        showSubfolders && this.state.mountFolderContent &&

                                            <Grid container
                                                  spacing={0}
                                                  style={{overflowY: 'auto'}}
                                            >
                                                <Grid item
                                                      xs={12}
                                                      className={classes.folderSubfolders}
                                                >
                                                    {
                                                        this.state.folderDetails &&
                                                        this.state.folderDetails.searchType &&
                                                        this.state.folderDetails.searchType === "subfolder" &&
                                                        <FolderSubfolders
                                                            boxFolderID={this.state.folderDetails.id}
                                                            subFolderCommentsConfig={actionsConfig.subFolderComments}
                                                            subFolderTasksConfig={actionsConfig.subFolderTasks}
                                                            optionsConfig={configFiles.optionsConfig}
                                                            searchResults={this.state.searchResults}
                                                            searchTotalCount={this.state.searchTotalCount}
                                                            getNextResults={this.getNextResults}
                                                            getAllResults={this.getAllResults}
                                                            displaySearchResults={this.state.displaySearchResults}
                                                            userDetails={this.props.userDetails}
                                                            isFetching={this.state.isFetching}
                                                            parentClasses={classes}
                                                            selectedSearchConfig={this.state.selectedWorkspaceConfig.searchConfig}
                                                            actionsConfig={actionsConfig}
                                                            metadataConfig={configFiles.metadataConfig}
                                                            workspaceConfig={this.props.workspaceConfig}
                                                            selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                                                            drawerOpen={drawerOpen}
                                                            folderDetails={this.state.folderDetails}
                                                            searchCriteria={this.state.searchCriteria}
                                                            searchCriteriaSimple={this.state.searchCriteriaSimple}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            templatesConfig={configFiles.templatesConfig}
                                                            workflowConfig={configFiles.workflowConfig}
                                                            updateFolderDetails={this.updateFolderDetails}
                                                            remountComponent={this.remountFolderContent}
                                                        />
                                                    }

                                                </Grid>
                                            </Grid>

                                    }

                                    {
                                        !showSubfolders &&

                                            <Grid container
                                                  spacing={0}
                                                  style={{overflowY:'auto'}}
                                            >

                                                {/*Search Results*/}
                                                {
                                                    ((this.state.displaySearchResults && !this.state.isFetching) || (this.state.searchOffset > 0)) && Object.entries(this.state.folderDetails).length === 0 ?
                                                    <React.Fragment>
                                                        <Grid item
                                                              id={"scrollableDiv"}
                                                              xs={12}
                                                              className={classes.searchResults}
                                                              style={{
                                                                  //vh to ensure each grid item within will scroll independently & search results app bar displays correctly
                                                                  //https://stackoverflow.com/questions/21224411/css-calc-viewport-units-workaround
                                                                  height: "100vh",
                                                                  marginTop: "-5vw",
                                                                  paddingTop: "5vw",
                                                                  paddingLeft: '16px',
                                                                  paddingRight: '16px',
                                                                  overflowY: "auto",
                                                              }}
                                                        >
                                                            <InfiniteScroll
                                                                style={{overflowX: 'hidden'}}
                                                                dataLength={this.state.searchResults.length}
                                                                next={this.getNextResults}
                                                                hasMore={hasMore}
                                                                loader={
                                                                    <InfiniteScrollLoader
                                                                        searchReturnedCount={this.state.searchResults.length}
                                                                        searchTotalCount={this.state.searchTotalCount}
                                                                        searchLimit={this.state.searchLimit}
                                                                        isFetching={this.state.isFetching}
                                                                        nextMarker={this.state.nextMarker}
                                                                        getNextResults={this.getNextResults}
                                                                    />
                                                                }
                                                                endMessage={
                                                                    <InfiniteScrollEndMessage
                                                                        searchReturnedCount={this.state.searchResults.length}
                                                                        searchTotalCount={this.state.searchTotalCount}
                                                                        nextMarker={this.state.nextMarker}
                                                                    />
                                                                }
                                                                 scrollableTarget="scrollableDiv"
                                                            >

                                                                <SearchResults
                                                                    {...other}
                                                                    searchResults={this.state.searchResults}
                                                                    searchTotalCount={this.state.searchTotalCount}
                                                                    searchLimit={this.state.searchLimit}
                                                                    getNextResults={this.getNextResults}
                                                                    getAllResults={this.getAllResults}
                                                                    displaySearchResults={this.state.displaySearchResults}
                                                                    userDetails={this.props.userDetails}
                                                                    isFetching={this.state.isFetching}
                                                                    parentClasses={this.props.classes}
                                                                    selectedSearchConfig={this.state.selectedWorkspaceConfig.searchConfig}
                                                                    actionsConfig={actionsConfig}
                                                                    metadataConfig={configFiles.metadataConfig}
                                                                    optionsConfig={configFiles.optionsConfig}
                                                                    workspaceConfig={this.props.workspaceConfig}
                                                                    selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                                                                    searchType={this.state.searchType}
                                                                    openFolder={this.openFolder}
                                                                    drawerOpen={drawerOpen}
                                                                    folderDetails={this.state.folderDetails}
                                                                    searchCriteria={this.state.searchCriteria}
                                                                    searchCriteriaSimple={this.state.searchCriteriaSimple}
                                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                    nextMarker={this.state.nextMarker}
                                                                    templatesConfig={configFiles.templatesConfig}
                                                                    workflowConfig={configFiles.workflowConfig}
                                                                    reloadWorkspace={this.handleSelectWorkspace}
                                                                />
                                                            </InfiniteScroll>

                                                        </Grid>

                                                    </React.Fragment> :
                                                        <span/>
                                                }



                                            </Grid>

                                    }


                                </Grid>
                            </Grid>

                        </React.Fragment>
                    }

                    {(mode === 'admin' && this.state.showAdminGeneral) &&
                        <Admin parentClasses={this.props.classes} drawerOpen={drawerOpen}/>
                    }

                    {(mode === 'reporting' && this.state.mountDashboard) &&
                        <Dashboard parentClasses={this.props.classes}
                                   dashboardUrl={this.state.dashboardUrl}
                                   remountComponent={this.remountDashboard}
                        />
                    }

                    {(mode === 'forms') &&
                        <Form parentClasses={this.props.classes}/>
                    }

                    {(mode === 'tasks') &&
                    // <Form parentClasses={this.props.classes}/>
                        <AllTasks userDetails={this.props.userDetails}
                                     classes={this.props.classes}
                                     remountComponent={this.remountDashboard}
                                     triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                     boxFolderID={this.state.folderDetails.boxFolderID}
                                     folderTasksConfig={actionsConfig.folderTasks}
                                     displayInOwnTab={true}

                        />
                    }

                    {this.state.showPreview &&
                        <PreviewWithPropertiesDialog
                            boxDocID={this.props.match.params.id}
                            userDetails={this.props.userDetails}
                            handleCloseDialog={this.handleClosePreviewDialog}
                            parentClasses={this.props.classes}
                            calledFromDocumentMenu={false}
                            metadataConfig={configFiles.metadataConfig}
                            optionsConfig={configFiles.optionsConfig}
                            workspaceConfig={configFiles.workspaceConfig}
                            selectedWorkspaceConfig={this.state.selectedWorkspaceConfig}
                            actionsConfig={actionsConfig}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        />
                    }

                    {this.state.showMyDocuments &&
                        <MyDocumentsDialog
                            userDetails={this.props.userDetails}
                            handleMyDocumentsClose={this.handleMyDocumentsClose}
                            myDocumentsFolder={window.REACT_APP_MY_DOCUMENTS_FOLDER}
                        />
                    }

                </div>

            </React.Fragment>
        );

    }
}

PortalApp.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    configFiles: PropTypes.object.isRequired,
    drawerWidth: PropTypes.number.isRequired,
    launchDashboard: PropTypes.bool.isRequired
    // setLocale: PropTypes.func.isRequired,
    // locale: PropTypes.string
};

export default withTranslation()(withSnackbar(withStyles(styles, { withTheme: true })(PortalApp)));

import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import SignRequestDialog from './SignRequestDialog';
import { ReactComponent as SignIcon } from './file-sign.svg';

const styles = theme => ({
    signIcon: {
        fill: theme.palette.secondary.main
    }
})

function SignRequestButton(props) {

    const [showDialog, setShowDialog] = useState(false);

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = (signSubmitted) => {
        setShowDialog(false)
        if (props.unmountSearchResults) {
            props.unmountSearchResults()
        }
    };

    return (
        <React.Fragment>

            <Tooltip title={"Request Signature(s)..."}>
                <div style={{display: "inline"}}>
                    <IconButton onClick={handleClickIcon} >
                        <SignIcon
                            fill = {props.theme.palette.secondary.main}
                            style={{width: "24px", height: "24px"}}
                        />
                    </IconButton>
                </div>
            </Tooltip>

            {
                showDialog &&
                <SignRequestDialog
                    boxDocID={props.boxDocID}
                    folderDetails={props.folderDetails}
                    userDetails={props.userDetails}
                    handleCloseDialog = {handleCloseDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    documentDetails={props.documentDetails}
                />
            }
        </React.Fragment>
    );

}

SignRequestButton.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    boxDocID: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    folderDetails: PropTypes.object.isRequired,
    unmountSearchResults: PropTypes.object,
    documentDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true} )(SignRequestButton);
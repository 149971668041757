import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileUploadDropzone from "./FileUploadDropzone";
import FormControl from '@material-ui/core/FormControl';
import MetadataTableForUploads from "./MetadataTableForUploads";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from "@material-ui/core/LinearProgress";
import {withSnackbar} from 'notistack';
import {green, grey, red} from '@material-ui/core/colors';
import RenderMetadataField from "../../common/RenderMetadataField";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import {
    asyncForEach,
    getReadableFileSizeString,
    fetch_retry,
    getErrorMessageFromResponse,
    getFieldConfig, getErrorMessageFromResponseAsync
} from "../../common/helper";
import {readFileAsyncBase64, readFileAsyncBinary,readFileAsyncArrayBuffer} from "./helper";
import crypto from "crypto";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: green[500],
    },
    greyAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: grey[500],
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    header: {
        paddingBottom : theme.spacing(2),
    }

});


function getColour(statusIconClass) {

    switch(statusIconClass) {
        case "redAvatar":
            return(red[500]);
        case "greenAvatar":
            return(green[500]);
        default:
            return(grey[500])
    }
}

let counterMetadataTable = 0;
function addRowIDToFile (file) {
    counterMetadataTable += 1;
    file.rowId = counterMetadataTable;
    file.metadata = {};
    file.id = ""; //box id placeholder
    file.statusMessage = "";
    file.partProgress = "";
    file.useChunkApi= false;
    file.statusIcon = "file_upload";
    file.statusIconClass = "greyAvatar";
    return file
}

function CollaborateStep(props) {
    return (
        <span>
            <Typography>
                Specify who you would like to share these documents with
            </Typography>
            {/*//reuse Collaborate component here ??*/}
        </span>
    )
}
CollaborateStep.propTypes = {};

function MetadataStep(props) {
    let gridSize = 12;
    switch(props.maxWidth) {
        case "sm":
            gridSize = 12;
            break;
        case "md":
            gridSize = 6;
            break;
        case "lg":
            gridSize = 4;
            break;
        case "xl":
            gridSize = 3;
            break;
        default:
            gridSize = 12
    }

    return (
        <React.Fragment>
            {
                props.uploadConfig.genericFields.length > 0 &&

                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"}  className={props.classes.header}>Generic metadata</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={props.classes.paper}
                                   style={{
                                       //width: "100%",
                                       paddingLeft: "20px",
                                       paddingTop: "20px",
                                       paddingRight: "20px",
                                       textAlign: "left"
                                   }}>
                                <Grid container spacing={2}>
                                    {
                                        props.uploadConfig.genericFields.map(genericField => {
                                            const fieldValue = (props.genericMetadata[genericField.templateKey + "~" + genericField.metadataKey])? props.genericMetadata[genericField.templateKey + "~" + genericField.metadataKey] : "";
                                            return(
                                                <Grid item xs={gridSize}>
                                                    <FormControl fullWidth  style={{paddingBottom: '10px', margin: '0px'}} key={"fc" + genericField.templateKey + "~" + genericField.metadataKey}>
                                                        <RenderMetadataField
                                                            parentClasses={props.classes}
                                                            fieldValue={fieldValue}
                                                            handleOnChange={props.handleOnChangeGenericMetadata}
                                                            metadataConfig={props.metadataConfig}
                                                            optionsConfig={props.optionsConfig}
                                                            metadataKey={genericField.metadataKey}
                                                            templateKey={genericField.templateKey}
                                                            usage={"upload"}
                                                            required={genericField.required}
                                                           // helperText={(genericField.required && (!fieldValue || fieldValue === "")) ? "Value required" : ""}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )})
                                    }
                                </Grid>
                            </Paper>
                        </Grid>

                    </React.Fragment>

            }


            {props.fields.length > 0 && props.files.length > 0 &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <br/><Typography variant={"subtitle2"}>Document specific metadata</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <MetadataTableForUploads
                            parentClasses={props.classes}
                            files={props.files}
                            fixedMetadata={props.fixedMetadata}
                            genericMetadata={props.genericMetadata}
                            handleOnChangeDocumentMetadata={props.handleOnChangeDocumentMetadata}
                            fields={props.fields}
                            metadataConfig={props.metadataConfig}
                            optionsConfig={props.optionsConfig}
                            metadataFromParentFolder={props.uploadConfig.setMetadataFromParentFolder}
                            folderDetails={props.folderDetails}
                        />
                    </Grid>
                </React.Fragment>
            }



        </React.Fragment>
    )
}
MetadataStep.propTypes = {
    classes: PropTypes.object.isRequired,
    updateFileList: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    fixedMetadata: PropTypes.object.isRequired,
    genericMetadata: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    handleOnChangeGenericMetadata: PropTypes.func.isRequired,
    handleOnChangeDocumentMetadata: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    maxWidth: PropTypes.string.isRequired
};


function SelectLocalDocumentStep(props) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <FileUploadDropzone
                    parentClasses={props.classes}
                    triggerParentUpdateFileList={props.updateFileList}
                    actionConfig={props.actionConfig}
                />
            </Grid>
        </Grid>
    )
}
SelectLocalDocumentStep.propTypes = {
    classes: PropTypes.object.isRequired,
    updateFileList: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    actionConfig: PropTypes.object.isRequired
};


function ConfirmationMessageStep(props){
    return(
        <React.Fragment>
            {(props.uploadProgress > 0 && !props.isUploadComplete) &&
                <React.Fragment>
                    <LinearProgress color = "secondary"/>
                    <ListSubheader disableSticky>Uploading {props.uploadProgress} of {props.files.length}</ListSubheader>
                </React.Fragment> }

            <List>
                {
                    props.files.map(file => {

                        const isUploading = file.statusMessage.substring(0, 9) === "Uploading";
                        const largeFileWarning = file.useChunkApi && isUploading ? (file.partProgress !== "" ? file.partProgress : "  Large file, please wait...") : "";
                        return(
                            <React.Fragment key={"frag" + file.name}>
                                <ListItem key={"li" + file.name}>
                                    <ListItemAvatar>
                                        <Avatar style={{backgroundColor: getColour(file.statusIconClass)}}>
                                            <i className='material-icons'>{file.statusIcon}</i>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText key={"liText" + file.name }
                                                  primary={file.statusIcon === "file_upload" ?
                                                      file.name + " (" + getReadableFileSizeString(file.size) + ")":
                                                      file.name }
                                                  secondary={file.statusMessage + largeFileWarning}/>
                                </ListItem>
                            </React.Fragment>
                    )})
                }
            </List>
        </React.Fragment>
    )
}

ConfirmationMessageStep.propTypes = {
    classes: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    uploadProgress: PropTypes.number.isRequired,
    isUploadComplete: PropTypes.bool.isRequired,
    actionConfig: PropTypes.object.isRequired
};

function ErrorMessageStep(props){
    return(
        <Typography>Error message here</Typography>
    )
}

ErrorMessageStep.propTypes = {};


const INITIAL_STATE = {
    maxWidth: "lg",
    open: false,
    activeStep: 0,
    skipped: new Set(),
    genericMetadata: {},
    files: [],
    response: {
        success: true,
        message: ""
    },
    uploadProgress: 0,
    isUploadComplete: false,

};

class AddDocDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        this.handleOnChangeDocumentMetadata = this.handleOnChangeDocumentMetadata.bind(this);
        this.updateFileList = this.updateFileList.bind(this);
        this.handleOnChangeGenericMetadata = this.handleOnChangeGenericMetadata.bind(this);
        this.doUploadAsync = this.doUploadAsync.bind(this);

        window.location.pathname.toLowerCase().includes("debug") && console.log ('AddDocDialog props: ', this.props);
    }

    componentDidMount(){
        this.setState({ open: true });
    }

    validateGenericFields = () => {

        let fields = this.props.uploadConfig.genericFields;
        let missingValues = [];

        if (fields && fields.length > 0) {
            fields.forEach(field => {
                if (field.required) {
                    if (!this.state.genericMetadata[field.templateKey + "~" + field.metadataKey]  || this.state.genericMetadata[field.templateKey + "~" + field.metadataKey].toString() === "" ) {
                        let fieldConfig = getFieldConfig(this.props.metadataConfig, field.templateKey, field.metadataKey);
                        if (Object.entries(fieldConfig).length > 0) {
                            missingValues.push(fieldConfig.label);
                        } else {
                            missingValues.push(field.metadataKey)
                        }
                    }
                }
            })
        }

        if (missingValues.length > 0) {
            if (missingValues.length === 1) {
                this.props.enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) , {variant: 'error'});
            } else {
                this.props.enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1), {variant: 'error'});
            }
            return false;
        } else {
            return true
        }
    }

    validateDocumentSpecificFields = () => {

        let fields = this.props.uploadConfig.documentSpecificFields;
        let missingValues = [];

        //loop through files
        let files = this.state.files;
        if (fields && fields.length > 0 && files && files.length > 0) {
            fields.forEach(field => {
                if (field.required) {
                    for(let i=0; i<files.length; i++) {
                        if (!files[i].metadata || Object.entries(files[i].metadata) === 0 || !files[i].metadata[field.templateKey + "~" + field.metadataKey] || files[i].metadata[field.templateKey + "~" + field.metadataKey].toString() === "") {
                            let fieldConfig = getFieldConfig(this.props.metadataConfig, field.templateKey, field.metadataKey);
                            if (Object.entries(fieldConfig).length > 0) {
                                missingValues.push(fieldConfig.label);
                            } else {
                                missingValues.push(field.metadataKey)
                            }
                            break;// skip to next field
                        }
                    }
                }
            })
        }

        if (missingValues.length > 0) {
            if (missingValues.length === 1) {
                this.props.enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) + (files.length === 1 ? '.' : ' for each file.'), {variant: 'error'});
            } else {
                this.props.enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1) + (files.length === 1 ? '.' : ' for each file.'), {variant: 'error'});
            }
            return false;
        } else {
            return true
        }
    }

    getFolderMetadataBox = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log('AddDocDialog getFolderMetadataBox for parent folder');

        const pathVar = "/" + this.props.folderDetails.id + "/metadata";
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar;

        const request = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
        };

        debug && console.log ('getFolderMetadataBox url:' , url, 'request:' , request);

        await this.props.triggerRefreshAuthToken();
        let response = await (fetch(url,request));

        debug && console.log ('getFolderMetadataBox response = ', response);

        const responseCheck = await response.ok;

        if (responseCheck) {
            let data = await response.json();
            debug && console.log('response.json =', data);
            return data;
        } else {
            Promise.resolve(getErrorMessageFromResponse(response, 'getting folder metadata'))
                .then(message => {
                    this.props.enqueueSnackbar(message, {variant: 'error'});
                })
            return null
        }
    };

    getFolderMetadataElastic = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        const indexes = this.props.indexes;
        const pathVar = (indexes && indexes.length > 0 ?  "/" + indexes.toString()  : "")  + "/" + this.props.folderDetails.id;

        await this.props.triggerRefreshAuthToken();

        const url = window.REACT_APP_CASE_API_BASE_URL  + pathVar ;
        const request = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
        };

        debug && console.log ('getFolderMetadataElastic url:' , url, 'request:' , request);

        await this.props.triggerRefreshAuthToken();
        let response = await (fetch(url,request));

        debug && console.log ('getFolderMetadataElastic response = ', response);

        const responseCheck = await response.ok;

        if (responseCheck) {
            let data = await response.json();
            debug && console.log('response.json =', data);
            if (Array.isArray(data)) {
                return (data[0])
            } else {
                return data;
            }

        } else {
            //response not ok
            Promise.resolve(getErrorMessageFromResponse(response, 'getting folder metadata'))
                .then(message => {
                    this.props.enqueueSnackbar(message, {variant: 'error'});
                })
            return null
        }
    }

    async processFile(file, parentFolderMetadata) {
        const debug = window.location.pathname.toLowerCase().includes("debug");
        try {

            //append generic metadata
            let genericMetadata = this.state.genericMetadata;
            let metadata = file.metadata;
            debug && console.log ("file.metadata:" , file.metadata);

            for (let propertyName in genericMetadata) {
                metadata[propertyName] = genericMetadata[propertyName];
            }

            //add any fixed metadata from config
            const fixedMetadata = this.props.uploadConfig.fixedMetadata;
            if (fixedMetadata) {
                for (let i = 0; i < fixedMetadata.length; ++i) {
                    metadata[fixedMetadata[i].templateKey + "~" + fixedMetadata[i].metadataKey] = fixedMetadata[i].value;
                }
            }

            //extract templateKey & metadataKey and populate array
            let metadataArray = [];
            Object.entries(metadata).forEach(entry => {
                metadataArray.push({
                    templateKey: entry[0].split("~")[0],
                    metadataKey: entry[0].split("~")[1],
                    value: entry[1]
                });
            });

            //add any parentFolderMetadata
            parentFolderMetadata.forEach(entry => {
                metadataArray.push(entry)
            })

            debug && console.log ('metadataArray=' , metadataArray, 'uploadConfig: ', this.props.uploadConfig );

            let subFolders = [];
            let subFolderConfig = this.props.uploadConfig.subFolderConfig; //top level subfolder config

            if (subFolderConfig) {

                while(subFolderConfig.length > 0 ) {

                    debug && console.log ('subFolderConfig: ' , subFolderConfig);

                    //loop through each subFolderConfig in the subFolderConfig array
                    let valueFound = false;
                    for (let i = 0; i < subFolderConfig.length ; i++) {

                        //Check if we have a value for the metadata key provided
                        let m = subFolderConfig[i].templateKey + "~" + subFolderConfig[i].metadataKey;

                        if (file.metadata[m] ) {
                            if (file.metadata[m].toString() !== "" ) {

                                //value found so add it to subFolders array
                                subFolders.push(file.metadata[m]);

                                //found a value so drill down to next subFolderConfig level
                                subFolderConfig = subFolderConfig[i].subFolderConfig;

                                valueFound = true;
                                break;
                            }
                        }
                    }

                    //if no metadata value found after looping through the subFolderConfigs at this level
                    //then don't want to drill down any further so exit the while loop by setting subFolderConfig to []
                    if (!valueFound || ! subFolderConfig){
                        subFolderConfig = []
                    }
                }
            }

            //If adding a document from within a folder, add document to the selected folder
            let folderId = this.props.uploadConfig.folderId;
            if (this.props.folderDetails) {
                if (this.props.folderDetails.id) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('this.props.folderDetails.id = ', this.props.folderDetails.id);
                    folderId = this.props.folderDetails.box_id ? this.props.folderDetails.box_id : this.props.folderDetails.id;
                }
            }

            let queryStr = "?folderId=" + folderId + "&userId=" + this.props.userDetails.boxId +
                "&ocr=" + this.props.uploadConfig.ocr +"&watson=" + this.props.uploadConfig.watson ;

            if (this.props.actionConfig.audit) {
                queryStr = queryStr + "&audit=true"
            }

            if (this.props.uploadConfig.newVersionOnConflict) {
                queryStr = queryStr + "&newVersionOnConflict=true"
            }
            const baseUrl = this.props.uploadConfig.watson ? window.REACT_APP_CONTENT_API_BASE_URL_AI : window.REACT_APP_CONTENT_API_BASE_URL;
            const endpoint = this.props.actionConfig.customEndpoint ? this.props.actionConfig.customEndpoint : window.REACT_APP_CONTENT_API_DOCUMENT;
            const url = baseUrl + endpoint + queryStr;

            //If large file need to chunk content and upload in chunks

            const useChunkApiFrom = this.props.actionConfig.useChunkApiFrom;
            const useChunkApi = useChunkApiFrom && useChunkApiFrom > 0 && file.size > useChunkApiFrom;

            debug && console.log ('useChunkApiFrom = ', useChunkApiFrom, 'useChunkApi=', useChunkApi);

            // The direct file upload API supports files up to 50MB in size and sends all the binary data to the Box API in 1 API request.
            // The chunked upload APIs support files from 20MB in size and allow an application to upload the file in parts,
            // allowing for more control to catch any errors and retry parts individually.

            if (useChunkApi) {

                debug && console.log (' use chunked upload API, file.size=', file.size);

                let updatedFiles = this.state.files;
                file.useChunkApi = true ;
                this.setState({files: updatedFiles});

                let sessionError = false;

                //1. Create Upload Session
                let body={
                    folder_id: folderId,
                    file_size: file.size,
                    file_name: file.name
                }
                const sessionRequest = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };
                const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/chunked/upload_sessions';
                debug && console.log ('Create session url= ', url, 'BODY=', body, 'request=', sessionRequest);

                await this.props.triggerRefreshAuthToken();
                let sessionResponse = await (fetch_retry(url,sessionRequest,3));
                debug && console.log ('Create session response = ', sessionResponse);
                let session;
                if (sessionResponse.ok) {
                    session = await sessionResponse.json();
                    debug && console.log ('Create session response.json = ', session);
                } else {
                    sessionError = true
                    //response not ok
                    Promise.resolve(getErrorMessageFromResponse(sessionResponse, 'creating upload session'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                }

                if (sessionError) {
                    console.log ('sessionError.... session = ', session, 'sessionResponse=', sessionResponse);
                    if (session) {
                        debug && console.log ('return session', session)
                        return session
                    } else {
                        debug && console.log ('return sessionResponse', sessionResponse)
                        return sessionResponse
                    }

                } else {
                    //2. Upload each part
                    debug && console.log ('Upload ' + session.total_parts +' parts....');

                    //used in commit
                    const commitShasum = crypto.createHash('sha1')

                    //Read file
                    const allBytes = await readFileAsyncBinary(file); //for digest
                    const bodyFull = await readFileAsyncArrayBuffer(file);  //for body
                    debug && console.log ('file.size=', file.size);

                    //initialise rangeEnd and contentRange then loop through and upload each part
                    let rangeStart = 0;
                    let rangeEnd = session.part_size-1
                    const partURL = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/chunked/upload_sessions/' + session.id;

                    const partsArray = []
                    let partError = false

                    for (let i = 0; i < session.total_parts; i++) {

                        debug && console.log (file.name ,' upload part: ', i+1, ' of ', session.total_parts);

                        let updatedFiles = this.state.files;
                        let progress = i+1
                        file.partProgress =  '  Large file, uploading part ' + progress + ' of ' + session.total_parts + ', please wait...' ;
                        this.setState({files: updatedFiles});

                        const firstPart = i === 0;
                        const finalPart = i === (session.total_parts-1);

                        if (!firstPart) {
                            rangeStart = rangeEnd + 1
                            rangeEnd = rangeEnd + session.part_size
                            if (finalPart){
                                rangeEnd = file.size -1
                            }
                        }

                        const partBytes = finalPart ? allBytes.slice(rangeStart) : allBytes.slice(rangeStart, rangeStart + session.part_size); //for digest
                        const bodyPart = bodyFull.slice(rangeStart, rangeStart + session.part_size); //for body

                        const shasum = crypto.createHash('sha1')
                        shasum.update(partBytes, 'binary')
                        commitShasum.update(partBytes, 'binary')
                        const digest = "sha=" + shasum.digest().toString('base64') ;
                        const contentRange = "bytes " + rangeStart + "-" + rangeEnd + "/" + file.size; //<unit> <range-start>-<range-end>/<size>

                        debug && console.log ('part size: ', partBytes.length);
                        debug && console.log ('contentRange: ', contentRange)
                        debug && console.log ('digest: ', digest)

                        /* Note re. headers:
                        "content-range": "bytes 8388608-16777215/445856194", //The byte range of the chunk.  Must not overlap with the range of a part already uploaded this session.
                        "digest": "" //example sha=fpRyg5eVQletdZqEKaFlqwBXJzM=     The RFC3230 message digest of the chunk uploaded.  Only SHA1 is supported. The SHA1 digest must be Base64 encoded. The format of this header is as sha=BASE64_ENCODED_DIGEST.
                        */

                        const partRequest = {
                            method: 'PUT',
                            headers: {
                                "Content-Type": "application/octet-stream",
                                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                                "Content-Range": contentRange,
                                "Digest": digest
                            },
                            body: bodyPart
                        };

                        debug && console.log ('Request for part ' + (i+1) + ' = ', partURL, 'partRequest=', partRequest);
                        const partResponse = await (fetch_retry(partURL,partRequest,3));
                        debug && console.log ('add part response = ', partResponse);
                        let partResponseJSON
                        if (partResponse.ok) {
                            partResponseJSON = await partResponse.json();
                            debug && console.log ('addPartResponseJSON = ', partResponseJSON);
                            partsArray.push(partResponseJSON.part)
                        } else {
                            partError = true
                            Promise.resolve(getErrorMessageFromResponse(partResponse, "uploading part " + progress + ' of ' + session.total_parts))
                                .then(message => {
                                    this.props.enqueueSnackbar(message, {variant: 'error'});
                                })
                        }

                        if (partError) {
                            break
                        }
                    }

                    if (partError) {
                        return null
                    } else {

                        debug && console.log('==========all chunks done for ' + file.name, "now commit then return file id...");

                        debug && console.log('partsArray=', partsArray);

                        const digest = "sha=" + commitShasum.digest().toString('base64')

                        debug && console.log ('digest: ', digest)
                        const body = {parts: partsArray}

                        const commitURL = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/chunked/upload_sessions/' + session.id ;

                        const commitRequest = {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                                "Digest": digest
                            },
                            body: JSON.stringify(body)
                        };

                        debug && console.log('Commit session url = ', commitURL, 'BODY=', body, 'request=', commitRequest);

                        const commitResponse = await (fetch_retry(commitURL, commitRequest, 3));
                        debug && console.log('Commit session response = ', commitResponse);

                        try {
                            const commitResponseJSON = await commitResponse.json();
                            debug && console.log('commitResponseJSON = ', commitResponseJSON);
                            return commitResponseJSON
                        } catch (err) {
                            if (commitResponse) {
                                return commitResponse
                            } else {
                                //e.g. out of memory error
                                const errorMessage = err
                                this.props.enqueueSnackbar(errorMessage, {variant: 'error'});
                                return {message: err}
                            }

                        }
                    }
                }

            } else {

                //Upload via standard upload api (no chunking)

                //call readFileAsync to get content');
                let content = await readFileAsyncBase64(file);

                let body = {
                    content: content,
                    fileName: file.name,
                    metadata: metadataArray,
                    subFolders: subFolders
                };

                if (this.props.uploadConfig.watson) {
                    body.discoveryRequest = {
                        apiKey: window.REACT_APP_WATSON_APIKEY,
                        instanceId: window.REACT_APP_WATSON_INSTANCEID,
                        collectionId: window.REACT_APP_WATSON_COLLECTIONID,
                        environmentId: window.REACT_APP_WATSON_ENVIRONMENTID,
                    }
                }

                window.location.pathname.toLowerCase().includes("debug") && console.log ("body: " , body);

                const request = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };

                debug && console.log ('Upload url= ', url, 'BODY=', body, 'request=', request);

                //upload to box
                await this.props.triggerRefreshAuthToken();
                let response = await (fetch(url,request));

                try {
                    if (response.ok) {
                        let data = await response.json();
                        debug && console.log ('response.json: ', data);
                        return data
                    } else {
                        //get error message and return that??
                        let errorDetails = {error: "placeholder"};
                        await getErrorMessageFromResponseAsync(response, '')
                            .then(message => {
                                console.log ('message = ', message)
                                errorDetails.message = message;
                            })
                        return (errorDetails)
                    }

                } catch(err) {
                    console.log ('exception', err)
                    this.props.enqueueSnackbar("Error " + response.status + " uploading file " + response.statusText, {variant: 'error'});
                    return {message: err}
                }
            }

        } catch(err) {
            window.location.pathname.toLowerCase().includes("debug") && console.log("processFile error: " , err);
            return {message: err};
        }
    }

    doUploadAsync = () => {

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            const debug = window.location.pathname.toLowerCase().includes("debug");

            //get folder metadata if required
            let parentFolderMetadataArray = [];

            let errorGettingMetadata = false;

            if (this.props.uploadConfig.setMetadataFromParentFolder) {

                debug && console.log ('setMetadataFromParentFolder');
                //add metadata specified to be applied from parent
                const setMetadataFromParentFolder = this.props.uploadConfig.setMetadataFromParentFolder;
                if (setMetadataFromParentFolder && setMetadataFromParentFolder.length > 0){

                    //get parent folder metadata
                    let getMetadataResponse = window.REACT_APP_FOLDER_SOURCE === "elastic" ?
                        await this.getFolderMetadataElastic() :
                        await this.getFolderMetadataBox();

                    let response = await getMetadataResponse;

                    debug && console.log ('getMetadata response = ' , response);

                    if (response) {
                        //extract the metadata we need from the response
                        //for each metadata template specified in config add item to array with templateKey, metadataKey and value
                        if ( window.REACT_APP_FOLDER_SOURCE === "elastic") {
                            for (let i=0; i < setMetadataFromParentFolder.length; i++) {
                                const templateKey = setMetadataFromParentFolder[i].templateKey
                                setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                                    const fieldConfig = getFieldConfig(this.props.metadataConfig, templateKey, metadataKey);
                                    let val = "";
                                    if (fieldConfig && fieldConfig.elasticField) {
                                        if (response.metadata[fieldConfig.elasticField] || response.metadata[fieldConfig.elasticField] === "" || response.metadata[fieldConfig.elasticField] === false) {
                                            val = response.metadata[fieldConfig.elasticField];
                                            if (fieldConfig.type === "date") {
                                                val = (val !== 0 && val !== "0" && val !== null) ? new Date(val) : val;
                                            }
                                            parentFolderMetadataArray.push({
                                                templateKey: setMetadataFromParentFolder[i].templateKey,
                                                metadataKey: metadataKey,
                                                value: val
                                            })
                                        }
                                    }
                                })
                            }

                        } else {
                            debug && console.log ('REACT_APP_FOLDER_SOURCE box');
                            //Process Box response
                            for (let i = 0; i < setMetadataFromParentFolder.length; i++) {
                                for (let r = 0; r < response.length; r++) {
                                    if (response[r]["$template"] === setMetadataFromParentFolder[i].templateKey) {
                                        //get metadata for each metadataKey listed
                                        setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                                            if (response[r][metadataKey]) {
                                                parentFolderMetadataArray.push({
                                                    templateKey: setMetadataFromParentFolder[i].templateKey,
                                                    metadataKey: metadataKey,
                                                    value: response[r][metadataKey]
                                                })
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    } else {
                        errorGettingMetadata = true
                    }
                }
            }

            if (!errorGettingMetadata) {

                let progressCounter = 0;
                let updatedFiles = this.state.files;

                await this.props.triggerRefreshAuthToken();
                await asyncForEach(
                    updatedFiles,
                    async (file) => {

                        const fileSizeStr = getReadableFileSizeString(file.size);

                        progressCounter = progressCounter + 1;
                        this.setState({uploadProgress: progressCounter});
                        file.statusMessage = "Uploading " + fileSizeStr  ;
                        file.statusIconClass = "greenAvatar";
                        this.setState({files: updatedFiles});

                        await this.processFile(file, parentFolderMetadataArray)
                            .then(result => {
                                window.location.pathname.toLowerCase().includes("debug") && console.log ('processFile result = ', result);
                                if (result) {
                                    if (result.id) {
                                        file.statusIconClass = "greenAvatar";
                                        if (result.sequence_id && result.sequence_id !== "" && parseInt(result.sequence_id) > 0 ) {
                                            file.statusIcon = "done_all";
                                            file.statusMessage = "Successfully uploaded " + fileSizeStr + ") as NEW VERSION of file, ID=" + result.id;
                                        } else {
                                            file.statusIcon = "done";
                                            file.statusMessage = "Successfully uploaded " + fileSizeStr + " file, ID = " + result.id;
                                        }

                                        return result.id
                                    } else {
                                        file.statusIcon = "error";
                                        file.statusIconClass = "redAvatar";

                                        Promise.resolve(getErrorMessageFromResponse(result, "uploading " + getReadableFileSizeString(file.size) + " file"))
                                            .then(message => {
                                                file.statusMessage = message
                                                return "0";
                                        })
                                    }
                                } else {
                                    window.location.pathname.toLowerCase().includes("debug") && console.log ("processFile result is null");
                                    file.statusIcon = "error";
                                    file.statusIconClass = "redAvatar";
                                    file.statusMessage = "Error uploading " + fileSizeStr + " file" ;
                                    return "0";
                                }
                            })
                            .then(id => {
                                file.id = id;
                            });

                        this.setState({files: updatedFiles});

                    });

                // All Done, update state with updatedfiles
                this.setState({isUploadComplete: true});
                this.setState({response: {success: true}});
            } else {
                this.handleBack()
            }


        };

        //start upload
        //Validate first
        if (this.validateGenericFields()) {

            if (this.validateDocumentSpecificFields()) {

                //show confirmation step showing progress
                this.handleNext();
                start();
             }

        }

    };

    updateFileList(files) {

        //Add rowID to prep data for adding to metadata table

        counterMetadataTable = 0;
        let filesWithRowID = [];

        files.forEach((item, i) => {
            filesWithRowID.push(addRowIDToFile(item));
        });

        this.setState({
            files: filesWithRowID
        });

    }

    handleClose = () => {
        const END_STATE = {
            //dialog/stepper
            open: false,
            activeStep: 0,
            skipped: new Set(),

            genericMetadata: {},
            files: [],

            //service
            response: {
                success: true,
                message: ""
            },

            uploadProgress: 0,
            isUploadComplete: false

        };
        this.setState(END_STATE);

        this.props.closeAddDocDialog();
    };

    handleCancel = () => {
        const END_STATE = {
            //dialog/stepper
            open: false,
            activeStep: 0,
            skipped: new Set(),

            genericMetadata: {},
            files: [],

            //service
            response: {
                success: true,
                message: ""
            },

            uploadProgress: 0,
            isUploadComplete: false

        };
        this.setState(END_STATE);

        this.props.cancelAddDocDialog();
    };

    handleOnChangeGenericMetadata = (id, newValue) => {

        const genericMetadata = this.state.genericMetadata;

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        genericMetadata[id] = val;

        this.setState({genericMetadata: genericMetadata});

    };

    handleOnChangeDocumentMetadata = (id, newValue) => {

        //input fields are named the same as the state properties so use this generic function to update the state properties
        //fields suffixed with _index

        let whichFile = Number(id.substring(id.indexOf("_")+1))-1;
        let stateProp = id.substring(0 , id.indexOf("_"));

        window.location.pathname.toLowerCase().includes("debug") && console.log ('stateProp = ', stateProp);

        const files1 = this.state.files;

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        files1[whichFile].metadata[stateProp] = val;

        this.setState({files: files1});

    };

    isStepOptional = step => step === 100;  //specify step number for optional step here


    handleNext = () => {

        const { activeStep } = this.state;

        let maxWidth = "sm"

        if (activeStep + 1 === 1) {
            if (this.props.uploadConfig.documentSpecificFields.length <= 1) {
                maxWidth = "sm"
            } else if (this.props.uploadConfig.documentSpecificFields.length <= 2) {
                maxWidth = "md"
            } else if (this.props.uploadConfig.documentSpecificFields.length <= 4) {
                maxWidth = "lg"
            } else {
                maxWidth = "xl"
            }
        }

        let { skipped } = this.state;
        if (this.isStepSkipped(activeStep)) {
            skipped = new Set(skipped.values());
            skipped.delete(activeStep);
        }
        this.setState({
            activeStep: activeStep + 1,
            skipped,
            maxWidth: maxWidth
        });

    };

    handleBack = () => {

        let maxWidth = "sm"
        const { activeStep } = this.state;
        if (activeStep - 1 === 1) {
            if (this.props.uploadConfig.documentSpecificFields.length <= 1) {
                maxWidth = "sm"
            } else if (this.props.uploadConfig.documentSpecificFields.length <= 2) {
                maxWidth = "md"
            } else if (this.props.uploadConfig.documentSpecificFields.length <= 4) {
                maxWidth = "lg"
            } else {
                maxWidth = "xl"
            }
        }

        this.setState(state => ({
            activeStep: state.activeStep - 1,
            maxWidth: maxWidth
        }));
    };

    handleSkip = () => {
        const { activeStep } = this.state;
        if (!this.isStepOptional(activeStep)) {
            // You probably want to guard against something like this, it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        this.setState(state => {
            const skipped = new Set(state.skipped.values());
            skipped.add(activeStep);
            return {
                activeStep: state.activeStep + 1,
                skipped,
            };
        });
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    isStepSkipped(step) {
        return this.state.skipped.has(step);
    }

    render() {

        const { classes, ...other } = this.props;
        const { activeStep } = this.state;

        let steps = [], stepComponents = [];

        steps.push("Select files to upload");
        stepComponents.push(
            <SelectLocalDocumentStep
                classes={this.props.classes}
                updateFileList={this.updateFileList}
                files={this.state.files}
                actionConfig={this.props.actionConfig}

            />
        );

        steps.push("Enter metadata");
        stepComponents.push(
            <MetadataStep
                {...other}
                classes={this.props.classes}
                updateFileList={this.updateFileList}
                files={this.state.files}
                fixedMetadata={this.props.uploadConfig.fixedMetadata}
                genericMetadata={this.state.genericMetadata}
                fields={this.props.uploadConfig.documentSpecificFields}
                handleOnChangeDocumentMetadata={this.handleOnChangeDocumentMetadata}
                uploadProgress={this.state.uploadProgress}
                metadataConfig={this.props.metadataConfig}
                optionsConfig={this.props.optionsConfig}
                uploadConfig={this.props.uploadConfig}
                handleOnChangeGenericMetadata={this.handleOnChangeGenericMetadata}
                maxWidth={this.state.maxWidth}
            />
        );

        if(this.state.response.success) {
            stepComponents.push(
                <ConfirmationMessageStep classes={classes} files={this.state.files} uploadProgress={this.state.uploadProgress}
                                         isUploadComplete={this.state.isUploadComplete} actionConfig={this.props.actionConfig}/>)
        } else {
            stepComponents.push(<ErrorMessageStep/>);
        }

        let dialogTitle = this.props.actionConfig && this.props.actionConfig.label ? this.props.actionConfig.label : "Add File(s)";

        if (this.props.folderDetails) {
            if (this.props.folderDetails.name) {
                dialogTitle = dialogTitle + " to " + this.props.folderDetails.name;
            }
        }

        return (
            <Dialog
                open={this.state.open} onClose={this.handleClose}
                aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={this.state.maxWidth} height={"90vh"}
                disableBackdropClick disableEscapeKeyDown
            >

                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>

                <DialogContent>

                    <div style={{width: '100%', display: 'block'}}>

                        <Stepper
                            activeStep={activeStep}
                            orientation={"horizontal"}>
                            {steps.map((label, index) => {
                                const props = {};
                                const labelProps = {};
                                if (this.isStepOptional(index)) {
                                    labelProps.optional = <Typography variant="caption">(Optional)</Typography>;
                                }
                                if (this.isStepSkipped(index)) {
                                    props.completed = false;
                                }
                                return (
                                    <Step key={label} {...props}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>

                        {stepComponents[activeStep] || <div>Error: Step not configured...</div>}
                    </div>
                </DialogContent>

                <DialogActions>
                    <React.Fragment>
                        {
                            activeStep === steps.length ?
                                <Button onClick={this.handleClose}
                                        variant="contained"
                                        disabled = {!this.state.isUploadComplete}
                                        color="secondary">
                                    {this.state.isUploadComplete ? "Close" : "Uploading..."}
                                </Button> :
                                <React.Fragment>
                                    <Button onClick={this.handleCancel}>Cancel</Button>&nbsp; &nbsp;
                                    {
                                        this.isStepOptional(activeStep) &&
                                        <Button variant="contained" color="secondary" onClick={this.handleSkip}>Skip</Button>
                                    }
                                    &nbsp;&nbsp;
                                    <Button variant="contained" color="secondary"
                                            disabled = {this.state.files.length === 0} //disable Next/Submit button if no files selected
                                            onClick={activeStep === steps.length - 1 ? this.doUploadAsync : this.handleNext}>
                                        {(activeStep === steps.length - 1 )? 'Submit' : 'Next'}
                                    </Button>
                                </React.Fragment>
                        }
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        );
    }
}

AddDocDialog.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    cancelAddDocDialog: PropTypes.func.isRequired,
    closeAddDocDialog: PropTypes.func.isRequired,
    folderDetails: PropTypes.object,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    indexes: PropTypes.array //required when folder source is elastic
};


export default withSnackbar(withStyles(styles, { withTheme: true })(AddDocDialog));

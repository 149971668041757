import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton/IconButton";

const styles = theme => ({});

function FolderIcon(){

    return (

        <div>
            <IconButton color="inherit" style={{ backgroundColor: 'transparent' }}>
                <i className='material-icons'> folder_open</i>
            </IconButton>

        </div>

    );

}

FolderIcon.propTypes = {};

export default withStyles(styles)(FolderIcon);
import { ReactComponent as FileIconWord } from './images/word.svg';
import { ReactComponent as FileIconExcel } from './images/excel.svg';
import { ReactComponent as FileIconPdf } from './images/pdf.svg';
import { ReactComponent as FileIconImage } from "./images/image.svg";
import { ReactComponent as FileIconDefault } from './images/file.svg';
import { ReactComponent as FileIconVideo } from './images/video.svg';
import { ReactComponent as FileIconAudio } from './images/audio.svg';

import PropTypes from "prop-types";
import React from "react";

function FileIcon (props) {

    const fileExt = props.fileName ? props.fileName.split('.').pop() : "";

    const isImage = ['jpg','jpeg','png','svg','tif','tiff'].includes(fileExt)
    const isVideo = ['avi','mov','mp4','wmv','ogg'].includes(fileExt)
    const isAudio = ['mp3','wav'].includes(fileExt)
    const isWord = ['doc', 'docx'].includes(fileExt)
    const isExcel = ['xls', 'xlsx'].includes(fileExt)
    const isPdf = fileExt === 'pdf'
    const style = {width: props.width, height: props.height}

    // console.log ('fileIcon props=', props)
    // console.log ('fileIcon fileExt=', fileExt, 'isImage=', isImage, 'isWord=', isWord, 'isExcel=', isExcel, 'isPdf=', isPdf)

    return(
            <React.Fragment>
                {
                    isPdf ?
                        <FileIconPdf style={style}/> :
                        isImage ?
                            <FileIconImage style={style}/>:
                            isVideo ?
                                <FileIconVideo style={style}/>:
                                isAudio ?
                                    <FileIconAudio style={style}/>:
                                    isWord ?
                                        <FileIconWord style={style}/>:
                                        isExcel ?
                                        <FileIconExcel style={style}/>:
                        <FileIconDefault style={style}/>
                }

            </React.Fragment>
        )
}

FileIcon.propTypes = {
    fileName: PropTypes.string.isRequired,
    height:  PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
};

export default FileIcon;